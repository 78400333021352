import IntermediarySidebar from "./IntermediarySidebar";

function IntermediaryContainer() {
  return (
    <div className="App h-full">
      <div className="h-full">
        <IntermediarySidebar/>
      </div>
    </div>
  );
}

export default IntermediaryContainer;