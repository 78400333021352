function DocxPreview({ fileUrl }: any) {
  const embedUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(fileUrl)}`;

  return (
    <iframe
      src={embedUrl}
      width="100%"
      height="600px"
      title="Office Online Document Preview"
      style={{ border: 'none' }}
    ></iframe>
  );
}

export default DocxPreview
