import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import { ApiController } from "../../../apiController";
import { Complain, User } from "../../../swagger/models";
import { useCombinedStore } from "../../../store"
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from 'react-i18next';

interface ScoresComplainPreviewProps {
  previewModal?: any
  setPreviewModal?: any
  category?: any,
  complainObject?: any,
  natureOfComplainDescription?: any,
  selectedNatureOfComplaints?: any,
  natureOfComplaints?: any,
  intermediary?: any
}

function ScoresComplainPreview({ previewModal, setPreviewModal, category, complainObject, natureOfComplainDescription, natureOfComplaints, intermediary, selectedNatureOfComplaints }: ScoresComplainPreviewProps) {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation();

  const role: string = params.role || "investor"

  useEffect(() => {
    ApiController.getInstance().UserLoggedIn$.subscribe((user: User | null) => {
      if (user) {
        if (user.userType === "Investor") {
          setUser(user);
        }
      }
    });
  }, [])

  const [user, setUser] = useState<User>({})
  const [refreshCaptcha, setRefreshCaptcha] = useState<number>(0)
  const [activeDot, setActiveDot] = useState(1)


  const fileAComplaint = useCombinedStore((state: any) => state.fileScoresComplaint)
  const scoresComplainFiled = useCombinedStore((state: any) => state.scoresComplainFiled)
  const handleComplaintSubmission = (e: any) => {
    e.preventDefault()
    fileAComplaint(complainObject)
    setRefreshCaptcha(refreshCaptcha + 1)
  }

  useEffect(() => {
    if (scoresComplainFiled) {
      ApiController.getInstance().ComplainFiled$.subscribe((complainFiled: Complain | null) => {
        if (complainFiled && complainFiled.complainId) {
          fetch(
            "https://hooks.slack.com/services/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",
            {
              method: "POST",
              mode: "no-cors",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                text: `*${user?.userName}* has filed a dispute against *${intermediary?.organisationName}*. The MII in this dispute is *${complainFiled?.MII?.organisationName}*`,
              }),
            }
          )
          navigate(`/${role}/dispute-registration/success?email=${user.email}&complaintID=${complainFiled.complaintUUID}&mii=${complainFiled?.MII?.organisationName}`)
        }
      })
    }
  }, [scoresComplainFiled])

  const onVerify = useCallback((token: string) => {
    complainObject.captchaToken = token
  }, [refreshCaptcha]);

  return (
    <div
      className="inset-0 fixed z-10 flex items-center justify-center"
      id="modal"
    >
      <div className="px-6 sm:px-0 sm:w-1/3">
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={true} />
        <div className="flex justify-center pt-4 pb-20">
          <div
            className="fixed inset-0 transition-opacity"
          >
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <div
            className="inline-block align-center h-fit w-fit bg-white rounded-xl py-4 text-left shadow-xl transform transition-all "
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex justify-between items-center px-8">
              <i className="ph ph-list-magnifying-glass text-2xl py-1 px-2 border border-border-grey rounded-lg"></i>
              <i className="ph ph-x text-xl text-low-grey" onClick={() => { setPreviewModal(!previewModal) }}></i>
            </div>
            <div className="flex flex-col justify-between h-full px-8 pb-5">
              {activeDot === 1 ? (
                <div className="flex flex-col gap-1">
                  <h1 className='font-semibold text-lg pt-3 w-full'>{t('DisputePreview')}</h1>
                  <p className="text-sm text-scores-light-grey pb-2">{t('PendingComplaintsNote')}</p>
                  <hr />
                  <div className='flex flex-col gap-6 pb-3 pt-8  text-label-grey w-full h-96 overflow-hidden overflow-y-scroll'>
                    <div className="flex flex-col gap-3 w-full">
                      {complainObject?.pendingComplaints?.map((i: string) => {
                        return <p className="text-xs text-label-grey w-2/3">{i}</p>
                      })}

                    </div>
                    <p className="text-sm text-scores-light-grey pb-2">{t('Disclaimer')}:
                    </p>
                    <p className="text-sm text-scores-light-grey pb-2">{t('DisclaimerNote1')}</p>
                    <p className="text-sm text-scores-light-grey pb-2">{t('DisclaimerNote2')}</p>
                    <p className="text-sm text-scores-light-grey pb-2">{t('DisclaimerNote3', {
                      ids: complainObject?.pendingComplaints?.join(',')
                    })}</p>
                    <p className="text-sm text-scores-light-grey pb-2">
                      {t('IDeclareDispose')}</p>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-1">
                  <h1 className='font-semibold text-lg pt-3 w-full'>{t('DisputePreview')}</h1>
                  <p className="text-sm text-scores-light-grey pb-2">{t('YourSMARTODRDisputeDetails')}</p>
                  <hr />
                  <div className='flex flex-col gap-6 pb-3 pt-8  text-label-grey w-full h-96 overflow-hidden overflow-y-scroll'>
                    <div className="flex flex-col gap-3 w-full">

                      <div className="flex sm:flex-row flex-col w-full">
                        <p className="text-xs text-label-grey w-1/3 mr-2 whitespace-nowrap">{t('email')}:</p>
                        <p className="text-xs text-label-grey w-2/3">{user.email ? user.email : ""}</p>
                      </div>
                      <div className="flex sm:flex-row flex-col w-full">
                        <p className="text-xs text-label-grey w-1/3 mr-2 whitespace-nowrap">{t('Intermediary')}:</p>
                        <p className="text-xs text-label-grey w-2/3">{category.length > 40 ? category.slice(0, 40) + '...' : category}</p>
                      </div>
                      <div className="flex sm:flex-row flex-col w-full">
                        <p className="text-xs text-label-grey w-1/3 mr-2 whitespace-nowrap">{t('DisputeAgainst')}:</p>
                        <p className="text-xs text-label-grey w-2/3">{intermediary?.organisationName}</p>
                      </div>
                      {selectedNatureOfComplaints?.map((complain: any, index: any) => {
                        return (
                          <>
                            <div className="flex sm:flex-row flex-col w-full">
                              <p className="text-xs text-label-grey w-1/3 mr-2 whitespace-nowrap">{t('NatureOfDispute')}:</p>
                              <p className="text-xs text-label-grey w-2/3">
                                {complain.natureType}
                              </p>
                            </div>
                            <hr />
                            {complain?.selectedSubComplaintType?.map((subcomplain: any, index: any) => {
                              return (
                                <>
                                  <div className="flex sm:flex-row flex-col w-full">
                                    <p className="text-xs text-label-grey w-1/3 mr-2 ">{t('Sub-NatureOfDispute')}:</p>
                                    <p className="text-xs text-label-grey w-2/3">{subcomplain?.subNatureType}</p>
                                  </div>
                                  <div className="flex sm:flex-row flex-col w-full">
                                    <p className="text-xs text-label-grey w-1/3 mr-2 whitespace-nowrap">{t('DisputeDetails')}:</p>
                                    <p className="text-xs text-label-grey w-2/3">{subcomplain?.description}</p>
                                  </div>
                                  {complainObject?.natureFiles?.map((file: any, index: any) => {
                                    return (<>
                                      {file?.natureType === subcomplain?.subNatureType && (
                                        <div className="flex sm:flex-row flex-col w-full">
                                          <p className="text-xs text-label-grey w-1/3 mr-2 whitespace-nowrap">{t('Attachments')}:</p>
                                          <p className="text-xs text-label-grey w-2/3">{file?.fileName.length > 30 ? file?.fileName.slice(0, 30) + '...' : file?.fileName}</p>
                                        </div>
                                      )
                                      }
                                    </>)
                                  })}
                                  <hr />
                                </>
                              )
                            })}
                          </>
                        )
                      })}

                    </div>
                  </div>
                </div>
              )}
              <div className="flex gap-4 items-center justify-center w-full">
                <div onClick={() => (setActiveDot(1))} className={`w-2.5 h-2.5 transition-all duration-300 origin-left ${activeDot === 1 ? 'bg-highlight-blue' : 'bg-border-grey'}`} style={{ borderRadius: '50%' }}>{" "}</div>
                <div onClick={() => (setActiveDot(2))} className={`w-2.5 h-2.5 transition-all duration-300 origin-left ${activeDot === 2 ? 'bg-highlight-blue' : 'bg-border-grey'}`} style={{ borderRadius: '50%' }}>{" "}</div>
              </div>

              {activeDot === 1 ? (
                <div className='flex sm:flex-row flex-col w-full pt-6 gap-4 justify-start'>
                  <button aria-label={t('cancel')}
                    onClick={() => { setPreviewModal(!previewModal) }}
                    className=" hover:bg-black/[0.1] cursor-pointer border border-border-grey w-1/2 hover:text-white justify-center text-xs bg-white text-scores-light-grey px-12 py-3 rounded-xl">
                    {t('cancel')}
                  </button>
                  <button onClick={(e) => { setActiveDot(2) }}
                    className="hover:bg-highlight-blue/[0.4] w-1/2 bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12"
                    aria-label={t('Proceed')}>
                    <p>{t('Proceed')}</p>
                  </button>
                </div>

              ) : (
                <div className='flex sm:flex-row flex-col w-full pt-6 gap-4 justify-start'>
                  <button aria-label={t('back')}
                    onClick={(e) => { setActiveDot(1) }}
                    className=" hover:bg-black/[0.1] cursor-pointer border border-border-grey w-1/2 hover:text-white justify-center text-xs bg-white text-scores-light-grey px-12 py-3 rounded-xl">
                    {t('Back')}
                  </button>
                  <button onClick={(e) => { handleComplaintSubmission(e) }}
                    className="hover:bg-highlight-blue/[0.4] w-1/2 bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12"
                    aria-label={t('Submit')}>
                    <p>{t('Submit')}</p>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ScoresComplainPreview