import { toast } from "react-hot-toast";
import { ApiController } from "../apiController";
import { loader, notifyError, notifySuccess } from "../helpers/helper";
import { ListUserParams, User } from "../swagger/models";

export const UserStore = (set: any, get: any, store: any) => ({
  users: [],
  officers: [],
  userDetail: {},
  matchingOrganisations: [],
  adminCount: 0,
  /**
   *
   * @param userId
   */
  getUser: async (userId: number) => {
    try {
        const userData =
          await ApiController.getInstance().userApi.getUserProfile(userId);
        set({
          userDetail: userData.data,
        });
    } catch (error: any) {
      notifyError(error?.response?.data?.message);
    }
  },
  addUserProfile: async (userObject: User) => {
    const toastId = loader("Adding User...");
    try {
      const userData =
        await ApiController.getInstance().userApi.createUserProfile(userObject);
      set({
        userDetail: userData.data,
      });
      toast.dismiss(toastId)
      notifySuccess('User profile added successfully!')
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
  updateUserProfile: async (userObject: User) => {
    const toastId = loader("Updating User Details...");
    try {
      const userData =
        await ApiController.getInstance().userApi.updateUserProfile(userObject);
      set({
        userDetail: userData.data,
      });
      toast.dismiss(toastId)
      notifySuccess('User profile updated successfully!')
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * Function to delete a user profile on the server
   * @param userId 
   */
  deleteUserProfile: async (userId: number) => {
    const toastId = loader("Deleting User Details...");
    try {
        await ApiController.getInstance().userApi.deleteUserProfile(userId);
        set({
          users: get().users.filter((user: any) => user.userId !== userId)
        })
      toast.dismiss(toastId)
      notifySuccess('User profile deleted successfully!')
    } catch (error: any) {
      console.log(error)
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   */
  listUsers: async (userParams: ListUserParams) => {
    const toastId = loader("Loading Users...");
    try {
      const userData =
        await ApiController.getInstance().userApi.listUsers(userParams);
      set({
        users: userData.data.Admins,
        adminCount: userData.data.adminCount
      });
      toast.dismiss(toastId)
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * Function to send the invitation to the existing user
   * @param userId 
   */
  resendUserInvitation: async (userId: number) => {
    const toastId = loader("Resending Invitation to User...");
    try {
        await ApiController.getInstance().userApi.resendInvitation(userId);
      toast.dismiss(toastId)
      notifySuccess('Invitation sent to user successfully!')
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   */
  searchOrganisations: async (organisationName: string) => {
    const toastId = loader("Loading Users...");
    try {
      const userData =
        await ApiController.getInstance().userApi.searchOrganisations(organisationName);
      set({
        matchingOrganisations: userData.data,
      });
      toast.dismiss(toastId)
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
  
  listGetUsers: async (orgId: number) => {
    const toastId = loader("Loading Users...");
    try {
      const userData =
        await ApiController.getInstance().userApi.listGetUsers(orgId);
      set({
        officers: userData.data,
      });
      toast.dismiss(toastId)
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
});
