import React from 'react'

function Loader() {
  return (
    <React.Fragment>
      <div className="loader text-center mt-40">
        <button aria-label="Loading">
          <span className='pl-3'>
            Loading ...
          </span>
        </button>
      </div>
    </React.Fragment>
  )
}

export default Loader
