import DocxPreview from "./DocumentPreviews/DocxPreview";
import OfficeOnlineExcelViewer from "./DocumentPreviews/ExcelPreview";
import PDFPreview from "./PDFPreview";

interface DocumentViewerModalProps {
  selectedFile: any;
  previewLink: string;
}

const DocumentViewerModal = ({ selectedFile, previewLink }: DocumentViewerModalProps) => {
  return (
    <>
      <div className="p-4 overflow-hidden" style={{ maxHeight: "calc(90vh - 80px)" }}>
        {selectedFile?.fileType === 'application/pdf' &&
          <div className="w-full h-full">
            <PDFPreview url={previewLink} />
          </div>
        }
        {selectedFile?.fileType?.startsWith('image/') &&
          <img src={previewLink} alt={selectedFile?.fileName} className="w-full h-full object-contain" />}
        {selectedFile?.fileType?.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') &&
        <OfficeOnlineExcelViewer fileUrl={previewLink} />

          // <div className="w-full h-full">
          //   <object data={previewLink} type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" width="100%" height="100%">
          //     <p>Preview of this document format is currently not supported. Please download the document by clicking the download icon next to the document title.</p>
          //   </object>
          // </div>
        }
        {/* Video Preview */}
        {selectedFile?.fileType?.startsWith('video/') && (
          <video controls style={{ width: '100%' }}>
            <source src={previewLink} type={selectedFile?.fileType} />
            Preview of this document format is currently not supported. Please download the document by clicking the download icon next to the document title.
          </video>
        )}
        {(selectedFile?.fileType === 'application/zip') && (
          <p>Preview of this document format is currently not supported. Please download the document by clicking the download icon next to the document title.</p>
        )}
        {(selectedFile?.fileType === 'application/msword' || selectedFile?.fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && (
          <DocxPreview fileUrl={previewLink} />
        )}
      </div>
      {selectedFile?.fileType?.startsWith('audio/') && (
        <div className="flex h-full w-full items-center justify-center">
          <audio className="w-full" controls>
            <source src={previewLink} type={selectedFile?.fileType} />
            Preview of this document format is currently not supported. Please download the document by clicking the download icon next to the document title.
          </audio>
        </div>
      )}
    </>
  );
};

export default DocumentViewerModal;