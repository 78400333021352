import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Toaster } from 'react-hot-toast'
import "./i18n";

import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react'

posthog.init(
  import.meta.env.VITE_PUBLIC_POSTHOG_KEY,
  {
    api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  }
);

ReactDOM.render(
  <React.StrictMode>
  <Toaster position="top-center"/>
  <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
