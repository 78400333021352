/* tslint:disable */
/* eslint-disable */
/**
 * OCP Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CategoryMaster } from '../models';
import { Organisation } from '../models';
import { SubCategoryMaster } from '../models';
import { ThirdLevelCategory } from '../models';
/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get details of requested Category Master
         * @summary Get complaint's detail
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryMaster: async (categoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling categoryMaster.');
            }
            const localVarPath = `/category/category_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get intermediary details from category
         * @summary Get intermediaries details
         * @param {number} categoryId 
         * @param {number} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        intermediaryCategory: async (categoryId: number, parentId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling intermediaryCategory.');
            }
            const localVarPath = `/category/intermediary_category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the category masters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCategoryMaster: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/category/list_category_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Third Level category master
         * @param {number} subCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubSubcategoryMaster: async (subCategoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subCategoryId' is not null or undefined
            if (subCategoryId === null || subCategoryId === undefined) {
                throw new RequiredError('subCategoryId','Required parameter subCategoryId was null or undefined when calling listSubSubcategoryMaster.');
            }
            const localVarPath = `/category/list_subSubcategory_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (subCategoryId !== undefined) {
                localVarQueryParameter['subCategoryId'] = subCategoryId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the category masters
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubcategoryMaster: async (categoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling listSubcategoryMaster.');
            }
            const localVarPath = `/category/list_subcategory_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get details of requested Category Master
         * @summary Get complaint's detail
         * @param {number} subCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoryMaster: async (subCategoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subCategoryId' is not null or undefined
            if (subCategoryId === null || subCategoryId === undefined) {
                throw new RequiredError('subCategoryId','Required parameter subCategoryId was null or undefined when calling subcategoryMaster.');
            }
            const localVarPath = `/category/subcategory_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (subCategoryId !== undefined) {
                localVarQueryParameter['subCategoryId'] = subCategoryId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get details of requested Category Master
         * @summary Get complaint's detail
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryMaster(categoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryMaster>> {
            const localVarAxiosArgs = await CategoryApiAxiosParamCreator(configuration).categoryMaster(categoryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get intermediary details from category
         * @summary Get intermediaries details
         * @param {number} categoryId 
         * @param {number} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async intermediaryCategory(categoryId: number, parentId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await CategoryApiAxiosParamCreator(configuration).intermediaryCategory(categoryId, parentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the category masters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCategoryMaster(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryMaster>>> {
            const localVarAxiosArgs = await CategoryApiAxiosParamCreator(configuration).listCategoryMaster(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of Third Level category master
         * @param {number} subCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSubSubcategoryMaster(subCategoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThirdLevelCategory>>> {
            const localVarAxiosArgs = await CategoryApiAxiosParamCreator(configuration).listSubSubcategoryMaster(subCategoryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the category masters
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSubcategoryMaster(categoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubCategoryMaster>>> {
            const localVarAxiosArgs = await CategoryApiAxiosParamCreator(configuration).listSubcategoryMaster(categoryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get details of requested Category Master
         * @summary Get complaint's detail
         * @param {number} subCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcategoryMaster(subCategoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubCategoryMaster>> {
            const localVarAxiosArgs = await CategoryApiAxiosParamCreator(configuration).subcategoryMaster(subCategoryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get details of requested Category Master
         * @summary Get complaint's detail
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryMaster(categoryId: number, options?: any): AxiosPromise<CategoryMaster> {
            return CategoryApiFp(configuration).categoryMaster(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get intermediary details from category
         * @summary Get intermediaries details
         * @param {number} categoryId 
         * @param {number} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        intermediaryCategory(categoryId: number, parentId?: number, options?: any): AxiosPromise<Organisation> {
            return CategoryApiFp(configuration).intermediaryCategory(categoryId, parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the category masters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCategoryMaster(options?: any): AxiosPromise<Array<CategoryMaster>> {
            return CategoryApiFp(configuration).listCategoryMaster(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Third Level category master
         * @param {number} subCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubSubcategoryMaster(subCategoryId: number, options?: any): AxiosPromise<Array<ThirdLevelCategory>> {
            return CategoryApiFp(configuration).listSubSubcategoryMaster(subCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the category masters
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubcategoryMaster(categoryId: number, options?: any): AxiosPromise<Array<SubCategoryMaster>> {
            return CategoryApiFp(configuration).listSubcategoryMaster(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get details of requested Category Master
         * @summary Get complaint's detail
         * @param {number} subCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoryMaster(subCategoryId: number, options?: any): AxiosPromise<SubCategoryMaster> {
            return CategoryApiFp(configuration).subcategoryMaster(subCategoryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * Get details of requested Category Master
     * @summary Get complaint's detail
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryMaster(categoryId: number, options?: any) {
        return CategoryApiFp(this.configuration).categoryMaster(categoryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get intermediary details from category
     * @summary Get intermediaries details
     * @param {number} categoryId 
     * @param {number} [parentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public intermediaryCategory(categoryId: number, parentId?: number, options?: any) {
        return CategoryApiFp(this.configuration).intermediaryCategory(categoryId, parentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the category masters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public listCategoryMaster(options?: any) {
        return CategoryApiFp(this.configuration).listCategoryMaster(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of Third Level category master
     * @param {number} subCategoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public listSubSubcategoryMaster(subCategoryId: number, options?: any) {
        return CategoryApiFp(this.configuration).listSubSubcategoryMaster(subCategoryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the category masters
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public listSubcategoryMaster(categoryId: number, options?: any) {
        return CategoryApiFp(this.configuration).listSubcategoryMaster(categoryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get details of requested Category Master
     * @summary Get complaint's detail
     * @param {number} subCategoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public subcategoryMaster(subCategoryId: number, options?: any) {
        return CategoryApiFp(this.configuration).subcategoryMaster(subCategoryId, options).then((request) => request(this.axios, this.basePath));
    }
}
