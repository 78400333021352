import AdminSidebar from "./AdminSidebar";

/**
 * AdminContainer component displays the main page of the admin portal.
 * It renders the AdminSidebar component on the left and an empty div on the right.
 * The right div is where the different pages of the admin portal will be rendered.
 */
function AdminContainer() {
  return (
    <div className="App h-full">
      <div className="h-full">
        <AdminSidebar/>
      </div>
    </div>
  );
}

export default AdminContainer;