import { toast } from "react-hot-toast";
import { ApiController } from "../apiController";
import { loader, notifyError } from "../helpers/helper";

export const CategoryStore = (set: any, get: any, store: any) => ({
  categoryMasters: [],
  subCategoryMasters: [],
  subsubCategoryMasters : [],
  categoryMaster: {},
  subCategoryMaster: {},
  intermediaries : [],

  /**
   *
   */
  fetchCategoryMasters: async () => {
    const toastId = loader("Loading Categories...");
    try {
      const categoryData =
        await ApiController.getInstance().categoryApi.listCategoryMaster();
      set({
        categoryMasters: categoryData.data,
      });
      toast.dismiss(toastId)
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   */
  fetchSubCategoryMasters: async (categoryId:any) => {
    const toastId = loader("Loading Sub-Categories...");
    try {
      const categoryData =
        await ApiController.getInstance().categoryApi.listSubcategoryMaster(categoryId);
      set({
        subCategoryMasters: categoryData.data,
      });
      toast.dismiss(toastId)
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },

  fetchSubSubCategoryMasters: async (categoryId:any) => {
    const toastId = loader("Loading Sub-Sub-Categories...");
    try {
      const categoryData =
        await ApiController.getInstance().categoryApi.listSubSubcategoryMaster(categoryId);
      set({
        subsubCategoryMasters: categoryData.data,
      });
      toast.dismiss(toastId)
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   * @param categoryId
   */
  fetchCategoryMaster: async (categoryId: number) => {
    const toastId = loader("Loading Categories...");
    try {
      const categoryData =
        await ApiController.getInstance().categoryApi.categoryMaster(categoryId);
      set({
        categoryMaster: categoryData.data,
      });
      toast.dismiss(toastId)
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },

  fetchIntermediaries : async (subCategoryId:any, parentId?: any) => {
    const toastId = loader("Loading Intermediaries...");
    try{
      const intermediaries = await ApiController.getInstance().categoryApi.intermediaryCategory(subCategoryId, parentId)
      set({
        intermediaries: intermediaries.data,
      });
      toast.dismiss(toastId)
    }catch(e : any){
      toast.dismiss(toastId)
      notifyError(e?.response?.data?.message);
    }
  },
   /**
   *
   * @param subCategoryId
   */
   fetchSubCategoryMaster: async (subCategoryId: number) => {
    const toastId = loader("Loading Categories...");
    try {
      const categoryData =
        await ApiController.getInstance().categoryApi.subcategoryMaster(subCategoryId);
      set({
        subCategoryMaster: categoryData.data,
      });
      toast.dismiss(toastId)
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
});
