import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCombinedStore } from '../../../store';
import { Tooltip } from 'react-tippy';
import { notifyError } from '../../../helpers/helper';
import { useTranslation } from 'react-i18next';
import { titleCase } from '../../common-module/helper';
import { ListOrgParams, Organisation, OrganisationParent, User } from '../../../swagger/models';
import { ApiController } from '../../../apiController';
import _ from "lodash";
import { ArrowLeft, ArrowRight } from 'phosphor-react';

function OrganisationManegement() {
  const { t } = useTranslation();
  const organisations: Organisation[] = useCombinedStore((state: any) => state.organisations);
  const listOrganisations = useCombinedStore((state: any) => state.listOrganisations);
  const odrMIIMapping = useCombinedStore((state: any) => state.odrMIIMapping);
  const params = useParams()
  const [currentUserRole, setCurrentUserRole] = useState<string>("MII's")
  const [listOrgParams, setListOrgParams] = useState<ListOrgParams>({})
  const [marketModal, setMarketModal] = useState(false)
  const [miis, setMIIs] = useState<Organisation[]>([])
  const [organisationParent, setOrganisationParent] = useState<OrganisationParent>({})
  const resendOrganisationInvitation = useCombinedStore((state: any) => state.resendOrganisationInvitation);
  const navigate = useNavigate()
  const [currentUser, setCurrentUser] = useState<User>({})

  const delayedQuery = useRef(_.debounce((listOrgParams) => listOrganisations(listOrgParams), 500)).current;

  const role: string = params.role || "mii"

  const [limit, setLimit] = useState<number>(25)
  const [page, setPage] = useState<number>(1)

  const fetchMIIDetails = async () => {
    try {
      const orgData =
        await ApiController.getInstance().organisationApi.listOrganisations({
          orgType: 'MII'
        });
      setMIIs(orgData.data)
    } catch (error: any) {
      notifyError(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    if (role === "odr") {
      fetchMIIDetails()
    }
  }, [])

  useEffect(() => {
    ApiController.getInstance().user$.subscribe((user: any) => {
      if (user) {
        setCurrentUser(user)
      }
    });
  }, [])

  useEffect(() => {
    switch (role) {
      case "odr":
        setCurrentUserRole("ODR's")
        setListOrgParams({
          orgType: 'ODR',
        })
        break;
      case "mii":
        setCurrentUserRole("MII's")
        setListOrgParams({
          orgType: 'MII',
        })
        break;
      case "intermediary":
        setCurrentUserRole("Intermediaries")
        setListOrgParams({
          orgType: 'INTERMEDIARY',
        })
        break;
      default:
        setCurrentUserRole("MII's")
        setListOrgParams({
          orgType: 'MII',
        })
        break;
    }
  }, [role])

  const linkODRMII = () => {
    odrMIIMapping(organisationParent)
  }

  const resendInvitation = (orgId: number) => {
    resendOrganisationInvitation(orgId);
  };

  const pendingVerification = () => {
    navigate('/mii/settings/organisation-management/pending-verification')
  }

  const searchOrganisation = (e: any) => {
    setListOrgParams({
      ...listOrgParams,
      search: e
    })
  }

  useEffect(() => {
    setListOrgParams({
      ...listOrgParams,
      limit: limit,
      offset: (page - 1) * limit
    })
  }, [page, limit]);

  useEffect(() => {
    delayedQuery(listOrgParams);
  }, [listOrgParams])


  return (
    <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl">
        <p className='text-xs text-back-gray'><Link to="/mii/settings">{t('Settings')}</Link> &gt; {t('UserManagement')}</p>
        <h1 className="text-3xl font-semibold text-highlight-blue pb-3">{t('Settings')}</h1>
        <div className="bg-white w-full h-full flex flex-col rounded-xl gap-7">
          <Link to="/mii/settings" className="py-3 border-b border-F5F5F5 text-xs pl-7">{t("Back")}</Link>
          <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
            <i className="ph-bold ph-user-circle-plus p-3 border border-F5F5F5 rounded-full"></i>
            <div className="flex flex-col gap-2">
              <p className="text-sm">{t(currentUserRole)}</p>
              <p className="text-xs text-back-gray">{t('Additional')} {t(titleCase(role))}</p>
            </div>
          </div>
          <div className="flex flex-col w-1/2 gap-2 mt-2 rounded-xl bg-light-blue text-highlight-blue text-xs h-fit p-3">
            <p className="font-bold">{t('MarketParticipantVerification')}</p>
            <p>{t('MarketParticipantVerificationCount', {
              count: organisations?.filter((org: Organisation) => org.OrganisationParents?.filter((i: OrganisationParent) => i.status === 'AWAITING_APPROVAL' && i.parentId === currentUser.orgId)?.length > 0).length
            })}
            </p>
            <button onClick={pendingVerification} className="w-1/2 bg-highlight-blue disabled:bg-highlight-blue/40 hover:bg-highlight-blue/[0.8] mt-4 text-white text-sm py-4 px-2 rounded-lg" aria-label={t('Save')}>
              {t('CompleteVerification')}
            </button>
          </div>
          <div className="flex flex-row justify-between pl-7">
            <div className="flex flex-row w-1/2 rounded-xl border border-border-grey pl-2">
              <i className="ph-bold ph-magnifying-glass p-2 "></i>
              <input type="text" onChange={(e) => searchOrganisation(e.target.value)} name="search" id="search" aria-label={t('search')} className="text-xs py-2.5 w-full rounded-xl px-2" />
            </div>
            <Link to="organisation-settings" className="text-xs text-back-gray pr-7 pt-3 hover:text-black/60 cursor-pointer">{t('Add')} {t(titleCase(role))}</Link>
            {role === 'intermediary' &&
              <Link to="/mii/settings/organisation-management/rejected-market-participant" className="text-xs text-back-gray pr-7 pt-3 hover:text-black/60 cursor-pointer">{t('Rejected')} {t(titleCase(role))}</Link>
            }
          </div>
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                  {t('Name')}
                </th>
                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                </th>
              </tr>
            </thead>
            <tbody>
              {organisations?.filter((org: Organisation) => org.status !== 'AWAITING_APPROVAL' && org.status !== 'REJECTED' && org.status !== 'RE-OPEN')?.map((org: Organisation) => {
                return (
                  <tr key={org?.orgId}>
                    <td className="border-b p-3 font-normal text-xs">
                      <Link to={`./organisation-settings/${org?.orgId}`}>{org?.organisationName} <br />
                        {org?.primaryEmail}</Link>
                    </td>
                    <td className="border-b font-normal text-xs">
                      <Tooltip
                        title={"Edit Organization"}
                        position="bottom"
                        arrow={false}
                        animation="shift"
                        size="small"
                        {...({ children: <Link to={`organisation-settings/${org.orgId}`}><i className="ph-bold ph-pencil-simple-line cursor-pointer hover:text-border-grey mr-4 text-base"></i></Link> } as any)} // Type assertion to bypass TS errors
                      >
                      </Tooltip>

                      <Tooltip
                        title={"List Users"}
                        position="bottom"
                        arrow={false}
                        animation="shift"
                        size="small"
                        {...({ children: <Link to={`../user-management?orgId=${org.orgId}&role=${role}`}><i className="ph-bold cursor-pointer text-base hover:text-border-grey ph-user-list mr-4"></i></Link> } as any)} // Type assertion to bypass TS errors
                      >
                      </Tooltip>
                      {role === "odr" &&
                        <Tooltip
                          title={"Link MII"}
                          position="bottom"
                          arrow={false}
                          animation="shift"
                          size="small"
                          {...({
                            children: <><button onClick={() => {
                              setOrganisationParent({
                                ...organisationParent,
                                orgId: org.orgId,
                              }); setMarketModal(true)
                            }}> <i className="ph-bold cursor-pointer text-base hover:text-border-grey ph-plus-square mr-4"></i></button></>
                          } as any)} // Type assertion to bypass TS errors
                        >
                        </Tooltip>
                      }
                      <Tooltip
                        title={"Resend Verification Link"}
                        position="bottom"
                        arrow={false}
                        animation="shift"
                        size="small"
                        {...({ children: <i className="ph-bold cursor-pointer text-base hover:text-border-grey ph-arrow-counter-clockwise mr-4" onClick={(e) => resendInvitation(org?.orgId)}></i> } as any)} // Type assertion to bypass TS errors
                      >
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex flex-row gap-2 justify-end mb-2">
            {page > 1 &&
              <button aria-label={t('PreviousPage')} onClick={() => setPage(page - 1)} className="p-2 mt-2 rounded-lg"><ArrowLeft size={20} weight="bold" /></button>
            }
            <p className=" p-2 mt-2 rounded-lg" aria-label={`On Page ${page}`}>{page}</p>
            {organisations.length >= limit &&
              <button aria-label={t('NextPage')} onClick={() => setPage(page + 1)} className=" p-2 mt-2 rounded-lg"><ArrowRight size={20} weight="bold" /></button>
            }
          </div>
        </div>

      </div>

      {marketModal && (
        <div className="fixed z-10 inset-0 flex items-center justify-center " id="modal">
          <div className="px-8 sm:px-0 sm:w-2/3 h-3/4">
            <div className="flex justify-center pt-4 pb-20 h-full">
              <div
                className="fixed inset-0 transition-opacity"
                onClick={() => setMarketModal(false)}
              >
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
              </div>
              <div
                className="inline-block align-center bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >

                <div className="flex flex-col gap-1 px-8 pb-5" style={{ height: "-webkit-fill-available" }}>
                  <div className="flex flex-row w-full justify-between items-center">
                    <h1 className='font-semibold sm:text-2xl text-lg pt-3'>All MII</h1>
                    <i className={`ph-bold ph-x text-lg text-label-grey cursor-pointer`} onClick={() => setMarketModal(false)}></i>
                  </div>
                  <div className="w-full min-h-fit overflow-hidden overflow-y-auto flex flex-col">
                    <div className='flex flex-col gap-4 pb-3 pt-4 text-label-grey overflow-y-scroll'>
                      <select
                        aria-label="State Dropdown" required name='state' id='state' onChange={(e) => setOrganisationParent({
                          ...organisationParent,
                          parentId: e.target.value
                        })}
                        className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={organisationParent?.parentId}
                      >
                        <option value={''}>Select MII</option>
                        {miis?.map((mii: Organisation) => {
                          return <option key={mii?.orgId} value={mii?.orgId}>{mii.organisationName}</option>
                        })}
                      </select>
                    </div>
                    <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>

                      <button onClick={() => linkODRMII()} aria-label='Proceed' type="submit" className='hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12'>
                        {t('Proceed')}
                      </button>
                      <button onClick={() => setMarketModal(false)} aria-label='Cancel' type="button" className='hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl'>
                        {t("Cancel")}
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      )}

    </div>
  )
}

export default OrganisationManegement