/* tslint:disable */
/* eslint-disable */
/**
 * OCP Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ArbitrationReportFile } from '../models';
import { DashboardCategoryReport } from '../models';
import { DashboardReport } from '../models';
import { DashboardReportParams } from '../models';
import { ODRInfoBank } from '../models';
import { ODRInfoBankOutput } from '../models';
import { SignedUrl } from '../models';
import { SubCategoryMaster } from '../models';
/**
 * IndexApi - axios parameter creator
 * @export
 */
export const IndexApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Dashboard Category specific report
         * @summary reset user's password
         * @param {DashboardReportParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardCategoryReport: async (body: DashboardReportParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling dashboardCategoryReport.');
            }
            const localVarPath = `/dashboard_category_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Dashboard report
         * @summary reset user's password
         * @param {DashboardReportParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardReport: async (body: DashboardReportParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling dashboardReport.');
            }
            const localVarPath = `/dashboard_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all files for a complainId
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRedactedAwardsUrl: async (url: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            if (url === null || url === undefined) {
                throw new RequiredError('url','Required parameter url was null or undefined when calling generateRedactedAwardsUrl.');
            }
            const localVarPath = `/generate_redacted_awards_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * InfoBank aggregate specific report
         * @summary InfoBank ODR specific report
         * @param {ODRInfoBank} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odrInfobankAggregateReport: async (body: ODRInfoBank, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling odrInfobankAggregateReport.');
            }
            const localVarPath = `/odr_infobank_aggregate_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * InfoBank ODR specific report
         * @summary InfoBank ODR specific report
         * @param {ODRInfoBank} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odrInfobankReport: async (body: ODRInfoBank, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling odrInfobankReport.');
            }
            const localVarPath = `/odr_infobank_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * InfoBank redacted awards specific report
         * @summary InfoBank ODR specific redacted awards
         * @param {number} odrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odrRedactedAwards: async (odrId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'odrId' is not null or undefined
            if (odrId === null || odrId === undefined) {
                throw new RequiredError('odrId','Required parameter odrId was null or undefined when calling odrRedactedAwards.');
            }
            const localVarPath = `/odr_redacted_awards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (odrId !== undefined) {
                localVarQueryParameter['odrId'] = odrId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the category masters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoryMasterList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/list_subcategory_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndexApi - functional programming interface
 * @export
 */
export const IndexApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Dashboard Category specific report
         * @summary reset user's password
         * @param {DashboardReportParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardCategoryReport(body: DashboardReportParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardCategoryReport>> {
            const localVarAxiosArgs = await IndexApiAxiosParamCreator(configuration).dashboardCategoryReport(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Dashboard report
         * @summary reset user's password
         * @param {DashboardReportParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardReport(body: DashboardReportParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardReport>> {
            const localVarAxiosArgs = await IndexApiAxiosParamCreator(configuration).dashboardReport(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all files for a complainId
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateRedactedAwardsUrl(url: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrl>> {
            const localVarAxiosArgs = await IndexApiAxiosParamCreator(configuration).generateRedactedAwardsUrl(url, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * InfoBank aggregate specific report
         * @summary InfoBank ODR specific report
         * @param {ODRInfoBank} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async odrInfobankAggregateReport(body: ODRInfoBank, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ODRInfoBankOutput>> {
            const localVarAxiosArgs = await IndexApiAxiosParamCreator(configuration).odrInfobankAggregateReport(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * InfoBank ODR specific report
         * @summary InfoBank ODR specific report
         * @param {ODRInfoBank} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async odrInfobankReport(body: ODRInfoBank, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ODRInfoBankOutput>> {
            const localVarAxiosArgs = await IndexApiAxiosParamCreator(configuration).odrInfobankReport(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * InfoBank redacted awards specific report
         * @summary InfoBank ODR specific redacted awards
         * @param {number} odrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async odrRedactedAwards(odrId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArbitrationReportFile>>> {
            const localVarAxiosArgs = await IndexApiAxiosParamCreator(configuration).odrRedactedAwards(odrId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the category masters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcategoryMasterList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubCategoryMaster>>> {
            const localVarAxiosArgs = await IndexApiAxiosParamCreator(configuration).subcategoryMasterList(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * IndexApi - factory interface
 * @export
 */
export const IndexApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Dashboard Category specific report
         * @summary reset user's password
         * @param {DashboardReportParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardCategoryReport(body: DashboardReportParams, options?: any): AxiosPromise<DashboardCategoryReport> {
            return IndexApiFp(configuration).dashboardCategoryReport(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Dashboard report
         * @summary reset user's password
         * @param {DashboardReportParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardReport(body: DashboardReportParams, options?: any): AxiosPromise<DashboardReport> {
            return IndexApiFp(configuration).dashboardReport(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all files for a complainId
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRedactedAwardsUrl(url: string, options?: any): AxiosPromise<SignedUrl> {
            return IndexApiFp(configuration).generateRedactedAwardsUrl(url, options).then((request) => request(axios, basePath));
        },
        /**
         * InfoBank aggregate specific report
         * @summary InfoBank ODR specific report
         * @param {ODRInfoBank} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odrInfobankAggregateReport(body: ODRInfoBank, options?: any): AxiosPromise<ODRInfoBankOutput> {
            return IndexApiFp(configuration).odrInfobankAggregateReport(body, options).then((request) => request(axios, basePath));
        },
        /**
         * InfoBank ODR specific report
         * @summary InfoBank ODR specific report
         * @param {ODRInfoBank} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odrInfobankReport(body: ODRInfoBank, options?: any): AxiosPromise<ODRInfoBankOutput> {
            return IndexApiFp(configuration).odrInfobankReport(body, options).then((request) => request(axios, basePath));
        },
        /**
         * InfoBank redacted awards specific report
         * @summary InfoBank ODR specific redacted awards
         * @param {number} odrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odrRedactedAwards(odrId: number, options?: any): AxiosPromise<Array<ArbitrationReportFile>> {
            return IndexApiFp(configuration).odrRedactedAwards(odrId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the category masters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoryMasterList(options?: any): AxiosPromise<Array<SubCategoryMaster>> {
            return IndexApiFp(configuration).subcategoryMasterList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IndexApi - object-oriented interface
 * @export
 * @class IndexApi
 * @extends {BaseAPI}
 */
export class IndexApi extends BaseAPI {
    /**
     * Dashboard Category specific report
     * @summary reset user's password
     * @param {DashboardReportParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndexApi
     */
    public dashboardCategoryReport(body: DashboardReportParams, options?: any) {
        return IndexApiFp(this.configuration).dashboardCategoryReport(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Dashboard report
     * @summary reset user's password
     * @param {DashboardReportParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndexApi
     */
    public dashboardReport(body: DashboardReportParams, options?: any) {
        return IndexApiFp(this.configuration).dashboardReport(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get all files for a complainId
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndexApi
     */
    public generateRedactedAwardsUrl(url: string, options?: any) {
        return IndexApiFp(this.configuration).generateRedactedAwardsUrl(url, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * InfoBank aggregate specific report
     * @summary InfoBank ODR specific report
     * @param {ODRInfoBank} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndexApi
     */
    public odrInfobankAggregateReport(body: ODRInfoBank, options?: any) {
        return IndexApiFp(this.configuration).odrInfobankAggregateReport(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * InfoBank ODR specific report
     * @summary InfoBank ODR specific report
     * @param {ODRInfoBank} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndexApi
     */
    public odrInfobankReport(body: ODRInfoBank, options?: any) {
        return IndexApiFp(this.configuration).odrInfobankReport(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * InfoBank redacted awards specific report
     * @summary InfoBank ODR specific redacted awards
     * @param {number} odrId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndexApi
     */
    public odrRedactedAwards(odrId: number, options?: any) {
        return IndexApiFp(this.configuration).odrRedactedAwards(odrId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the category masters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndexApi
     */
    public subcategoryMasterList(options?: any) {
        return IndexApiFp(this.configuration).subcategoryMasterList(options).then((request) => request(this.axios, this.basePath));
    }
}
