import { ArrowLeft, ArrowRight } from 'phosphor-react';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useCombinedStore } from '../../../store';
import { User } from '../../../swagger/models';
import { useTranslation } from 'react-i18next';

function UsersList() {
    const { t } = useTranslation();
    const users: User[] = useCombinedStore((state: any) => state.users);
    const listUsers = useCombinedStore((state: any) => state.listUsers);
    const [limit, setLimit] = useState<number>(10)
    const [page, setPage] = useState<number>(1)
  
    useEffect(() => {
      listUsers({});
    }, []);
  
    return (
      <div className="pt-16 w-full h-full px-20 pb-10">
        <div
          style={{ background: "#F4F4F4" }}
          className="flex w-full h-full flex-initial  px-20 py-14 flex-col gap-7 rounded-2xl"
        >
          <p className="text-xs text-back-gray">{t('Users')}</p>
          <div className="flex flex-row justify-between">
            <h1 className="text-3xl font-semibold text-highlight-blue">
            {t('Users')}
            </h1>
            <div className="flex flex-row w-fit gap-3 rounded-lg cursor-pointer px-6 py-2.5 items-center text-label-grey bg-low-grey">
              <p className="m-0 text-xs">{t('Filters')}</p>
              <i className="ph-bold ph-funnel-simple"></i>
            </div>
          </div>
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                  {t('UserName')}
                </th>
                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                  {t('email')}
                </th>
                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                  {t('MobileNumber')}
                </th>
                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                  {t('Status')}
                </th>
              </tr>
            </thead>
            <tbody>
              {users?.map((user: User) => {
                return (
                  <tr key={user?.userId}>
                    <td className="border-b p-3 font-normal text-xs">
                      <Link to={`./userDetails/${user?.userId}`}>{user?.userName}</Link>
                    </td>
                    <td className="border-b p-3 font-normal text-xs">
                      {user?.email}
                    </td>
                    <td className="border-b p-3 font-normal text-xs">
                      {user?.phoneNumber}
                    </td>
                    <td className="border-b p-3 font-normal text-xs">
                      {user?.status}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex flex-row gap-2 justify-end mb-2">
              {page > 1 &&
                <button aria-label={t('PreviousPage')} onClick={() => setPage(page - 1)} className="p-2 mt-2 rounded-lg"><ArrowLeft size={20} weight="bold" /></button>
              }
              <p className=" p-2 mt-2 rounded-lg" aria-label={`On Page ${page}`}>{page}</p>
              {users.length >= limit &&
                <button aria-label={t('NextPage')} onClick={() => setPage(page + 1)} className=" p-2 mt-2 rounded-lg"><ArrowRight size={20} weight="bold" /></button>
              }
            </div>
        </div>
      </div>
  )
}

export default UsersList