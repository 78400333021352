/* tslint:disable */
/* eslint-disable */
/**
 * OCP Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ListOrgParams } from '../models';
import { MarketParticipantApproval } from '../models';
import { MarketParticipantRejection } from '../models';
import { MergeOrganisation } from '../models';
import { Organisation } from '../models';
import { OrganisationParent } from '../models';
/**
 * OrganisationApi - axios parameter creator
 * @export
 */
export const OrganisationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary API to send Invitation to org again
         * @param {MarketParticipantApproval} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveMarketParticipants: async (body: MarketParticipantApproval, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling approveMarketParticipants.');
            }
            const localVarPath = `/admin/org-management/approve_market_participants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add the details of specific organisation
         * @param {Organisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganisation: async (body: Organisation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createOrganisation.');
            }
            const localVarPath = `/admin/org-management/organisation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of specific organisation
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisation: async (orgId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getOrganisation.');
            }
            const localVarPath = `/admin/org-management/organisation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the organisations
         * @param {ListOrgParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganisations: async (body: ListOrgParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listOrganisations.');
            }
            const localVarPath = `/admin/org-management/list_organisations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API to merge duplicate organisations information
         * @param {MergeOrganisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeDuplicateOrganisation: async (body: MergeOrganisation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mergeDuplicateOrganisation.');
            }
            const localVarPath = `/admin/org-management/merge_duplicate_organisation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API to Link ODR with MII account
         * @param {OrganisationParent} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odrMiiLinking: async (body: OrganisationParent, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling odrMiiLinking.');
            }
            const localVarPath = `/admin/org-management/odr_mii_linking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API to Reject Market Participants application
         * @param {MarketParticipantRejection} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectMarketParticipants: async (body: MarketParticipantRejection, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling rejectMarketParticipants.');
            }
            const localVarPath = `/admin/org-management/reject_market_participants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API to send Invitation to org again
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInvitationOrganisation: async (orgId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling resendInvitationOrganisation.');
            }
            const localVarPath = `/admin/org-management/resend_invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API to send Invitation to MP to reregister again
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendMpReRegistration: async (orgId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling resendMpReRegistration.');
            }
            const localVarPath = `/admin/org-management/resend_mp_re_registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the details of specific organisation
         * @param {Organisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganisation: async (body: Organisation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateOrganisation.');
            }
            const localVarPath = `/admin/org-management/organisation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationApi - functional programming interface
 * @export
 */
export const OrganisationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary API to send Invitation to org again
         * @param {MarketParticipantApproval} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveMarketParticipants(body: MarketParticipantApproval, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).approveMarketParticipants(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add the details of specific organisation
         * @param {Organisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganisation(body: Organisation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).createOrganisation(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the details of specific organisation
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganisation(orgId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).getOrganisation(orgId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the organisations
         * @param {ListOrgParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrganisations(body: ListOrgParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Organisation>>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).listOrganisations(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary API to merge duplicate organisations information
         * @param {MergeOrganisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mergeDuplicateOrganisation(body: MergeOrganisation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).mergeDuplicateOrganisation(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary API to Link ODR with MII account
         * @param {OrganisationParent} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async odrMiiLinking(body: OrganisationParent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).odrMiiLinking(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary API to Reject Market Participants application
         * @param {MarketParticipantRejection} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectMarketParticipants(body: MarketParticipantRejection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).rejectMarketParticipants(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary API to send Invitation to org again
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendInvitationOrganisation(orgId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).resendInvitationOrganisation(orgId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary API to send Invitation to MP to reregister again
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendMpReRegistration(orgId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).resendMpReRegistration(orgId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the details of specific organisation
         * @param {Organisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganisation(body: Organisation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).updateOrganisation(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrganisationApi - factory interface
 * @export
 */
export const OrganisationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary API to send Invitation to org again
         * @param {MarketParticipantApproval} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveMarketParticipants(body: MarketParticipantApproval, options?: any): AxiosPromise<void> {
            return OrganisationApiFp(configuration).approveMarketParticipants(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add the details of specific organisation
         * @param {Organisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganisation(body: Organisation, options?: any): AxiosPromise<Organisation> {
            return OrganisationApiFp(configuration).createOrganisation(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of specific organisation
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisation(orgId: number, options?: any): AxiosPromise<Organisation> {
            return OrganisationApiFp(configuration).getOrganisation(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the organisations
         * @param {ListOrgParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganisations(body: ListOrgParams, options?: any): AxiosPromise<Array<Organisation>> {
            return OrganisationApiFp(configuration).listOrganisations(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API to merge duplicate organisations information
         * @param {MergeOrganisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeDuplicateOrganisation(body: MergeOrganisation, options?: any): AxiosPromise<void> {
            return OrganisationApiFp(configuration).mergeDuplicateOrganisation(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API to Link ODR with MII account
         * @param {OrganisationParent} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odrMiiLinking(body: OrganisationParent, options?: any): AxiosPromise<void> {
            return OrganisationApiFp(configuration).odrMiiLinking(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API to Reject Market Participants application
         * @param {MarketParticipantRejection} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectMarketParticipants(body: MarketParticipantRejection, options?: any): AxiosPromise<void> {
            return OrganisationApiFp(configuration).rejectMarketParticipants(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API to send Invitation to org again
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInvitationOrganisation(orgId: number, options?: any): AxiosPromise<void> {
            return OrganisationApiFp(configuration).resendInvitationOrganisation(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API to send Invitation to MP to reregister again
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendMpReRegistration(orgId: number, options?: any): AxiosPromise<void> {
            return OrganisationApiFp(configuration).resendMpReRegistration(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the details of specific organisation
         * @param {Organisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganisation(body: Organisation, options?: any): AxiosPromise<Organisation> {
            return OrganisationApiFp(configuration).updateOrganisation(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationApi - object-oriented interface
 * @export
 * @class OrganisationApi
 * @extends {BaseAPI}
 */
export class OrganisationApi extends BaseAPI {
    /**
     * 
     * @summary API to send Invitation to org again
     * @param {MarketParticipantApproval} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public approveMarketParticipants(body: MarketParticipantApproval, options?: any) {
        return OrganisationApiFp(this.configuration).approveMarketParticipants(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Add the details of specific organisation
     * @param {Organisation} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public createOrganisation(body: Organisation, options?: any) {
        return OrganisationApiFp(this.configuration).createOrganisation(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the details of specific organisation
     * @param {number} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public getOrganisation(orgId: number, options?: any) {
        return OrganisationApiFp(this.configuration).getOrganisation(orgId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the organisations
     * @param {ListOrgParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public listOrganisations(body: ListOrgParams, options?: any) {
        return OrganisationApiFp(this.configuration).listOrganisations(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary API to merge duplicate organisations information
     * @param {MergeOrganisation} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public mergeDuplicateOrganisation(body: MergeOrganisation, options?: any) {
        return OrganisationApiFp(this.configuration).mergeDuplicateOrganisation(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary API to Link ODR with MII account
     * @param {OrganisationParent} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public odrMiiLinking(body: OrganisationParent, options?: any) {
        return OrganisationApiFp(this.configuration).odrMiiLinking(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary API to Reject Market Participants application
     * @param {MarketParticipantRejection} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public rejectMarketParticipants(body: MarketParticipantRejection, options?: any) {
        return OrganisationApiFp(this.configuration).rejectMarketParticipants(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary API to send Invitation to org again
     * @param {number} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public resendInvitationOrganisation(orgId: number, options?: any) {
        return OrganisationApiFp(this.configuration).resendInvitationOrganisation(orgId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary API to send Invitation to MP to reregister again
     * @param {number} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public resendMpReRegistration(orgId: number, options?: any) {
        return OrganisationApiFp(this.configuration).resendMpReRegistration(orgId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update the details of specific organisation
     * @param {Organisation} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public updateOrganisation(body: Organisation, options?: any) {
        return OrganisationApiFp(this.configuration).updateOrganisation(body, options).then((request) => request(this.axios, this.basePath));
    }
}
