import { ArrowLeft, ArrowRight } from 'phosphor-react';
import { useEffect, useState } from 'react'
import { useCombinedStore } from '../../../store';
import { RoleMaster } from '../../../swagger/models';
import { useTranslation } from 'react-i18next';

function RolesList() {
  const { t } = useTranslation();
  const roleMasters: RoleMaster[] = useCombinedStore((state: any) => state.roleMasters);
  const listRoles = useCombinedStore((state: any) => state.listRoles);

  const [limit, setLimit] = useState<number>(10)
  const [page, setPage] = useState<number>(1)

  useEffect(() => {
    listRoles();
  }, []);

  /**
   * 
   * @param object 
   * @returns 
   */
  const docsExist = (object: any): boolean => {
    return Object.keys(object).some((k) => object[k] === true) ? true : false
  }

  return (
    <div className="pt-16 w-full h-full px-20 pb-10">
      <div
        style={{ background: "#F4F4F4" }}
        className="flex w-full h-full flex-initial  px-20 py-14 flex-col gap-7 rounded-2xl"
      >
        <p className="text-xs text-back-gray">{t('Roles')}</p>
        <div className="flex flex-row justify-between">
          <h1 className="text-3xl font-semibold text-highlight-blue">
            {t('Roles')}
          </h1>
        </div>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                {t('RoleName')}
              </th>
              <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                {t('Module')}
              </th>
            </tr>
          </thead>
          <tbody>
            {roleMasters?.map((role: RoleMaster) => {
              return (
                <tr key={role?.roleId}>
                  <td className="border-b p-3 font-normal text-xs">
                    {/* <Link to={`./roleDetails/${role?.roleId}`}> */}
                    {role?.roleName}
                    {/* </Link> */}
                  </td>
                  <td className="border-b p-3 font-normal text-xs">
                    {Object.keys(role.permissions)?.map((permission: any) => {
                      return <span className='p-1'>{docsExist(role.permissions[permission]) && permission}</span>
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex flex-row gap-2 justify-end mb-2">
          {page > 1 &&
            <button aria-label={t('PreviousPage')} onClick={() => setPage(page - 1)} className="p-2 mt-2 rounded-lg"><ArrowLeft size={20} weight="bold" /></button>
          }
          <p className=" p-2 mt-2 rounded-lg" aria-label={`On Page ${page}`}>{page}</p>
          {roleMasters.length >= limit &&
            <button aria-label={t('NextPage')} onClick={() => setPage(page + 1)} className=" p-2 mt-2 rounded-lg"><ArrowRight size={20} weight="bold" /></button>
          }
        </div>
      </div>
    </div>
  );
}

export default RolesList