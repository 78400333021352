import React from 'react';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

/**
 * InfoTooltip component displays a tooltip with a given title when the user clicks on
 * a question mark icon. The tooltip is positioned to the right of the icon and has a subtle
 * animation.
 * @param {{title: string}} props - The title of the tooltip.
 * @returns {React.ReactElement} A React element representing the tooltip.
 */
const InfoTooltip = ({ title }: any) => {
  return (
    <Tooltip
      title={title}
      position="right"
      trigger="click"
      arrow={true}
      animation="shift"
      {...({ children: <i className="ph-bold text-xl ph-question cursor-pointer"></i> } as any)} // Type assertion to bypass TS errors
    >

    </Tooltip>
  );
};

export default InfoTooltip;