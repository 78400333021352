import { useLocation } from "react-router-dom";
import { useCombinedStore } from "../../../store";
import { Complain } from "../../../swagger/models";
import { useTranslation } from 'react-i18next';

function FilingSuccess() {

    const complaintDetail: Complain = useCombinedStore((state: any) => state.complaint);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const mii = searchParams.get('mii')
    const { t } = useTranslation();

    return (
        <div className="pt-7 w-full h-full pr-1 sm:pr-4 pl-1 pb-7">
            <div
                style={{ background: '#F4F4F4' }}
                className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl">
                <p className='text-xs text-back-gray'>{t('FileADispute')}</p>
                <div className="flex flex-row w-full h-fit gap-2 pb-2 items-center">
                    <p className="text-2xl font-bold">{t('DisputeRegisteredSuccessfully')}!</p>
                    <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/Green-Tick-Vector-PNG-Images.png" width={"38px"} />
                </div>
                <div className="flex flex-col gap-2">
                    <p className="text-xs">{t(`YourDisputeRegisteredSuccessfully`, {
                        complaintUUID: complaintDetail?.complaintUUID
                    })}. {t(`AnEmailSent`, {
                        email: email
                    })}</p>
                    <p className="text-xs">{t(`AuthorizedRepresentativeTouch`, {
                        mii
                    })}</p>
                </div>
                <h1 className="text-2xl font-bold pt-24">{t('NextStepsforyourDispute')}</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-6 items-start justify-start sm:px-1">
                    <div className="flex flex-col gap-2.5">
                        <p className="rounded-lg py-3 w-fit px-5 bg-light-blue text-highlight-blue text-2xl font-bold">1</p>
                        <p className="text-2xl font-bold">{t('IntermediaryConfirmation')}</p>
                        <p className="text-xs text-label-grey w-full sm:w-3/4">{t('IntermediaryConfirmDispute')}</p>
                    </div>
                    <div className="flex flex-col gap-2.5">
                        <p className="rounded-lg py-3 w-fit px-5 bg-light-blue text-highlight-blue text-2xl font-bold">2</p>
                        <p className="text-2xl font-bold">{t('MIIReview')}</p>
                        <p className="text-xs text-label-grey w-full sm:w-2/3">{t('MIIAssociatedDisputRefer')}</p>
                    </div>
                    <div className="flex flex-col gap-2.5">
                        <p className="rounded-lg py-3 w-fit px-5 bg-light-blue text-highlight-blue text-2xl font-bold">3</p>
                        <p className="text-2xl font-bold">{t('ODRInstitutionSelection')}</p>
                        <p className="text-xs text-label-grey w-full sm:w-1/2">{t('OnlineDisputeResolutionInstitutionRound')}</p>
                    </div>
                    <div className="flex flex-col gap-2.5">
                        <p className="rounded-lg py-3 w-fit px-5 bg-light-blue text-highlight-blue text-2xl font-bold">4</p>
                        <p className="text-2xl font-bold">{t('Conciliation')}</p>
                        <p className="text-xs text-label-grey w-full sm:w-2/3">{t('ODRInstitutionAppointConciliator')}</p>
                    </div>
                    <div className="flex flex-col gap-2.5">
                        <p className="rounded-lg py-3 w-fit px-5 bg-light-blue text-highlight-blue text-2xl font-bold">5</p>
                        <p className="text-2xl font-bold">{t('Arbitration')}</p>
                        <p className="text-xs text-label-grey w-full sm:w-2/3">{t('FailureConciliationArbitrationInitiated')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilingSuccess