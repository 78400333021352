import InvestorSidebar from "./InvestorSidebar";

function InvestorContainer() {
  return (
    <div className="App h-full">
      <div className="h-full">
        <InvestorSidebar/>
      </div>
    </div>
  );
}

export default InvestorContainer;