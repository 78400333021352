import { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import { User } from "../../../swagger/models";
import { ApiController } from "../../../apiController";
import { useTranslation } from "react-i18next";

/**
 * @description
 * This component is used to display the success message after
 * a user initiates arbitration. The user is shown a message
 * indicating that the arbitration has been successfully initiated
 * and that the MII will review the case and provide a decision
 * within 30 days.
 *
 * @param {User} user The user object from the state
 * @param {string} complaintID The ID of the complaint
 * @returns {JSX.Element} A JSX element containing the success message
 */
function ArbitrationSuccess() {
  const location = useLocation();
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(location.search);
  const complaintID = searchParams.get("complainId");
  const urlarray = window.location.href.split("/");

  const [user, setUser] = useState<User>({});

  useEffect(() => {
    ApiController.getInstance().UserLoggedIn$.subscribe((user: User | null) => {
      if (user) {
        setUser(user);
      }
    });
  }, []);

  const currentURL = window.location.href.split("/")[3];

  return (
    <div className="pt-7 w-full h-full pr-1 sm:pr-4 pl-1 pb-7">
      <div
        style={{ background: "#F4F4F4" }}
        className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl"
      >
        <div className="flex flex-row w-full justify-between items-center">
          <p className="hidden sm:block text-xs text-back-gray">
            <Link to={`/${currentURL}/disputes`}>{t("disputeHistory")}</Link>{" "}
            &gt;{" "}
            <Link to={`/${currentURL}/disputes/disputeDetails/${urlarray[6]}`}>
              {urlarray[6]}
            </Link>{" "}
            &gt; {t('InitiatedSuccessfully')}
          </p>
          <Link to={`/${currentURL}/disputes`} className="text-back-gray">
            X
          </Link>
        </div>

        <div className="flex flex-row w-full h-fit gap-2 pb-2 items-center">
          <p className="text-2xl font-semibold">
            {t('ArbitrationInitiatedSuccessfully')}!
          </p>
          <img
            src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/Green-Tick-Vector-PNG-Images.png"
            width={"38px"}
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-xs">
            {t(`SuccessfullyInitatedArbitration`, {
              complaintID,
              email: user?.email,
            })}
          </p>
          <p className="text-xs">
            {t('Note')}: {t('RredressedWithin30')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ArbitrationSuccess;
