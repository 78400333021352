import React, { Fragment, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../common-module/loader';
import { ApiController } from '../../apiController';

function ScoresRedirection() {
    const location = useLocation();
    const params = useParams()

    const searchParams = new URLSearchParams(location.search);
    const complaintUUID: string = params.complaintUUID!
    const userToken = searchParams.get('token');

  useEffect(() => {
    if (complaintUUID && userToken) {
        console.log('complaintUUID', complaintUUID)
        localStorage.setItem("TOKEN", userToken);
        ApiController.getInstance().initApis(userToken);
        ApiController.getInstance().updateLoggedInStatus({userType: 'Investor'});  
        ApiController.getInstance().updateUserProfile({userType: 'Investor'});
        window.location.href = `/investor/disputeDetails/${complaintUUID}`
      }
  }, [complaintUUID, userToken]);

  return (
    <Fragment>
      <Loader/>
    </Fragment>
  );
}

export default ScoresRedirection