import { useTranslation } from 'react-i18next';

/**
 * HowToFileAComplaint component
 * 
 * This component is used to display the steps that a user needs to follow
 * to file a dispute on the SMART ODR platform.
 * 
 * The component displays the following steps:
 * 1. File a new dispute
 * 2. Select an intermediary against which the dispute is to be filed
 * 3. Select the category of the dispute
 * 4. Fill in the details of the dispute
 * 5. Track the progress of the dispute
 * 
 * The component also displays a message at the bottom of the page that
 * instructs the user to go to the track page once they have filed the dispute.
 * 
 * The component uses the `useTranslation` hook to get the translations for
 * the text that is displayed on the page.
 */
function HowToFileAComplaint() {
  const { t } = useTranslation();
  return (
    <div
      style={{ background: '#F4F4F4' }}
      className="flex w-full h-full flex-initial items-center px-8 py-16 flex-col gap-2 rounded-2xl">
      <h1 className="text-lg sm:text-2xl font-bold pb-3">{t('FileDisputeSMARTODR')}</h1>
      <p className="text-xs text-label-grey">{t('SMARTODRConnectsODR')}</p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-14 items-start justify-start sm:px-24">
        <div className="flex flex-col gap-2.5">
          <p className="rounded-lg py-3 w-fit px-5 bg-light-blue text-highlight-blue text-2xl font-bold">1</p>
          <p className="text-2xl font-bold">{t('fileNewDispute')}</p>
          <p className="text-xs text-label-grey w-full sm:w-1/2">{t('ClickFileNewDispute')}</p>
        </div>
        <div className="flex flex-col gap-2.5">
          <p className="rounded-lg py-3 w-fit px-5 bg-light-blue text-highlight-blue text-2xl font-bold">2</p>
          <p className="text-2xl font-bold">{t('SelectIntermediary')}</p>
          <p className="text-xs text-label-grey w-full sm:w-1/2">{t('SelectIntermediaryAgainst')}</p>
        </div>
        <div className="flex flex-col gap-2.5">
          <p className="rounded-lg py-3 w-fit px-5 bg-light-blue text-highlight-blue text-2xl font-bold">3</p>
          <p className="text-2xl font-bold">{t('SelectCategory')}</p>
          <p className="text-xs text-label-grey w-full sm:w-1/2">{t('SelectCategoryStockExchangeDepository')}</p>
        </div>
        <div className="flex flex-col gap-2.5">
          <p className="rounded-lg py-3 w-fit px-5 bg-light-blue text-highlight-blue text-2xl font-bold">4</p>
          <p className="text-2xl font-bold">{t('FillDisputeDetails')}</p>
          <p className="text-xs text-label-grey w-full sm:w-1/2">{t('FillDetailsDispute')}</p>
        </div>
        <div className="flex flex-col gap-2.5">
          <p className="rounded-lg py-3 w-fit px-5 bg-light-blue text-highlight-blue text-2xl font-bold">5</p>
          <p className="text-2xl font-bold">{t('TrackProgress')}</p>
          <p className="text-xs text-label-grey w-full sm:w-1/2">{t('OnceDisputeFiled')}</p>
        </div>
      </div>
      <div className="flex h-full justify-end flex-col">
        <p className="text-xs text-label-grey">{t('OnceFileTrackPage')}</p>
      </div>

    </div>
  )
}

export default HowToFileAComplaint