import { useEffect, useState } from "react"
import Notifications from "../Admin/Notifications/Notifications"
import { Tooltip } from "react-tippy"
import { Rating, User } from "../../swagger/models"
import { ApiController } from "../../apiController"
import { useCombinedStore } from "../../store"
import { useTranslation } from 'react-i18next';

function RightSideBar() {
  const { t } = useTranslation();
  const [user, setUser] = useState<User>({})
  const [ratingsModal, setRatingsModal] = useState(false)
  const [hoveredStars, setHoveredStars] = useState(0);
  const [rating, setRating] = useState<Rating>({
    type: 'SYSTEM'
  });
  const captureRating = useCombinedStore((state: any) => state.captureRating)

  const handleStarHover = (starIndex: any) => {
    setHoveredStars(starIndex);
  };

  const handleStarClick = (starIndex: any) => {
    setRating({
      ...rating,
      rating: starIndex
    })
  };

  const handleStarLeave = () => {
    setHoveredStars(0);
  };

  const renderStars = () => {
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      const isHovered = i <= hoveredStars;
      const isSelected = i <= rating.rating;

      stars.push(
        <i
          key={i}
          className={`ph-fill ph-star ${isHovered || isSelected ? ' text-yellow-400' : ''}`}
          onMouseOver={() => handleStarHover(i)}
          onClick={() => handleStarClick(i)}
        ></i>
      );
    }

    return stars;
  };

  useEffect(() => {
    ApiController.getInstance().user$.subscribe((user: any) => {
      if (user) {
        setUser(user)
      }
    });
  }, [])

  const sendFeedback = () => {
    captureRating(rating);
    setRatingsModal(false);
  }

  const [showNotification, setShowNotification] = useState(false)
  return (
    <div className='hidden sm:flex flex-col h-screen sticky top-0 bg-grey-b-1 pl-2 pr-4 text-left justify-between text-label-grey'>
      <div className="flex flex-col gap-6 w-fit bg-white overflow-hidden pt-10">

        <Tooltip
          title={t('Notifications')}
          position="bottom"
          arrow={false}
          animation="shift"
          size="small"
          {...({ children: <i className="ph-bold ph-bell cursor-pointer transform hover:-translate-y-1 transition-transform ease-in duration-200 text-gray-500 hover:text-highlight-blue" onClick={() => setShowNotification(!showNotification)}></i> } as any)} // Type assertion to bypass TS errors
        >
        </Tooltip>
        {user?.userId &&
          <Notifications isOpen={showNotification} setOpen={setShowNotification} />
        }
        <Tooltip
          title={t("Help")}
          position="bottom"
          arrow={false}
          animation="shift"
          size="small"
          {...({
            children: <a href="https://help.smartodr.in/" target="_blank" className="cursor-pointer transform hover:-translate-y-1 transition-transform ease-in duration-200 text-gray-500 hover:text-highlight-blue">
              <i className="ph-bold ph-question"></i>
            </a>
          } as any)} // Type assertion to bypass TS errors
        >
        </Tooltip>
        <Tooltip
          title={user?.Rating?.userId ? `${t('ReceivedRating')}!` : t('Rating')}
          disabled={user?.Rating?.userId}
          position="bottom"
          arrow={false}
          animation="shift"
          size="small"
          {...({ children: <i className="ph-bold ph-star hover:cursor-pointer" onClick={() => user?.Rating?.userId ? setRatingsModal(false) : setRatingsModal(true)}></i> } as any)} // Type assertion to bypass TS errors
        >
        </Tooltip>
      </div>
      {ratingsModal &&
        (
          <div
            className="fixed z-10 inset-0 flex items-center justify-center "
            id="modal"
          >
            <div className="px-8 sm:px-0 sm:w-1/3">
              <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setRatingsModal(false)}
                >
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                  className="inline-block align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <form >
                    <div className="flex flex-col gap-1 px-8 pb-5">
                      <h1 className='font-semibold sm:text-2xl text-lg pt-3'>{t('ValueFeedback')}</h1>
                      <div className='flex flex-col gap-4 pb-3 pt-4 text-label-grey'>
                        <p className="text-xs text-label-grey">{t('RateExperience')}</p>
                        <div className="flex flex-row gap-2">
                          {renderStars()}
                        </div>
                        <p className="text-xs text-label-grey">{t('TellusWhy')}</p>
                        <textarea onChange={(e) => setRating({
                          ...rating,
                          comment: e.target.value
                        })} placeholder={t('YourFeedback')} name="bugReport" id="bugReport" rows={4} className="text-xs border rounded-lg w-full p-2" />
                      </div>
                      <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>

                        <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12" aria-label={t('submit')}
                          onClick={sendFeedback}
                        >
                          <p>{t('submit')}</p>
                        </button>
                        <button aria-label={t('cancel')} onClick={() => setRatingsModal(false)} className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                          {t('cancel')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )

      }
    </div>
  )
}

export default RightSideBar