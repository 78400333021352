import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

interface PDFPreviewProps {
  url: string;
}

const PDFPreview = ({ url }: PDFPreviewProps) => {
  return (
    <div style={{ height: '750px' }}>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <Viewer fileUrl={url} />
      </Worker>
    </div>
  );
};

export default PDFPreview;
