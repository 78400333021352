import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useCombinedStore } from '../../../store';
import { ListOrgParams, Organisation, OrganisationParent, User } from '../../../swagger/models';
import { useTranslation } from 'react-i18next';
import { titleCase } from '../../common-module/helper';
import { Tooltip } from 'react-tippy';
import { ApiController } from '../../../apiController';


/**
 * This component is used to display the list of market participant applications which are rejected.
 * The component is only accessible by an organisation admin.
 * The component displays the list of options for managing users, including
 *     - MII profiles
 *     - Market Participants
 *     - ODR institutions
 *     - Users
 * The component also displays the organisation name and the number of users associated with the organisation.
 */
function MarketParticipantRejected() {
  const { t } = useTranslation();
  const organisations: Organisation[] = useCombinedStore((state: any) => state.organisations);
  const listOrganisations = useCombinedStore((state: any) => state.listOrganisations);
  const [listOrgParams, setListOrgParams] = useState<ListOrgParams>({})
  const resenMPInvitation = useCombinedStore((state: any) => state.resenMPInvitation);
  const [currentUser, setCurrentUser] = useState<User>({})

  useEffect(() => {
    if (listOrgParams.orgType) {
      listOrganisations(listOrgParams);
    }
  }, [listOrgParams]);

  useEffect(() => {
    setListOrgParams({
      orgType: 'INTERMEDIARY',
      limit: 25,
      offset: 0,
    })
  }, [])

  useEffect(() => {
    ApiController.getInstance().user$.subscribe((user: any) => {
      if (user) {
       setCurrentUser(user) 
      }
    });
  }, [])

  const resendInvitation = (orgId: number) => {
    resenMPInvitation(orgId);
  };

  return (
    <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl">
        <p className='text-xs text-back-gray'><Link to="/mii/settings">{t('Settings')}</Link> &gt; {t('UserManagement')}</p>
        <h1 className="text-3xl font-semibold text-highlight-blue pb-3">{t('Settings')}</h1>
        <div className="bg-white w-full h-full flex flex-col rounded-xl gap-7">
          <Link to="/mii/settings" className="py-3 border-b border-F5F5F5 text-xs pl-7">{t("Back")}</Link>
          <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
            <i className="ph-bold ph-user-circle-plus p-3 border border-F5F5F5 rounded-full"></i>
            <div className="flex flex-col gap-2">
              <p className="text-sm">{t('Intermediaries')}</p>
              <p className="text-xs text-back-gray">{t('Rejected')} {t(titleCase('intermediary'))}</p>
            </div>
          </div>
          <div className="flex flex-row justify-between pl-7">
            <div className="flex flex-row w-1/2 rounded-xl border border-border-grey pl-2">
              <i className="ph-bold ph-magnifying-glass p-2 "></i>
              <input type="text" name="search" id="search" aria-label={t('search')} className="text-xs py-2.5 w-full rounded-xl px-2" />
            </div>
          </div>
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                  {t('Name')}
                </th>
                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                  {t('Status')}
                </th>
                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                </th>
              </tr>
            </thead>
            <tbody>
              {organisations?.filter((org: Organisation) => org.status === 'REJECTED' || org.status === 'RE-OPEN' || org.OrganisationParents?.filter((i: OrganisationParent) => (i.status === 'REJECTED')&& i.parentId === currentUser.orgId)?.length > 0)?.map((org: Organisation) => {
                return (
                  <tr key={org?.orgId}>
                    <td className="border-b p-3 font-normal text-xs">
                      <Link to={`./organisation-settings/${org?.orgId}`}>{org?.organisationName}</Link>
                    </td>
                    <td className="border-b p-3 font-normal text-xs">
                      {titleCase(org?.status)}
                    </td>
                    <td className="border-b font-normal text-xs">
                      <Tooltip
                        title={"Send Application update Link"}
                        position="bottom"
                        arrow={false}
                        animation="shift"
                        size="small"
                        {...({ children: <i className="ph-bold cursor-pointer text-base hover:text-border-grey ph-arrow-counter-clockwise mr-4" onClick={(e) => resendInvitation(org?.orgId)}></i> } as any)} // Type assertion to bypass TS errors
                      >
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

      </div>
    </div>
  )
}

export default MarketParticipantRejected