import { useCombinedStore } from "../../../store";
import { ComplainNature } from "../../../swagger/models";
import { dateTimeHelper } from "../../common-module/helper";
import { useTranslation } from 'react-i18next';

function PreScoresConciliation({ complaintDetails }: any) {
  const { t } = useTranslation();

  const downloadFileOfComplaint = useCombinedStore((state: any) => state.downloadFile)

  const downloadFile = async (key: any, complainNatureId: number) => {
    let res = await downloadFileOfComplaint(key, complaintDetails?.complainId, complainNatureId, null)
    if (!res.error) window.open(res.file)
  }

  return (
    <div className="flex sm:flex-row flex-col justify-between">
      <div className="sm:w-2/3 w-full">
        {complaintDetails?.ComplainNatures?.filter((complainNature: ComplainNature) => complainNature.otherMetaData.isFromScores)?.map((complainNature: ComplainNature, index: number) => {
          return (
            <div className="flex flex-row justify-between">
              <div key={complainNature?.complainNatureId} className="w-full flex flex-col gap-4 pr-10 pt-10">
                <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                  <p className="text-xs text-label-grey sm:w-1/3 font-semibold">{t('SCORESComplaintId')}: </p>
                  <p className="text-xs text-label-grey sm:w-2/3">{complaintDetails?.primaryCaseRefId}</p>
                </div>
                <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                  <p className="text-xs text-label-grey sm:w-1/3 font-semibold">{t('ComplaintRegistrationDate')}: </p>
                  <p className="text-xs text-label-grey sm:w-2/3">{dateTimeHelper(complaintDetails?.createdAt)}</p>
                </div>
                <div className="flex sm:flex-row flex-col w-full">
                  <div className="flex flex-col sm:w-1/3">
                    <p className="text-xs text-label-grey">{index === 0 ? `${t('DisputeDetails')}:` : ''}</p>
                    <p className="text-xs text-back-gray">{index === 0 ? `${complaintDetails?.ComplainNatures?.filter((complainNature: ComplainNature) => complainNature.otherMetaData.isFromScores)?.length} ${complaintDetails?.ComplainNatures?.filter((complainNature: ComplainNature) => complainNature.otherMetaData.isFromScores)?.length > 1 ? t('Disputes') : t('Dispute')} ${t('Filed')}` : ''}</p>
                  </div>
                  <div className="flex flex-col sm:w-2/3 gap-3">
                    <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                      <p className="text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complainNature?.natureType} / {complainNature?.subNatureType}</p>
                    </div>
                    <textarea className="border border-border-grey disabled:bg-disable-grey py-3 px-5 text-xs text-label-grey rounded-xl"
                      rows={7} disabled={true}
                      value={complainNature?.description}></textarea>
                  </div>
                </div>
                <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full pr-10 pt-5">
                  <p className="text-xs sm:w-1/3 text-label-grey">{index === 0 ? t('Attachments') : ''}:</p>
                  <div className="flex flex-col sm:w-2/3 gap-2">
                    {complainNature?.files?.map((file: any) => {
                      return (
                        <div className="flex flex-col gap-2 text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey bg-white rounded-xl">
                          <p className="cursor-pointer" onClick={(e) => downloadFile(file.file || file.key, complainNature?.complainNatureId)}>{file?.fileName}</p>
                          <p className="text-back-gray">{t('uploadedby', {
                            name: file?.uploadedBy,
                            date: dateTimeHelper(file?.uploadedAt)
                          })}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>)
        })}
      </div>
    </div>
  )
}

export default PreScoresConciliation