import { useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * SendMIIReviewModal component displays a modal to initiate the review process by the MII.
 * The modal includes a "Proceed" button which triggers the handleStatusChange callback with 
 * 'AWAITING_MII_REVIEW' status and is enabled only if the terms and conditions are accepted.
 * There is also a cancel button which closes the modal without proceeding.
 *
 * Props:
 * - setSendMIIReview: Function to control the visibility of the modal.
 * - handleStatusChange: Callback function executed upon clicking the "Proceed" button.
 */
const SendMIIReviewModal = ({ setSendMIIReview, handleStatusChange }: any) => {
  const [tAndC, setTAndC] = useState(false)
  const { t } = useTranslation();

  return (
    <div
    className="fixed z-10 inset-0 flex items-center justify-center "
    id="modal"
  >
    <div className="px-8 sm:px-0 sm:w-1/3">
      <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
        <div
          className="fixed inset-0 transition-opacity"
          onClick={() => setSendMIIReview(false)}
        >
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <div
          className="inline-block align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <form >
            <div className="flex flex-col gap-1 px-8 pb-5">
              <h1 className='font-semibold sm:text-2xl text-lg pt-3'>{t('SendMIIReview')}</h1>
              <div className='flex flex-col gap-4 pb-3 pt-4 text-label-grey'>
                <p className="text-xs text-label-grey">{t('SendDisputeMIIReview')}</p>
              </div>
              <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>

                <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12" aria-label={t('Proceed')}
                  onClick={(e: any) => handleStatusChange(e, 'AWAITING_MII_REVIEW', '')} 
                  disabled={!tAndC}
                >
                  <p>{t('Proceed')}</p>
                </button>
                <button aria-label={t('cancel')} onClick={() => setSendMIIReview(false)} className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                {t('cancel')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
  );
};


export default SendMIIReviewModal;