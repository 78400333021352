import { useTranslation } from 'react-i18next';

/**
 * A modal that displays information about arbitration, including fees and what to expect once arbitration has been initiated.
 *
 * Props:
 * - setArbInfoModal: A function to control the visibility of the modal.
 */
function ArbitrationInfoModal({ setArbInfoModal }: any) {
  const { t } = useTranslation();
  return (
    <div className="fixed z-10 inset-0 flex items-center justify-center " id="modal">
      <div className="px-8 sm:px-0 sm:w-1/3">
        <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => setArbInfoModal(false)}
          >
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <div
            className="inline-block overflow-hidden overflow-y-scroll align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style={{
              height: "66vh", // Set the modal height to 66% of the viewport height
              top: "50%", // Vertically center the modal on the screen
            }}
          >
            <form >
              <div className="flex flex-col gap-1 px-8 pb-5">
                <div className="flex flex-row justify-between items-center border-b-2 border-highlight-blue pb-2">
                  <h1 className='font-semibold sm:text-xl text-lg pt-3'>{t('WhatcanIdoonceArbitrationhasbeenInitiated')}</h1>
                  <p className="text-sm text-back-gray cursor-pointer" onClick={() => setArbInfoModal(false)}>X</p>
                </div>
                <div className='flex flex-col text-xs gap-4 pb-3 pt-4 text-label-grey'>
                  <p>{t('OnceArbitrationhasbeeninitiatied')}</p>
                  <p>{t('Ifyoufailtopaywithinthegiven')}</p>
                  <p>{t('ArbitrationProceedingsbegin')}</p>
                  <p>{t('NotePayArbitration')}</p>
                  <p>{t('TheArbitrationFeespayable')}</p>
                  <table className=" w-full text-xs table-auto border-separate border-spacing-0 rounded-xl">
                    <thead>
                      <tr>
                        <th className=" border border-label-grey p-4 text-center  rounded-tl-lg ">{t('ClaimAmount')}</th>
                        <th className="border-y border-r p-4 text-center border-label-grey">{t('ArbitrationFees')}</th>
                        <th className="border-y border-r p-4 text-center border-label-grey rounded-tr-lg">{t('ODRInstitutionFees')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border-b border-x border-label-grey p-3 text-center">{t('0-1Lakh')}</td>
                        <td className="border-b border-r border-label-grey p-3 text-center">Rs. 4800</td>
                        <td className="border-b border-r border-label-grey p-3 text-center">Rs. 600</td>
                      </tr>
                      <tr>
                        <td className="border-b border-x border-label-grey p-3 text-center">{t('1-10Lakh')}</td>
                        <td className="border-b border-r border-label-grey p-3 text-center">Rs. 8000</td>
                        <td className="border-b border-r border-label-grey p-3 text-center">Rs. 1000</td>
                      </tr>
                      <tr>
                        <td className="border-b border-x border-label-grey p-3 text-center">{t('10-20Lakh')}</td>
                        <td className="border-b border-r border-label-grey p-3 text-center">Rs. 12000</td>
                        <td className="border-b border-r border-label-grey p-3 text-center">Rs. 1500</td>
                      </tr>
                      <tr>
                        <td className="border-b border-x border-label-grey p-3 text-center">{t('20-30Lakh')}</td>
                        <td className="border-b border-r border-label-grey p-3 text-center">Rs. 16000</td>
                        <td className="border-b border-r border-label-grey p-3 text-center">Rs. 2000</td>
                      </tr>
                      <tr>
                        <td className="border-b border-x border-label-grey p-3 text-center">{t('30-50Lak')}</td>
                        <td className="border-b border-r border-label-grey p-3 text-center">Rs. 60000</td>
                        <td className="border-b border-r border-label-grey p-3 text-center">Rs. 7500</td>
                      </tr>
                      <tr>
                        <td className="border-b border-x border-label-grey p-3 text-center rounded-bl-lg">{t('50Lakh')}</td>
                        <td className="border-b border-r border-label-grey p-3 text-center">Rs. 120000</td>
                        <td className="border-b border-r border-label-grey p-3 text-center rounded-br-lg">Rs. 15000</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>*{t('TheabovementionedFees')}</p>
                  <p>**{t('BothArbitrationFeesODRInstitutionFees')}</p>
                  <p>{t('Itisrecommended')}</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ArbitrationInfoModal