import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, NavLink, useParams } from 'react-router-dom'
import { forgetPasswordValidationSchema } from '../../helpers/validation'
import { useCombinedStore } from '../../store'
import { useTranslation } from 'react-i18next';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { ForgotPasswordParams } from '../../swagger/models'

/**
* This component renders a forgot password page where the user can enter their email 
* and receive a password recovery link.
* 
* @returns A JSX element that renders the forgot password page.
*/
function ForgotPassword() {
  // Get the URL parameters 
  const params = useParams();

  // Get the translation function
  const { t } = useTranslation();

  // Parse the search parameters from the URL
  const searchParams = new URLSearchParams(location.search);

  // Initialize OTPRequest state with default email and role
  const [OTPRequest, setOTPRequest] = useState<ForgotPasswordParams>({
    email: localStorage.getItem('email') || '',
    role: searchParams.get('role')
  });

  // State to trigger captcha refresh
  const [refreshCaptcha, setRefreshCaptcha] = useState<number>(0);

  // Access the forgotPassword function from the global store
  const forgotPassword = useCombinedStore((state: any) => state.forgotPassword);

  // Form options with validation schema
  const formOptions = {
    resolver: yupResolver(forgetPasswordValidationSchema),
  };

  // Initialize form handling with react-hook-form
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  // Handle input change for email
  const onInputChange = (e: any) => {
    const { value } = e.target;
    // Update the OTPRequest state with new email value
    setOTPRequest({
      ...OTPRequest,
      email: value,
    });
  };

  // Handle form submission
  async function onSubmit(e: any) {
    // Execute forgotPassword function with current OTPRequest state
    forgotPassword(OTPRequest);
    // Trigger captcha refresh
    setRefreshCaptcha(refreshCaptcha + 1);
    return false;
  }

  // Handle captcha verification
  const onVerify = useCallback((token: string) => {
    // Update OTPRequest state with new captcha token
    setOTPRequest({
      ...OTPRequest,
      captchaToken: token
    });
  }, [refreshCaptcha]);

  return (
    <div className="flex flex-row justify-between pt-7 px-10 lg:pl-32 pb-7 w-full h-screen">
      <div className="flex flex-col h-full w-full lg:w-1/2 sm:pt-20 pt-10 justify-between">
        {/* Google Recaptcha component */}
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={true} />
        <div>
          <div className='flex flex-row justify-between items-start'>
            {/* Logo and navigation link to login */}
            <NavLink to="/login">
              <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '175px', height: 'fit' }} />
            </NavLink>
            <div className='hidden lg:block'>
              <div className='grid grid-cols-2'>
                {/* Conditional navigation links based on user role */}
                {OTPRequest.role !== 'odr' && <Link to="/odr/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('ODRInstitution')} {t('login')}</Link>}
                {OTPRequest.role !== 'mii' && <Link to="/mii/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('MII')} {t('login')}</Link>}
                {OTPRequest.role !== 'intermediary' && <Link to="/intermediary/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('MarketParticipant')} {t('login')}</Link>}
                {OTPRequest.role !== 'investor' && <Link to="/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('Investor')} {t('login')}</Link>}
              </div>
            </div>
          </div>
          {/* Page heading */}
          <h1 className="pt-32 text-3xl text-highlight-blue font-bold">{t('forgotPassword')}</h1>
          {/* Account related links */}
          <p className="text-xs pt-2">{t('noaccount')} <Link to="/register" style={{ color: '#306AFF' }}>{t('createAccount')}</Link></p>
          <p className="text-xs pt-2">{t('AlreadyHaveAccount')} <Link to="/login" style={{ color: '#306AFF' }}>{t('Loginhere')}</Link></p>
          {/* Form for submitting email to receive password recovery link */}
          <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col pt-12 gap-2'>
            <label htmlFor="email" className="text-xs">{t('email')}</label>
            <input required aria-label={t('email')} {...register('email')} type="text" id='email' onChange={onInputChange} className={`rounded-lg border p-2 border-solid ${errors.email?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
            <span className="text-xs text-highlight-red">{errors.email?.message}</span>

            <button disabled={!OTPRequest.email} aria-label='Send me Link' type='submit' className='bg-highlight-blue hover:bg-highlight-blue/[0.8] mt-4 text-white text-sm py-4 px-28 rounded-lg'>
              {t('SendRecoveryLink')}
            </button>
          </form>
        </div>
        {/* Conditional role-based login links for smaller screens */}
        <div className='grid grid-cols-2 lg:hidden pb-14'>
          {OTPRequest.role !== 'odr' && <Link to="/odr/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('ODRInstitution')} {t('login')}</Link>}
          {OTPRequest.role !== 'mii' && <Link to="/mii/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('MII')} {t('login')}</Link>}
          {OTPRequest.role !== 'intermediary' && <Link to="/intermediary/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">Market Participant {t('login')}</Link>}
          {OTPRequest.role !== 'investor' && <Link to="/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('Investor')} {t('login')}</Link>}
        </div>
      </div>
      {/* Image section for larger screens */}
      <div className='hidden lg:flex w-1/2 justify-end'>
        <div className='hidden lg:block h-full pl-8 mb-8 py-8 top-0 right-0 rounded-2xl'>
          <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/HeroIMAGESMARTODR.png"
            className='w-fit overflow-hidden h-full rounded-xl' alt="hero" />
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword