import { useState } from "react"
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

/**
 * @function TrackStatus
 * @description A function component to track the status of a complain.
 * @returns {JSX.Element} A JSX element containing a form with a text input
 * and a button. The text input is used to enter the dispute ID,
 * and the button is used to track the status of the dispute.
 * The button is disabled until the dispute ID is entered.
 * When the button is clicked, the component navigates to the dispute details page.
 */
function TrackStatus() {

    const navigate = useNavigate();
    const [complaintId, setComplaint] = useState<string>("");
    const { t } = useTranslation();

    const trackStatus = () => {
        const currentURLs = window.location.href.split('/')
        navigate(`/${currentURLs[3]}/disputes/disputeDetails/${complaintId}`);
    }

    return (
        <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
            <div
                style={{ background: '#F4F4F4' }}
                className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2  rounded-2xl">
                <p className='hidden sm:block text-xs text-back-gray'>{t('TrackStatus')}</p>
                <h1 className="text-lg sm:text-3xl font-semibold text-highlight-blue pb-3">{t('TrackStatus')}</h1>
                <div className="flex flex-col gap-2">
                    <label htmlFor="trackStatus" className="text-xs text-label-grey">{t('EnterDisputeID')}</label>
                    <input name='complaintId' id='complaintId' onChange={(e) => setComplaint(e.target.value)} required aria-label={t('TrackStatus')} type="text" className='rounded-xl sm:w-1/3 border p-2 border-border-grey border-solid' />
                </div>
                <button disabled={!complaintId} onClick={trackStatus} aria-label='Proceed' type='submit' className='bg-highlight-blue w-fit text-center hover:bg-highlight-blue/[0.8] text-white text-xs py-3 px-20 rounded-xl'>
                {t('Proceed')}
                </button>
            </div>
        </div>
    )
}

export default TrackStatus