import { toast } from "react-hot-toast";
import { ApiController } from "../apiController";
import { loader, notifyError } from "../helpers/helper";

export const RoleStore = (set: any, get: any, store: any) => ({
  roleMasters: [],
  roleDetail: {},
  /**
   *
   * @param complaintUUID
   */
  getRole: async (complaintUUID: string) => {
    try {
    //   const roleData =
    //     await ApiController.getInstance().roleApi.(complaintUUID);
    //   set({
    //     roleDetail: roleData.data,
    //   });
    } catch (error: any) {
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   */
  listRoles: async () => {
    const toastId = loader("Loading Roles...");
    try {
      const roleData =
        await ApiController.getInstance().roleApi.listRoles();
      set({
        roleMasters: roleData.data,
      });
      toast.dismiss(toastId)
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
});
