import { useTranslation } from "react-i18next";

interface ClaimValueAlertProps {
  setClaimValueModal?: any;
  claimValueModal?: boolean;
  setEditClaimValue?: any;
}

function ClaimValueAlert({
  setClaimValueModal,
  setEditClaimValue,
}: ClaimValueAlertProps) {

  const { t } = useTranslation();
  const updateModal = () => {
    setClaimValueModal(false)
    setEditClaimValue(true)
  }

  return (
    <div
      className="fixed z-10 inset-0 flex items-center justify-center "
      id="modal"
    >
      <div className="px-8 sm:px-0 sm:w-1/3">
        <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => setClaimValueModal(false)}
          >
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <div
            className="inline-block align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <form >
              <div className="flex items-center flex-col gap-1 px-8 pb-5">
                <div className="flex items-center">
                  <img style={{ width: '60px', position: 'fixed', height: 'fit-content', paddingTop: '40px' }} src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/redwarn.png" />
                  <iframe width="200" className="pl-8" src="https://lottie.host/embed/ad27134f-6d1a-4cf2-a777-452751541f4e/1YPevjKtdi.json"></iframe>
                </div>
                <div className='flex flex-col gap-2 pb-3 pt-4 text-center text-label-grey'>
                  <h5 className="text-lg font-semibold text-odr-grey">Alert: That seems like an unusually high amount</h5>
                  <p className="text-sm text-odr-grey">Please ensure the claim value entered is correct. <br />Extraordinarily high claim values will be scrutinized.</p>
                </div>
                <div className='flex flex-col justify-center items-center sm:flex-row w-full pt-4 gap-4'>
                  <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12" aria-label={t('Edit')}
                    onClick={updateModal}
                  >
                    <p>{t('Edit')}</p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  );
}

export default ClaimValueAlert;
