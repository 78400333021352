import MIISidebar from "./MIISidebar";

function MIIContainer() {
  return (
    <div className="App h-full">
      <div className="h-full">
        <MIISidebar/>
      </div>
    </div>
  );
}

export default MIIContainer;