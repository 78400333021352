import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { ApiController } from "../../../apiController"
import { internalChangePasswordValidationSchema } from "../../../helpers/validation"
import { useCombinedStore } from "../../../store"
import { ChangePassword } from "../../../swagger/models"
import { encryptString } from "../../common-module/helper"
import { useTranslation } from 'react-i18next';

/**
 * PrivacySettings component renders a form for the user to change their password.
 * It validates the input using a Yup schema, encrypts the password before submission,
 * and navigates the user upon successful password update.
 *
 * @returns A JSX element that renders the change password form.
 */
function PrivacySettings() {
  const { t } = useTranslation();
  const [changePassword, setChangePassword] = useState<ChangePassword>({})
  const [encryptedPassword, setEncryptedPassword] = useState<ChangePassword>({})
  const authChangePassword = useCombinedStore((state: any) => state.authChangePassword)
  const isPasswordUpdated = useCombinedStore((state: any) => state.isPasswordUpdated)
  const currentURL = window.location.href.split('/')[3]
  const navigate = useNavigate()

  const formOptions = {
    resolver: yupResolver(internalChangePasswordValidationSchema),
  }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors } = formState

  const onInputChange = (e: any) => {
    const { name, value } = e.target
    setChangePassword({
      ...changePassword,
      [name]: value,
    })
    setEncryptedPassword({
      ...encryptedPassword,
      [name]: encryptString(value),
    })
  }

  async function onSubmit(e: any) {
    // e.preventDefault()
    authChangePassword(encryptedPassword)
    return false
  }

  const redirect = () => {
    navigate(`/${currentURL}/settings`)
  }

  useEffect(() => {
    if (isPasswordUpdated) {
      navigate(`/${currentURL}/login`)
      ApiController.getInstance().clearToken();
    }
  }, [isPasswordUpdated])

  return (
    <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl">
        <p className='sm:block hidden text-xs text-back-gray'><Link to={`/${currentURL}/settings`}>{t("Settings")}</Link> &gt; {t('Security')}</p>
        <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue pb-3">{t('PrivacySettings')}</h1>
        <div className="bg-white w-full flex flex-col rounded-xl gap-4">
          <Link to={`/${currentURL}/settings`} className="py-3 border-b border-F5F5F5 text-xs pl-7">{t("Back")}</Link>
          <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
            <i className="ph-bold ph-key sm:p-3 p-2 border border-border-grey rounded-full"></i>
            <div className="flex flex-col sm:gap-2 gap-1">
              <p className="sm:text-sm text-xs">{t('Security')}</p>
              <p className="sm:text-xs text-[10px] text-back-gray">{t('ChangePassword')}</p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} >
            <div className="flex flex-col gap-2 px-7 sm:w-1/2">
              <label htmlFor="oldPassword" className="text-xs">{t('OldPassword')}</label>
              <input {...register('oldPassword')} required aria-label={t('OldPassword')} type="password" name='oldPassword' id='oldPassword' onChange={onInputChange} className={`rounded-xl border p-2 text-xs border-solid ${errors.oldPassword?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.oldPassword?.message}</span>

              <label htmlFor="newPassword" className="text-xs">{t('NewPassword')}</label>
              <input {...register('newPassword')} required aria-label={t('NewPassword')} type="password" name='newPassword' id='newPassword' onChange={onInputChange} className={`rounded-xl border p-2 text-xs border-solid ${errors.newPassword?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.newPassword?.message}</span>

              <label htmlFor="confPassword" className="text-xs">{t('ConfirmPassword')}</label>
              <input {...register('confPassword')} required aria-label={t('ConfirmPassword')} type="password" name='confPassword' id='confPassword' onChange={onInputChange} className={`rounded-xl border text-xs p-2 border-solid  mb-3 ${errors.confPassword?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.confPassword?.message}</span>

            </div>
            <div className="flex flex-row gap-3 pl-7 pt-2">
              <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2.5 rounded-lg disabled:bg-highlight-blue/[0.4]" aria-label={t('SavePassword')}>
                {t('SavePassword')}
              </button>
              <button onClick={redirect} aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2.5 rounded-lg">
              {t('cancel')}
              </button>
            </div>
          </form>
          <p className="pb-3 text-xs pl-7">{" "}</p>
        </div>

      </div>
    </div>
  )
}

export default PrivacySettings