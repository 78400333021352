import { create } from "zustand";
import { AuthStore } from "./store/authStore";
import { ComplainStore } from "./store/complainStore";
import { MIIStore } from "./store/miiStore";
import { OCPState } from "./store/state";
import { OCPAction } from "./store/action";
import { RoleStore } from "./store/roleStore";
import { UserStore } from "./store/userStore";
import { CategoryStore } from "./store/categoryStore";
import { OrganisationStore } from "./store/orgStore";


export const useCombinedStore = create<OCPState & OCPAction>((...params) => ({
  ...ComplainStore(...params),
  ...MIIStore(...params),
  ...AuthStore(...params),
  ...RoleStore(...params),
  ...UserStore(...params),
  ...CategoryStore(...params),
  ...OrganisationStore(...params),
}));
