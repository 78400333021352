import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ApiController } from "../../apiController"
import { Rating, User } from "../../swagger/models";
import { notifySuccess } from "../../helpers/helper";
import { useTranslation } from 'react-i18next';
import { useCombinedStore } from "../../store";
import Notifications from "../Admin/Notifications/Notifications";

function InvestorSidebar() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [user, setUser] = useState<User>({})
  const [showIconList, setShowIconList] = useState(true);
  const [showFullScreenComponent, setShowFullScreenComponent] = useState(false);
  const [bugModal, setBugModal] = useState(false)
  const [feedback, setFeedback] = useState(" ")
  const [showNotification, setShowNotification] = useState(false)
  const [ratingsModal, setRatingsModal] = useState(false)
  const [hoveredStars, setHoveredStars] = useState(0);
  const [rating, setRating] = useState<Rating>({
    type: 'SYSTEM'
  });
  const captureRating = useCombinedStore((state: any) => state.captureRating)

  const handleStarHover = (starIndex: any) => {
    setHoveredStars(starIndex);
  };

  const handleStarClick = (starIndex: any) => {
    setRating({
      ...rating,
      rating: starIndex
    })
  };

  const handleStarLeave = () => {
    setHoveredStars(0);
  };

  const renderStars = () => {
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      const isHovered = i <= hoveredStars;
      const isSelected = i <= rating.rating;

      stars.push(
        <i
          key={i}
          className={`ph-fill ph-star ${isHovered || isSelected ? ' text-yellow-400' : ''}`}
          onMouseOver={() => handleStarHover(i)}
          onClick={() => handleStarClick(i)}
        ></i>
      );
    }

    return stars;
  };

  const handleIconListClick = () => {
    setShowIconList(false);
    setShowFullScreenComponent(true);
  };

  const handleFullScreenComponentClose = () => {
    setShowIconList(true);
    setShowFullScreenComponent(false);
  };

  const sendRating = () => {
    captureRating(rating);
    setRatingsModal(false);
  }

  const sendFeedback = (e: any) => {
    e.preventDefault();
    fetch(
      "https://hooks.slack.com/services/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",
      {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: `*Name:* ${user?.userName}, Intermediary - ${user?.Organisation?.organisationName},\n*Email:* ${user?.email}\n*Bug Report:* ${feedback}`,
        }),
      }
    ).then((response) => {
      notifySuccess("Roger that! We'll get on it!");
      setBugModal(false)
    });
  };

  const logout = () => {
    navigate(`/login`);
    ApiController.getInstance().clearToken();
  };

  useEffect(() => {
    ApiController.getInstance().user$.subscribe((user: any) => {
      if (user) {
        setUser(user)
      }
    });
    if (localStorage.getItem('selectedLanguage')) {
      i18n.changeLanguage(localStorage.getItem('selectedLanguage')!)
    }

  }, [])

  const mainMenuItems = [
    {
      name: `${t('disputeHistory')}`,
      icon: 'clipboard-text',
      role: "navigation",
      link: "disputes",
      subName: 'See all your Disputes'
    },
    {
      name: `${t('fileNewDispute')}`,
      icon: 'plus-circle',
      role: "navigation",
      link: "dispute-registration",
      subName: 'File a New Dispute'
    },
    // {
    //   name: `${t('fetchScoresDispute')}`,
    //   icon: 'plus-circle',
    //   role: "navigation",
    //   link: "scores-dispute-registration",
    //   subName: 'Fetch scores Dispute'
    // },
  ]
  return (
    <>
      <div className="hidden sm:flex sticky top-0 h-screen flex-col w-fit bg-white bg-grey-b-1 text-left justify-between">
        <div className="flex flex-col w-64 bg-white overflow-hidden pt-10">
          <NavLink to="disputes">
            <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '250px', height: 'fit' }} />
          </NavLink>
          <ul className="flex flex-col gap-2 py-4">
            {mainMenuItems.map((item, i) => {
              return (
                <li key={item.name} className="group hover:bg-highlight-blue ml-2 mr-4 rounded-lg">
                  <NavLink
                    to={item.link}
                    className={({ isActive }) =>
                      isActive ? "flex flex-row items-center h-12 transform hover:translate-x-2 bg-highlight-blue rounded-lg text-white transition-transform ease-in duration-200 hover:text-white"
                        : "flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-white"}>
                    <span
                      className="inline-flex items-center justify-center h-12 w-12 text-lg">
                      <i className={`ph-bold ph-${item.icon}`}></i>
                    </span>
                    <span
                      role={item.role}
                      title={item.name}
                      className="text-sm font-medium group-hover:text-white whitespace-nowrap">{item.name}</span>
                    <span className="ml-auto mr-6 text-sm rounded-full px-2 py-px text-white"><svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.41527 0.155246C7.19868 -0.0590817 6.85577 -0.0504166 6.64943 0.174587C6.44309 0.399598 6.45147 0.755747 6.66806 0.970075L11.183 5.43727H0.541667C0.242515 5.43727 0 5.6892 0 5.99993C0 6.31067 0.242515 6.56259 0.541667 6.56259H11.183L6.66806 11.0299C6.45147 11.2442 6.44309 11.6004 6.64943 11.8254C6.85577 12.0504 7.19868 12.0591 7.41527 11.8448L12.7734 6.54324C12.8953 6.42253 12.9676 6.26851 12.99 6.10849C12.9965 6.07338 13 6.03707 13 5.99993C13 5.9628 12.9965 5.92641 12.9899 5.89123C12.9675 5.73128 12.8953 5.57741 12.7734 5.45678L7.41527 0.155246Z" fill="white" />
                    </svg>
                    </span>
                  </NavLink>
                </li>)
            })}
          </ul>
        </div>
        <div className="flex flex-col pl-2 pb-10">
          <div onClick={() => setBugModal(true)}
            className={`${bugModal ? "cursor-pointer flex flex-row gap-4 items-center h-14 transform hover:translate-x-2 transition-transform ease-in duration-200 text-white bg-highlight-blue rounded-lg mr-4 mb-2"
              : "cursor-pointer flex flex-row gap-4 items-center h-14 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-white hover:bg-highlight-blue rounded-lg mr-4 mb-2"}`}>
            <i className="pl-4 ph-bold ph-bug"></i>
            <span role="button"
              className="text-sm font-medium group-hover:text-black whitespace-nowrap"
            >
              {t('reportBug')}
            </span>
          </div>
          <div onClick={logout} className="cursor-pointer flex flex-row gap-4 items-center h-14 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-white hover:bg-highlight-blue rounded-lg mr-4 mb-2">
            <i className="pl-4 ph-bold ph-power"></i>
            <span role="button"
              className="text-sm font-medium group-hover:text-black whitespace-nowrap"
            >
              {t('logout')}
            </span>
          </div>
          <NavLink to="settings"
            className={({ isActive }) =>
              isActive ? "cursor-pointer flex flex-row gap-4 items-center h-14 transform hover:translate-x-2 transition-transform ease-in duration-200 text-white bg-highlight-blue rounded-lg mr-4 mb-2"
                : "cursor-pointer flex flex-row gap-4 items-center h-14 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-white hover:bg-highlight-blue rounded-lg mr-4 mb-2"}
          >
            <i className="pl-5 ph-bold ph-user text-xs"></i>
            <div className="flex flex-col gap-1">
              <p className="text-sm font-medium group-hover:text-black whitespace-nowrap">{user?.userName}</p>
              <p className="text-xs font-medium group-hover:text-black whitespace-nowrap">{t('Investor')}</p>
            </div>
          </NavLink>
        </div>
      </div>
      {showIconList && (
        <div className="sm:hidden flex flex-row justify-between pt-12 p-3 w-full items-center">
          <i className="ph-bold ph-list text-lg" onClick={handleIconListClick}></i>
          <NavLink to="disputes">
            <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '170px', height: 'fit' }} />
          </NavLink>
          <div className="flex gap-2 items-center">
          <i className="ph-bold ph-bell cursor-pointer transform hover:-translate-y-1 transition-transform ease-in duration-200 text-gray-500 hover:text-highlight-blue" onClick={() => setShowNotification(!showNotification)}></i>
          {user?.userId &&
          <Notifications isOpen={showNotification} setOpen={setShowNotification} />
        }
          <NavLink to="settings" className={({ isActive }) =>
            isActive ? "ph-bold ph-user text-lg text-highlight-blue" : "ph-bold ph-user text-lg"}></NavLink>
          </div>
        </div>
      )}

      {showFullScreenComponent && (
        <div className="fixed inset-0 flex bg-white flex-col z-50 h-screen">
          <div className="flex flex-row justify-between w-full px-4 pt-16 pb-8 border-b h-fit border-border-grey">
            <p>{" "}</p>
            <NavLink to="disputes" onClick={() => {
              setShowFullScreenComponent(false)
              setShowIconList(true)
            }}>
              <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '170px', height: 'fit' }} />
            </NavLink>
            <i className="ph-bold ph-x text-lg" onClick={handleFullScreenComponentClose}></i>
          </div>
          <ul className="flex flex-col gap-8 pt-8">
            {mainMenuItems.map((item, i) => {
              return (
                <li key={item.name} className="text-center">
                  <NavLink
                    to={item.link}
                    className={({ isActive }) =>
                      isActive ? "text-highlight-blue text-center flex flex-col gap-1"
                        : "text-sm text-center flex flex-col gap-1"} onClick={handleFullScreenComponentClose}>

                    <p
                      role={item.role}
                      title={item.name}
                      className="text-sm font-medium whitespace-nowrap">{item.name}</p>
                    <p className="text-xs text-border-grey">{item.subName}</p>
                  </NavLink>
                </li>)
            })}
            <li className="text-center">
              <div onClick={() => {
                setBugModal(true)
                setShowFullScreenComponent(false)
                setShowIconList(true)
              }}
                className="text-sm text-center flex flex-col gap-1">
                <p
                  role="nav"
                  title={t('reportBug')}
                  className="text-sm font-medium whitespace-nowrap">{t('reportBug')}</p>
                <p className="text-xs text-border-grey">{t('reportBugHere')}</p>
              </div>
            </li>
            <li className="text-center">
              <a href="https://help.smartodr.in/" target="_blank" className="text-sm text-center flex flex-col gap-1">
                <p
                  role="nav"
                  title={t('Help')}
                  className="text-sm font-medium whitespace-nowrap">{t('Help')}</p>
                <p className="text-xs text-border-grey">{t('Help us help you!')}</p>
              </a>
            </li>
            <li className="text-center">
              <div onClick={() => {
                setRatingsModal(true)
                setShowFullScreenComponent(false)
                setShowIconList(true)
              }}
                className="text-sm text-center flex flex-col gap-1">
                <p
                  role="nav"
                  title={t('Feedback')}
                  className="text-sm font-medium whitespace-nowrap">{t('Feedback')}</p>
                <p className="text-xs text-border-grey">{t('Rate us!')}</p>
              </div>
            </li>
            <li className="text-center">
              <div onClick={logout}
                className="text-sm text-center flex flex-col gap-1">
                <p
                  role="Logout"
                  title={t('logout')}
                  className="text-sm font-medium whitespace-nowrap">{t('logout')}</p>
                <p className="text-xs text-border-grey">Logout of the Platform</p>
              </div>
            </li>
          </ul>
        </div>
      )}

      {ratingsModal &&
        (
          <div
            className="fixed z-10 inset-0 flex items-center justify-center "
            id="modal"
          >
            <div className="px-8 sm:px-0 sm:w-1/3">
              <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setRatingsModal(false)}
                >
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                  className="inline-block align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <form >
                    <div className="flex flex-col gap-1 px-8 pb-5">
                      <h1 className='font-semibold sm:text-2xl text-lg pt-3'>{t('ValueFeedback')}</h1>
                      <div className='flex flex-col gap-4 pb-3 pt-4 text-label-grey'>
                        <p className="text-xs text-label-grey">{t('RateExperience')}</p>
                        <div className="flex flex-row gap-2">
                          {renderStars()}
                        </div>
                        <p className="text-xs text-label-grey">{t('TellusWhy')}</p>
                        <textarea onChange={(e) => setRating({
                          ...rating,
                          comment: e.target.value
                        })} placeholder={t('YourFeedback')} name="bugReport" id="bugReport" rows={4} className="text-xs border rounded-lg w-full p-2" />
                      </div>
                      <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>

                        <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12" aria-label={t('submit')}
                          onClick={sendRating}
                        >
                          <p>{t('submit')}</p>
                        </button>
                        <button aria-label={t('cancel')} onClick={() => setRatingsModal(false)} className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                          {t('cancel')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )

      }

      {bugModal &&
        (
          <div
            className="fixed z-10 inset-0 flex items-center justify-center "
            id="modal"
          >
            <div className="px-8 sm:px-0 sm:w-1/3">
              <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setBugModal(false)}
                >
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                  className="inline-block align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <form>
                    <div className="flex flex-col gap-1 px-8 pb-5">
                      <h1 className='font-semibold sm:text-2xl text-lg pt-3'>{t('reportBug')}?</h1>
                      <div className='flex flex-col gap-4 pb-3 pt-4 text-label-grey'>
                        <p className="text-xs text-label-grey">{t('bugDescription')}</p>
                        <textarea onChange={(e) => setFeedback(e.target.value)} placeholder={t('ClearAnAttachment')} name="bugReport" id="bugReport" rows={4} className="text-xs border rounded-lg w-full p-2" />
                      </div>
                      <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>

                        <button type="submit" className="hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12" aria-label={t('submit')}
                          onClick={sendFeedback}
                        >
                          <p>{t('submit')}</p>
                        </button>

                        <button aria-label={t('cancel')} onClick={() => setBugModal(false)} className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                          {t('cancel')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        )

      }
    </>

  )
}

export default InvestorSidebar


