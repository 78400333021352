import React, { Fragment, useEffect, useState } from 'react'
import ScoresFilingModal from './ScoresFilingModal'
import ScoresDisputeFiling from './ScoresDisputeFiling'
import { useCombinedStore } from '../../../store'
import ComplaintFilingScores from './ComplaintFilingScores'
import ComplaintFiling from './ComplaintFiling'

function ComplainFilingContainer() {

  const [scoresModal, setScoredModal] = useState(true)
  const [authLetterFile, setAuthLetterFile] = useState(null)
  const [scoreDetails, setScoreDetails] = useState(null)
  const [isScoresComplain, setIsScoresComplain] = useState(false)

  const scoresDisputeDetails = useCombinedStore((state: any) => state.scoresDisputeDetails);
  const clearScoresComplain = useCombinedStore((state: any) => state.clearScoresComplain);

  /**
     * 
     * @param scoreDetails  
     */
  const setIsRegisteredInScores = (scoreDetails: any, authLetterFile: any) => {
    if (authLetterFile) {
      setAuthLetterFile(authLetterFile)
    }
    setScoreDetails(scoreDetails)
  }

  useEffect(() => {
    if (scoresDisputeDetails && scoresDisputeDetails.primaryCaseRefId) {
      setIsScoresComplain(true)
      setScoredModal(false)
    }
  }, [scoresDisputeDetails])

  useEffect(() => {
    clearScoresComplain()
    setScoredModal(true)
  }, [])

  return (
    <Fragment>
      {scoresModal &&
        <ScoresFilingModal setScoredModal={setScoredModal} scoresModal={scoresModal} setIsRegisteredInScores={setIsRegisteredInScores}></ScoresFilingModal>
      }
      {isScoresComplain &&
        <ScoresDisputeFiling authLetterFile={authLetterFile}></ScoresDisputeFiling>
      }
      {!isScoresComplain &&
        <ComplaintFiling authLetterFile={authLetterFile} scoreDetails={scoreDetails}></ComplaintFiling>
      }
    </Fragment>
  )
}

export default ComplainFilingContainer