import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { useCombinedStore } from "../../../store";
import { useTranslation } from 'react-i18next';

/**
 * @description This component is used to show the arbitration pre-requisites before the 
 *              arbitration process starts. It shows the details of the fees associated 
 *              with the arbitration process and asks the user to agree to the terms
 *              and condition before proceeding.
 * @returns A React component
 */
function ArbitrationRequisites() {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const currentURL = window.location.href.split('/')[3]
  const urlarray = window.location.href.split('/')
  const [tAndC, setTAndC] = useState(false)

  const updateStatusofComplain = useCombinedStore((state: any) => state.updateStatusofComplain)
  const complaintStatus = useCombinedStore((state: any) => state.complaintStatus)

  const handleStatusChange = (e: any, status: string) => {
    e.preventDefault();
    const statusObject = {
      complainId: urlarray[6],
      status: "ARBITRATION_REQUESTED"
    }
    updateStatusofComplain(statusObject)
  }
  
  useEffect(()=> {
    if (complaintStatus === 'ARBITRATION_REQUESTED') {
      navigate(`initiated-arbitration?complainId=${urlarray[6]}`)
    }
  }, [complaintStatus])

  return (
    <div className="pt-7 w-full h-full pr-1 sm:pr-4 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2  rounded-2xl">
        <p className='hidden sm:block text-xs text-back-gray'>
          <Link to={`/${currentURL}/disputes`}>{t('disputeHistory')}</Link> &gt;
          <Link to={`/${currentURL}/disputes/disputeDetails/${urlarray[6]}`}>{urlarray[6]}</Link> &gt; {t('Initiate Arbitration')}</p>
        <h1 className="text-lg sm:text-3xl font-semibold text-highlight-blue pb-3">{t('ArbitrationPre-Requisites')}</h1>
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col gap-2 sm:w-1/2 w-full">
            <p className="text-xs text-label-grey">{t('OnceyouclickProceed')}</p>
            <p className="text-xs text-label-grey pb-2">{t('AfterSuccessfullyInitiatingArbitration')}</p>

            <table className=" w-full text-xs table-auto border-separate border-spacing-0 rounded-xl">
              <thead>
                <tr>
                  <th className=" border border-label-grey p-4  rounded-tl-lg ">{t('ClaimAmount')}</th>
                  <th className="border-y border-r p-4 border-label-grey">{t('ArbitrationFees')}</th>
                  <th className="border-y border-r p-4 border-label-grey">{t('ODRInstitutionFees')}</th>
                  <th className="border-y border-r p-4 border-label-grey rounded-tr-lg">{t('Total')}*</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-b border-x border-label-grey p-3 text-center">{t('0-1Lakh')}</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 4,800</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 600</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 5,400</td>
                </tr>
                <tr>
                  <td className="border-b border-x border-label-grey p-3 text-center">{t('1-10Lakh')}</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 8,000</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 1,000</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 9,000</td>
                </tr>
                <tr>
                  <td className="border-b border-x border-label-grey p-3 text-center">{t('10-20Lakh')}</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 12,000</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 1,500</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 13,500</td>
                </tr>
                <tr>
                  <td className="border-b border-x border-label-grey p-3 text-center">{t('20-30Lakh')}</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 16,000</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 2,000</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 18,000</td>
                </tr>
                <tr>
                  <td className="border-b border-x border-label-grey p-3 text-center">{t('30-50Lakh')}</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 60,000</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 7,500</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 67,500</td>
                </tr>
                <tr>
                  <td className="border-b border-x border-label-grey p-3 text-center">{t('50Lakh')}</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 1,20,000</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 15,000</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 1,35,000</td>
                </tr>
                <tr>
                  <td className="border-b border-x border-label-grey p-3 text-center rounded-bl-lg">{t('Above1Crore')}</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">{t('1%ClaimValue')}</td>
                  <td className="border-b border-r border-label-grey p-3 text-center">Rs. 35,000</td>
                  <td className="border-b border-r border-label-grey p-3 text-center rounded-br-lg">{t('Rs155000ormore')}</td>
                </tr>
              </tbody>
            </table>
            <p className="text-xs text-label-grey w-full pt-3">*{t('TotalIsExcluding')}</p>
            <p className="text-xs text-label-grey w-full pt-3">{t('InCasetheClaimValue')}</p>
            <div className="flex flex-row gap-2 pt-3  w-full items-start">
              <input type="checkbox" checked={tAndC} onChange={() => setTAndC(!tAndC)} />
              <p className="text-xs text-label-grey">{t('ReadArbitrationTerms')}</p>
            </div>
          </div>

          <div className="flex sm:flex-row flex-col-reverse items-center gap-2 justify-end">
            <Link to={`/${currentURL}/disputes/disputeDetails/${urlarray[6]}`} aria-label='Proceed' type='submit' className=' w-fit text-center hover:bg-border-grey/[0.8] cursor-pointer text-label-grey text-xs py-3 px-20 rounded-xl'>
              {t('cancel')}
            </Link>

            <button disabled={!tAndC} className='bg-highlight-blue w-fit text-center cursor-pointer hover:bg-highlight-blue/[0.8] disabled:bg-highlight-blue/[0.8] text-white text-xs py-3 px-20 rounded-xl' onClick={(e: any) => handleStatusChange(e, 'ARBITRATION_REQUESTED')}>{t('Proceed')}</button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ArbitrationRequisites