import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiController } from "../../apiController";
import { notifyError } from "../../helpers/helper";
import ODRSidebar from "./ODRSidebar";
import { JwtPayload, jwtDecode } from 'jwt-decode'
import moment from 'moment'

function ODRContainer() {

  const [timeLeft, setTimeLeft] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    ApiController.getInstance().user$.subscribe((user) => {
      if (user) {
        setTimeLeft(calculateTimeLeft())
      }
    })
  }, [])

  const calculateTimeLeft = () => {
    let timeLeftForSession = 0
    const base64token = localStorage.getItem('TOKEN')
    if (!base64token) {
      timeLeftForSession = 0
    } else {
      const token: any = jwtDecode<JwtPayload>(base64token)
      if (token && moment(token?.exp * 1000).diff(new Date().getTime(), 'milliseconds') > 0) {
        timeLeftForSession = moment(token?.exp * 1000).diff(new Date().getTime(), 'milliseconds')
      } else {
        notifyError('Session Expired')
        navigate('/odr/login')
        ApiController.getInstance().clearToken()
      }
    }
    // console.log(timeLeftForSession)
    return timeLeftForSession
  }

  useEffect(() => {
    // console.log("Calling timeout")
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 5000)
    return () => clearTimeout(timer)
  })

  return (
    <div className="App h-full">
      <div className="h-full">
        <ODRSidebar />
      </div>
    </div>
  );
}

export default ODRContainer;