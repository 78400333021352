import { Fragment, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiController } from '../../apiController'
import { notifyError } from '../../helpers/helper'

/**
 * This component verifies the user's email address.
 * The user is redirected to the login page after verification.
 * If the verification fails, an error message is displayed.
 */
function VerifyUser() {

    const params = useParams()
    const navigate = useNavigate()

    const token: string = params.token!

    const verifyToken = async (token: string) => {
        try {
            (await ApiController.getInstance().openApi.verifyEmail(token)).data
            navigate(`/investor/login`)
        } catch (error: any) {
            notifyError(error?.response?.data?.message);
        }
    }

    useEffect(() => {
        verifyToken(token)
    }, [token])

    return (
        <Fragment>
            <div className='flex flex-col h-full w-full align-middle items-center mt-40 gap-6'>
                <div className='flex flex-col gap-2'>
                </div>
            </div>
        </Fragment>
    )
}

export default VerifyUser