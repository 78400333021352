import { useEffect, useState } from "react"
import { ApiController } from "../../../apiController";
import { useCombinedStore } from "../../../store";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

/**
 * NotificationSettings component allows users to view and update their email and SMS notification preferences.
 * It uses translation for localization and fetches the user's current notification preferences upon mounting.
 * Users can toggle the email and SMS notification settings, and save their preferences using the provided button.
 * The component also provides navigation links for user settings.
 */
function NotificationSettings() {
  const { t } = useTranslation();
  const [emailNotification, setEmailNotification] = useState(false)
  const [smsNotification, setSmsNotification] = useState(false)
  const updateNotificationPreference = useCombinedStore((state: any) => state.updateNotificationPreference)
  const currentURL = window.location.href.split('/')[3]

  useEffect(() => {
    ApiController.getInstance().user$.subscribe((user: any) => {
      if (user) {
        setEmailNotification(user.emailPreference || false)
        setSmsNotification(user.smsPreference || false)
      }
    });
  }, [])

  const updatePreference = () => {
    updateNotificationPreference(emailNotification, smsNotification)
  }
  return (
    <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl">
        <p className='sm:block hidden text-xs text-back-gray'><Link to={`/${currentURL}/settings`}>{t('Settings')}</Link> &gt; {t('NotificationSettings')}</p>
        <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue pb-3">{t('NotificationSettings')}</h1>
        <div className="bg-white w-full flex flex-col rounded-xl gap-4">
          <Link to={`/${currentURL}/settings`} className="py-3 border-b border-F5F5F5 text-xs pl-7">{t('Back')}</Link>
          <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
            <i className="ph-bold ph-bell sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
            <div className="flex flex-col sm:gap-2 gap-1">
              <p className="sm:text-sm text-xs">{t('Notifications')} </p>
              <p className="sm:text-xs text-[10px] text-back-gray">{t('EnableOrDisable')}</p>
            </div>
          </div>
          <div className="pl-7 pb-8 pt-4 flex flex-col gap-3">
            <div className="flex gap-5 items-end">
              <p className="text-xs">{t('EmailNotifications')}</p>
              <input
                className="mr-2 mt-[0.3rem] h-3.5 w-8 
              appearance-none rounded-[0.4375rem] 
              bg-neutral-300 
              before:pointer-events-none 
              before:absolute before:h-3.5 
              before:w-3.5 before:rounded-full 
              before:bg-transparent 
              before:content-[''] 
              after:absolute after:z-[2] 
              after:-mt-[0.1875rem] after:h-5 
              after:w-5 after:rounded-full after:border-none 
              after:bg-neutral-100 
              after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] 
              after:transition-[background-color_0.2s,transform_0.2s] 
              after:content-[''] 
              checked:bg-highlight-blue
              checked:after:absolute checked:after:z-[2] 
              checked:after:-mt-[3px] checked:after:ml-[1.0625rem] 
              checked:after:h-5 checked:after:w-5 
              checked:after:rounded-full checked:after:border-none 
              checked:after:bg-primary 
              checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] 
              checked:after:transition-[background-color_0.2s,transform_0.2s] 
              checked:after:content-[''] hover:cursor-pointer focus:outline-none 
              focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] 
              focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] 
              focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
              focus:after:absolute focus:after:z-[1] focus:after:block 
              focus:after:h-5 focus:after:w-5 focus:after:rounded-full 
              focus:after:content-[''] checked:focus:border-primary 
              checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] 
              checked:focus:before:scale-100 
              checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] 
              checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                type="checkbox"
                role="switch"
                id="EmailNotif"
                checked={emailNotification}
                onChange={(e) => setEmailNotification(e.target.checked)} />
              <label
                className=""
                htmlFor="EmailNotif"
              >{emailNotification ? <p className="text-xs text-highlight-blue">{t('Enabled')}</p> : <p className="text-xs text-border-grey">{t('Disabled')}</p>}</label>

            </div>
            <div className="flex gap-6 items-end">
              <p className="text-xs">{t('SMSNotifications')}</p>
              <input
                className="mr-1 mt-[0.3rem] h-3.5 w-8 
              appearance-none rounded-[0.4375rem] 
              bg-neutral-300 
              before:pointer-events-none 
              before:absolute before:h-3.5 
              before:w-3.5 before:rounded-full 
              before:bg-transparent 
              before:content-[''] 
              after:absolute after:z-[2] 
              after:-mt-[0.1875rem] after:h-5 
              after:w-5 after:rounded-full after:border-none 
              after:bg-neutral-100 
              after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] 
              after:transition-[background-color_0.2s,transform_0.2s] 
              after:content-[''] 
              checked:bg-highlight-blue
              checked:after:absolute checked:after:z-[2] 
              checked:after:-mt-[3px] checked:after:ml-[1.0625rem] 
              checked:after:h-5 checked:after:w-5 
              checked:after:rounded-full checked:after:border-none 
              checked:after:bg-primary 
              checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] 
              checked:after:transition-[background-color_0.2s,transform_0.2s] 
              checked:after:content-[''] hover:cursor-pointer focus:outline-none 
              focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] 
              focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] 
              focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
              focus:after:absolute focus:after:z-[1] focus:after:block 
              focus:after:h-5 focus:after:w-5 focus:after:rounded-full 
              focus:after:content-[''] checked:focus:border-primary 
              checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] 
              checked:focus:before:scale-100 
              checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] 
              checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                type="checkbox"
                role="switch"
                checked={smsNotification}
                onChange={(e) => setSmsNotification(e.target.checked)}
                id="SmsNotif" />
              <label
                className=""
                htmlFor="SmsNotif"
              >{smsNotification ? <p className="text-xs text-highlight-blue">{t('Enabled')}</p> : <p className="text-xs text-border-grey">{t('Disabled')}</p>}</label>
            </div>

            <div className="flex flex-row gap-3 py-3">
              <button onClick={updatePreference} className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2.5 rounded-lg disabled:bg-highlight-blue/[0.4]" aria-label={t('Save')}>
                {t('Save')}
              </button>
            </div>
          </div>

          <p className="pb-3 text-xs pl-7">{" "}</p>
        </div>

      </div>
    </div>
  )
}

export default NotificationSettings