/* tslint:disable */
/* eslint-disable */
/**
 * OCP Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ChangePassword4 } from '../models';
import { Notification } from '../models';
import { Rating } from '../models';
import { SignedUrl } from '../models';
import { User } from '../models';
import { UserParent } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of all the organizations linked to the specified email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allLinkedProfiles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/all_linked_profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset password when user is already logged in
         * @summary change user's password
         * @param {ChangePassword4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (body: ChangePassword4, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling changePassword.');
            }
            const localVarPath = `/auth/change_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clean all the notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanAllNotifications: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/clean_all_notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the notification
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanNotification: async (notificationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling cleanNotification.');
            }
            const localVarPath = `/auth/clean_notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (notificationId !== undefined) {
                localVarQueryParameter['notificationId'] = notificationId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get profile of requested user
         * @summary Get user's profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/get_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the notification preference
         * @param {boolean} emailPreference 
         * @param {boolean} smsPreference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationPreference: async (emailPreference: boolean, smsPreference: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailPreference' is not null or undefined
            if (emailPreference === null || emailPreference === undefined) {
                throw new RequiredError('emailPreference','Required parameter emailPreference was null or undefined when calling notificationPreference.');
            }
            // verify required parameter 'smsPreference' is not null or undefined
            if (smsPreference === null || smsPreference === undefined) {
                throw new RequiredError('smsPreference','Required parameter smsPreference was null or undefined when calling notificationPreference.');
            }
            const localVarPath = `/auth/notification_preference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (emailPreference !== undefined) {
                localVarQueryParameter['emailPreference'] = emailPreference;
            }

            if (smsPreference !== undefined) {
                localVarQueryParameter['smsPreference'] = smsPreference;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Capture the user rating for system
         * @param {Rating} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rating: async (body: Rating, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling rating.');
            }
            const localVarPath = `/auth/rating`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * profile picture remove from user
         * @summary remove profile picture of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProfilePic: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/remove_profile_pic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Switch profile of requested user
         * @summary Get user's profile
         * @param {UserParent} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchIntermediaryProfile: async (body: UserParent, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling switchIntermediaryProfile.');
            }
            const localVarPath = `/auth/switch_intermediary_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Switch profile of requested user inside organization
         * @summary Get user's profile
         * @param {UserParent} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchOrganisationProfile: async (body: UserParent, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling switchOrganisationProfile.');
            }
            const localVarPath = `/auth/switch_organisation_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the details of User
         * @summary Update user specific details
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (body: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProfile.');
            }
            const localVarPath = `/auth/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * profile upload for user
         * @summary upload profile of the user
         * @param {string} profilePic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilepic: async (profilePic: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profilePic' is not null or undefined
            if (profilePic === null || profilePic === undefined) {
                throw new RequiredError('profilePic','Required parameter profilePic was null or undefined when calling uploadProfilepic.');
            }
            const localVarPath = `/auth/upload_profilepic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


            if (profilePic !== undefined) { 
                localVarFormParams.append('profilePic', profilePic as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of all the organizations linked to the specified email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allLinkedProfiles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).allLinkedProfiles(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Reset password when user is already logged in
         * @summary change user's password
         * @param {ChangePassword4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(body: ChangePassword4, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).changePassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Clean all the notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cleanAllNotifications(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).cleanAllNotifications(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete the notification
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cleanNotification(notificationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).cleanNotification(notificationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get profile of requested user
         * @summary Get user's profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).getProfile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotifications(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notification>>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).listNotifications(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the notification preference
         * @param {boolean} emailPreference 
         * @param {boolean} smsPreference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationPreference(emailPreference: boolean, smsPreference: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).notificationPreference(emailPreference, smsPreference, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Capture the user rating for system
         * @param {Rating} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rating(body: Rating, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rating>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).rating(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * profile picture remove from user
         * @summary remove profile picture of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeProfilePic(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).removeProfilePic(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Switch profile of requested user
         * @summary Get user's profile
         * @param {UserParent} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async switchIntermediaryProfile(body: UserParent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).switchIntermediaryProfile(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Switch profile of requested user inside organization
         * @summary Get user's profile
         * @param {UserParent} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async switchOrganisationProfile(body: UserParent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).switchOrganisationProfile(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the details of User
         * @summary Update user specific details
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(body: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).updateProfile(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * profile upload for user
         * @summary upload profile of the user
         * @param {string} profilePic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProfilepic(profilePic: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrl>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).uploadProfilepic(profilePic, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary List of all the organizations linked to the specified email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allLinkedProfiles(options?: any): AxiosPromise<Array<User>> {
            return AuthApiFp(configuration).allLinkedProfiles(options).then((request) => request(axios, basePath));
        },
        /**
         * Reset password when user is already logged in
         * @summary change user's password
         * @param {ChangePassword4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(body: ChangePassword4, options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).changePassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clean all the notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanAllNotifications(options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).cleanAllNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the notification
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanNotification(notificationId: number, options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).cleanNotification(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get profile of requested user
         * @summary Get user's profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<User> {
            return AuthApiFp(configuration).getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications(options?: any): AxiosPromise<Array<Notification>> {
            return AuthApiFp(configuration).listNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the notification preference
         * @param {boolean} emailPreference 
         * @param {boolean} smsPreference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationPreference(emailPreference: boolean, smsPreference: boolean, options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).notificationPreference(emailPreference, smsPreference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Capture the user rating for system
         * @param {Rating} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rating(body: Rating, options?: any): AxiosPromise<Rating> {
            return AuthApiFp(configuration).rating(body, options).then((request) => request(axios, basePath));
        },
        /**
         * profile picture remove from user
         * @summary remove profile picture of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProfilePic(options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).removeProfilePic(options).then((request) => request(axios, basePath));
        },
        /**
         * Switch profile of requested user
         * @summary Get user's profile
         * @param {UserParent} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchIntermediaryProfile(body: UserParent, options?: any): AxiosPromise<User> {
            return AuthApiFp(configuration).switchIntermediaryProfile(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Switch profile of requested user inside organization
         * @summary Get user's profile
         * @param {UserParent} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchOrganisationProfile(body: UserParent, options?: any): AxiosPromise<User> {
            return AuthApiFp(configuration).switchOrganisationProfile(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the details of User
         * @summary Update user specific details
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(body: User, options?: any): AxiosPromise<User> {
            return AuthApiFp(configuration).updateProfile(body, options).then((request) => request(axios, basePath));
        },
        /**
         * profile upload for user
         * @summary upload profile of the user
         * @param {string} profilePic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilepic(profilePic: string, options?: any): AxiosPromise<SignedUrl> {
            return AuthApiFp(configuration).uploadProfilepic(profilePic, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary List of all the organizations linked to the specified email address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public allLinkedProfiles(options?: any) {
        return AuthApiFp(this.configuration).allLinkedProfiles(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Reset password when user is already logged in
     * @summary change user's password
     * @param {ChangePassword4} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public changePassword(body: ChangePassword4, options?: any) {
        return AuthApiFp(this.configuration).changePassword(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Clean all the notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public cleanAllNotifications(options?: any) {
        return AuthApiFp(this.configuration).cleanAllNotifications(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete the notification
     * @param {number} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public cleanNotification(notificationId: number, options?: any) {
        return AuthApiFp(this.configuration).cleanNotification(notificationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get profile of requested user
     * @summary Get user's profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getProfile(options?: any) {
        return AuthApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public listNotifications(options?: any) {
        return AuthApiFp(this.configuration).listNotifications(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update the notification preference
     * @param {boolean} emailPreference 
     * @param {boolean} smsPreference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public notificationPreference(emailPreference: boolean, smsPreference: boolean, options?: any) {
        return AuthApiFp(this.configuration).notificationPreference(emailPreference, smsPreference, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Capture the user rating for system
     * @param {Rating} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public rating(body: Rating, options?: any) {
        return AuthApiFp(this.configuration).rating(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * profile picture remove from user
     * @summary remove profile picture of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public removeProfilePic(options?: any) {
        return AuthApiFp(this.configuration).removeProfilePic(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Switch profile of requested user
     * @summary Get user's profile
     * @param {UserParent} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public switchIntermediaryProfile(body: UserParent, options?: any) {
        return AuthApiFp(this.configuration).switchIntermediaryProfile(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Switch profile of requested user inside organization
     * @summary Get user's profile
     * @param {UserParent} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public switchOrganisationProfile(body: UserParent, options?: any) {
        return AuthApiFp(this.configuration).switchOrganisationProfile(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the details of User
     * @summary Update user specific details
     * @param {User} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public updateProfile(body: User, options?: any) {
        return AuthApiFp(this.configuration).updateProfile(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * profile upload for user
     * @summary upload profile of the user
     * @param {string} profilePic 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public uploadProfilepic(profilePic: string, options?: any) {
        return AuthApiFp(this.configuration).uploadProfilepic(profilePic, options).then((request) => request(this.axios, this.basePath));
    }
}
