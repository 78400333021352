import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { ApiController } from '../../apiController'
import ComplainList from '../Admin/Complain/ComplainList'
import ComplaintDetails from '../Admin/Complain/ComplaintDetails'
import RolesList from '../Admin/Roles/RolesList'
import AccountSettings from '../Admin/Settings/AccountSettings'
import PrivacySettings from '../Admin/Settings/PrivacySettings'
import Settings from '../Admin/Settings/Settings'
import UserManagementSettings from '../Admin/Settings/UserManagementSettings'
import UserSettings from '../Admin/Settings/UserSettings'
import TrackStatus from '../Admin/Track-Status/TrackStatus'
import UsersList from '../Admin/Users/UsersList'
import ODRContainer from './ODRContainer'
import ArbitrationRequisites from '../Admin/Complain/ArbitrationRequisites'
import ArbitrationSuccess from '../Admin/Complain/ArbitrationSuccess'
import RightSideBar from '../common-module/RightSideBar'
import NotificationSettings from '../Admin/Settings/NotificationSettings'
import OrganisationSettings from '../Admin/Settings/OrganisationSettings'
import Dashboard from '../Admin/Dashboard/Dashboard'
import ODRInfoBankD from '../Admin/Settings/ODRInfoBank'
import OdrApiGeneration from '../Admin/Settings/OdrApiGeneration'
import posthog from 'posthog-js'
function ODRModule() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  useEffect(() => {
    ApiController.getInstance().UserLoggedIn$.subscribe(
      async (userLoggedIn) => {
        if (userLoggedIn && userLoggedIn.userType === 'ODR') {
          setIsAuthenticated(true);
          const user = await ApiController.getInstance().authApi.getProfile();
          ApiController.getInstance().updateUserProfile(user.data);
          posthog.identify(user.data.email, {
            phone: user.data.phoneNumber,
            role: user.data.userType,
            city: user.data.city,
            state: user.data.state,
            pincode: user.data.pinCode,
          })
        } else {
          setIsAuthenticated(false);
          navigate("/odr/login");
        }
      }
    );
  }, []);
  return (
    <React.Fragment>
      <div role="heading" aria-level={1} className="flex sm:flex-row flex-col pb-0 mx-auto px-2 w-full h-full  justify-between">
        <div>{isAuthenticated && <ODRContainer />}</div>
        <div className="w-full min-h-full" role="main">
          <Routes>
          <Route
              path="dashboard"
              element={<Dashboard />}
              index={true}
            />
            <Route path="disputes">
              <Route index={true} element={<ComplainList />} />
              <Route
                index={false}
                path="disputeDetails/:complainUUID"
                element={<ComplaintDetails />} />
                <Route
                  index={false}
                  path="disputeDetails/:complainUUID/initiate-arbitration"
                  element={<ArbitrationRequisites />} />
                  <Route
                  index={false}
                  path="disputeDetails/:complainUUID/initiate-arbitration/initiated-arbitration"
                  element={<ArbitrationSuccess />} />
            </Route>
            <Route
              path="track-status"
              element={<TrackStatus />}
              index={true}
            />
            <Route
              path="roles"
              element={<RolesList />}
              index={true}
            />
            <Route
              path="users"
              element={<UsersList />}
              index={true}
            />
            <Route path="settings">
              <Route index={true} element={<Settings />} />
              <Route
                index={false}
                path="account-settings"
                element={<AccountSettings />} />
              <Route
                index={false}
                path="organisation-settings/:orgId"
                element={<OrganisationSettings />} />
              <Route
                index={false}
                path="security"
                element={<PrivacySettings />} />
              <Route
                index={false}
                path="notification-settings"
                element={<NotificationSettings />} />
              <Route
                index={false}
                path="ODR-Infobank"
                element={<ODRInfoBankD />} />
              <Route
                index={false}
                path="ODR-Api"
                element={<OdrApiGeneration />} />
              <Route
                index={false}
                path="user-management"
                element={<UserManagementSettings />} />
              <Route
                index={false}
                path="user-management/user-settings"
                element={<UserSettings />} />
              <Route
                index={false}
                path="user-management/user-settings/:userId"
                element={<UserSettings />} />
            </Route>
            <Route
              path="*"
              element={<Navigate replace={true} to="disputes" />}
            />
            <Route
              path="/"
              element={<Navigate replace={false} to={"disputes"} />}
            />
          </Routes>
        </div>
        <RightSideBar/>
      </div>
    </React.Fragment>
  )
}
export default ODRModule

