import { useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * RejectComplaintModal component displays a modal that allows users to provide a reason
 * for returning a dispute. The modal includes a text area for entering a status update 
 * and a "Return Dispute" button which triggers the handleStatusChange callback with the 
 * status 'DISPUTE_RETURNED' and the provided status update when clicked.
 * The modal can be closed without taking any action by clicking the cancel button.
 *
 * Props:
 * - setRejectComplaint: Function to control the visibility of the modal.
 * - user: The user object, although not directly used within the component.
 * - handleStatusChange: Callback function executed when the "Return Dispute" button is clicked.
 */
const RejectComplaintModal = ({ setRejectComplaint, user, handleStatusChange }: any) => {

  const [statusUpdate, setStatusUpdate] = useState<string>("")
  const { t } = useTranslation();

  return (
    <div
      className="fixed z-10 inset-0 flex items-center justify-center "
      id="modal"
    >
      <div className="px-8 sm:px-0 sm:w-1/3">
        <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => setRejectComplaint(false)}
          >
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <div
            className="inline-block align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <form >
              <div className="flex flex-col gap-1 px-8 pb-5">
                <h1 className='font-semibold sm:text-2xl text-lg py-3'>{t('ProvideReasoningReturning')}</h1>
                <textarea value={statusUpdate} onChange={(e) => setStatusUpdate(e.target?.value)} placeholder={t('AddReasonReturningDispute')} className='text-xs p-2 border rounded-xl w-full sm:w-3/4' rows={5} />
                <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>
                  <button className="hover:bg-highlight-red/[0.4] bg-highlight-red justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12" aria-label={t('ReturnDispute')}
                    onClick={(e: any) => {
                      handleStatusChange(e, 'DISPUTE_RETURNED', '', statusUpdate)
                    }
                    }
                  >
                    <p>{t('ReturnDispute')}</p>
                  </button>

                  <button aria-label={t('cancel')} onClick={() => setRejectComplaint(false)} className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                    {t('cancel')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  );
};


export default RejectComplaintModal;