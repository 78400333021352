import { toast } from "react-hot-toast";
import { ApiController } from "../apiController";
import { loader, notifyError, notifySuccess } from "../helpers/helper";
import { MergeOrganisation, OrganisationParent } from "../swagger/models";
import {
  ListOrgParams,
  MarketParticipantApproval,
  MarketParticipantRejection,
  Organisation,
} from "../swagger/models";

export const OrganisationStore = (set: any, get: any, store: any) => ({
  organisations: [],
  orgDetail: {},
  newOrgDetails: {},
  rejectedMPApplication: {},
  approvedMPApplication: {},
  /**
   *
   * @param orgId
   */
  getOrganisation: async (orgId: number) => {
    const toastId = loader("Loading Organisation...");
    try {
      const orgData =
        await ApiController.getInstance().organisationApi.getOrganisation(
          orgId
        );
      set({
        orgDetail: orgData.data,
      });
      toast.dismiss(toastId);
    } catch (error: any) {
      console.error(error);
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   */
  listOrganisations: async (listOrgParams: ListOrgParams) => {
    const toastId = loader("Loading Organisations...");
    try {
      const orgData =
        await ApiController.getInstance().organisationApi.listOrganisations(
          listOrgParams
        );
      set({
        organisations: orgData.data,
      });
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  addOrgProfile: async (orgObject: Organisation) => {
    const toastId = loader("Adding Organisation...");
    try {
      const orgData =
        await ApiController.getInstance().organisationApi.createOrganisation(
          orgObject
        );
      set({
        newOrgDetails: orgData.data,
      });
      toast.dismiss(toastId);
      notifySuccess("Organisation added successfully!");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  updateOrgProfile: async (orgObject: Organisation) => {
    const toastId = loader("Updating Organisation...");
    try {
      const orgData =
        await ApiController.getInstance().organisationApi.updateOrganisation(
          orgObject
        );
      set({
        orgDetail: orgData.data,
      });
      toast.dismiss(toastId);
      notifySuccess("Organisation updated successfully!");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   * @param orgObject
   */
  approveMarketParticipant: async (orgObject: MarketParticipantApproval) => {
    const toastId = loader("Approving Market Participant application...");
    try {
      const orgData =
        await ApiController.getInstance().organisationApi.approveMarketParticipants(
          orgObject
        );
      set({
        approvedMPApplication: orgObject,
      });
      toast.dismiss(toastId);
      notifySuccess("Market Participant application approved successfully!");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   * @param orgObject
   */
  rejectMarketParticipant: async (orgObject: MarketParticipantRejection) => {
    const toastId = loader("Rejecting Market Participant application...");
    try {
      const orgData =
        await ApiController.getInstance().organisationApi.rejectMarketParticipants(
          orgObject
        );
      set({
        rejectedMPApplication: orgObject,
      });
      toast.dismiss(toastId);
      notifySuccess("Market Participant application rejected successfully!");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * Functions to call the Merge account API
   * @param orgObject 
   */
  mergeDuplicateAccounts: async (orgObject: MergeOrganisation) => {
    const toastId = loader("Merging accounts...");
    try {
      const orgData =
        await ApiController.getInstance().organisationApi.mergeDuplicateOrganisation(
          orgObject
        );
      toast.dismiss(toastId)
      notifySuccess('Organisation Merged successfully!. Please refresh your settings.')
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * 
   * @param organisationParent 
   */
  odrMIIMapping: async (organisationParent: OrganisationParent) => {
    const toastId = loader("Merging accounts...");
    try {
      const orgData =
        await ApiController.getInstance().organisationApi.odrMiiLinking(
          organisationParent
        );
      toast.dismiss(toastId)
      notifySuccess('ODR linked successfully!. Please refresh your settings.')
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * Function to send the invitation to the existing organisation
   * @param orgId 
   */
  resendOrganisationInvitation: async (orgId: number) => {
    const toastId = loader("Resending Invitation to Organisation...");
    try {
      await ApiController.getInstance().organisationApi.resendInvitationOrganisation(
        orgId
      );
      toast.dismiss(toastId);
      notifySuccess("Invitation sent to organisation successfully!");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * Function to send the invitation to the market participant
   * @param orgId
   */
  resenMPInvitation: async (orgId: number) => {
    const toastId = loader("Resending Invitation to Market Participant...");
    try {
      await ApiController.getInstance().organisationApi.resendMpReRegistration(
        orgId
      );
      toast.dismiss(toastId);
      notifySuccess("Invitation sent to market participant successfully!");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
});
