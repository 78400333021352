import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { changePasswordValidationSchema } from '../../helpers/validation'
import { useCombinedStore } from '../../store'
import { ChangePassword } from '../../swagger/models'
import TandCModal from './T&CModal'
import { encryptString } from '../common-module/helper'
import { useTranslation } from 'react-i18next';

/**
 * SetupPassword component facilitates the setup of a new password
 * by allowing the user to define and confirm a new password. It validates the input using
 * a Yup schema and encrypts the password before submission. The component also handles 
 * Terms and Conditions agreement via a modal. Upon successful password setup, the user is 
 * redirected to the login page.
 *
 * @returns A JSX element that renders the setup password form.
 */
function SetupPassword() {
    const { t } = useTranslation();
    const [changePasswordObject, setChangePasswordObject] = useState<ChangePassword>({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        token: ''
    })
    const [encryptedPassword, setEncryptedPassword] = useState<ChangePassword>({})
    const [tcChecked, setTCChecked] = useState<boolean>(false)
    const [tandCModal, setTandCModal] = useState(false)

    const params = useParams()
    const token: string = params.token!
    const navigate = useNavigate()
    const setupNewAccountPassword = useCombinedStore((state: any) => state.setupNewAccountPassword)
    const passwordUpdated = useCombinedStore((state: any) => state.passwordUpdated)
    const newPasswordSet = useCombinedStore((state: any) => state.newPasswordSet)

    const formOptions = {
        resolver: yupResolver(changePasswordValidationSchema),
    }

    const { register, handleSubmit, formState } = useForm(formOptions)
    const { errors } = formState

    const onInputChange = (e: any) => {
        const { name, value } = e.target
        setChangePasswordObject({
            ...changePasswordObject,
            [name]: value,
        })
        setEncryptedPassword({
            ...encryptedPassword,
            [name]: encryptString(value),
          })
    }

    async function onSubmit(e: any) {
        setupNewAccountPassword(encryptedPassword)
        return false
    }

    useEffect(() => {
        setChangePasswordObject({
            ...changePasswordObject,
            token: token
        })
        setEncryptedPassword({
            ...encryptedPassword,
            token: token
          })
    }, [token])

    useEffect(() => {
        if (passwordUpdated) {
            navigate(`/login`)
        }
    }, [passwordUpdated])

    return (
        <div className="flex flex-row justify-between pt-7 px-10 lg:pl-32 pb-7 w-full h-screen">
            <div className="flex flex-col h-full w-full lg:w-1/2 pt-10 sm:pt-20 justify-between">
                <div>
                    <div className='flex flex-row justify-between'>
                        <NavLink to="/login">
                        <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '175px', height: '40px' }} />
                        </NavLink>
                        
                    </div>
                    {!newPasswordSet ? (
                        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-2 mt-5'>
                            <label htmlFor="password" className="text-xs">{t('EnterPassword')}</label>
                            <input aria-label={t('EnterPassword')} {...register('newPassword')} type='password' id='newPassword' onChange={onInputChange} className={`rounded-lg border p-2 border-solid ${errors.newPassword?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                            <span className="text-xs text-highlight-red">{errors.newPassword?.message}</span>
                            <label htmlFor="password" className="text-xs">{t('ConfirmPassword')}</label>
                            <input aria-label={t('ConfirmPassword')} {...register('confirmPassword')} type='password' id='confirmPassword' onChange={onInputChange} className={`rounded-lg border p-2 border-solid ${errors.confirmPassword?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                            <span className="text-xs text-highlight-red">{errors.confirmPassword?.message}</span>
                            <div className="flex flex-row gap-2 pt-4">
                                <input onChange={(e: any) => {setTandCModal(e.target.checked)
                                e.target.checked === false && setTCChecked(false)}} aria-label={t('AgreeSMARTTermsConditions')} type='checkbox' id='logggedin' className='bg-border-grey' />
                                <label className="text-xs">{t('AgreeSMARTTermsConditions')}</label>
                            </div>
                            <button disabled={!tcChecked} aria-label='Proceed' type='submit' className='bg-highlight-blue disabled:bg-highlight-blue/40 hover:bg-highlight-blue/[0.8] mt-4 text-white text-sm py-4 px-28 rounded-lg'>
                                {t('Proceed')}
                            </button>
                            {tandCModal && <TandCModal setTandCModal={setTandCModal} setTCChecked={setTCChecked} submission={(e: any) => setTCChecked(true)} />}
                        </form>
                    ) : (
                        <div className="pb-8">
                            <div className="rounded-xl bg-light-blue flex flex-col gap-3 py-4 px-4 text-xs">
                                <div className="flex flex-row w-full h-fit gap-2 items-center">
                                    <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/Green-Tick-Vector-PNG-Images.png" width={"30px"} />
                                    <p className="font-bold text-highlight-blue">{t('AccountRegistrationSuccessful')}!</p>
                                </div>

                                <p className="text-highlight-blue">{t('LoginPlatformCredentials')}</p>
                                <p className="text-highlight-blue">{t('Logintotheplatform')} <Link to="/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('here')}</Link>.</p>
                                <p className="text-highlight-blue">{t('InCaseofAnyQueries')}</p>

                            </div>
                        </div>
                    )}

                </div>
            </div>
            <div className='hidden lg:flex w-1/2 justify-end'>
                <div className='hidden lg:block h-full pl-8 mb-8 py-8 top-0 right-0 rounded-2xl'>
                    <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/HeroIMAGESMARTODR.png"
                        className='w-fit overflow-hidden h-full rounded-xl' alt="hero" />
                </div>
            </div>
        </div>
    )
}

export default SetupPassword