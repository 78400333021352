
import { Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/SMARTODR_LOGO.png"
// @ts-ignore
import html2pdf from 'html2pdf.js';

function TermsAndCondition() {

  const reportTemplateRef = useRef<any>(null);

  const generatePdf = () => {
    const element = document.getElementById('pdf-element');

    const opt = {
      margin: 10,
      filename: 'SMART ODR Terms and Conditions.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf()
    .from(element)
    .set(opt)
    .save();
  }


  return (
    <Fragment >
      <div id="pdf-content" className="flex flex-row w-full justify-end pt-5 pr-12">
        <button type="button" className="text-xs p-3 bg-highlight-blue text-white rounded-lg hover:bg-highlight-blue/40" onClick={generatePdf}>Download PDF</button>
      </div>
      <div className="flex flex-col gap-2 p-12 text-sm" id="pdf-element" ref={reportTemplateRef}>
        <div className="flex flex-row justify-end items-center text-xs">
          <NavLink to="/login"><img src={logo} alt="SMARTODR Logo" style={{ width: '175px', height: 'fit' }} /></NavLink>
        </div>
        <p className="text-lg text-center underline font-bold">
          TERMS AND CONDITIONS OF USAGE OF ONLINE DISPUTE RESOLUTION PORTAL<br />
          (ODR PORTAL)
        </p>
        <p className="text-sm  text-back-gray text-center font-serif">
          Version 1.0.1
        </p>
        <p className="text-sm text-back-gray text-center font-serif">
          Last updated on 4th August, 2023
        </p>
        <br />
        <p className='font-serif'>
        This document comprising of Terms and Conditions (<b className='font-serif'>Terms</b>) 
        is a legally binding Agreement (“Agreement”) between the Users (<i className='font-serif'>as defined below</i>)  (hereinafter <b className="font-serif">“User”</b> or <b className="font-serif">you</b> or <b className="font-serif">your</b>) and  “User(s)”  access or availing of ODR Facility (<i className='font-serif'>as defined below</i>) being
          provided jointly by
          <b className='font-serif'> National Stock Exchange of India Limited, BSE Limited, National
            Securities Depository Limited, Central Depository Services (India)
            Limited and Metropolitan Stock Exchange of India Limited </b>(hereinafter <b className='font-serif'>Host </b>or <b className='font-serif'>we </b>or <b className='font-serif'>us</b>), through its platform
          accessible at
          <a className='' href='https://smartodr.in/login'>https://smartodr.in/login</a> (<b className='font-serif'>Platform</b>). The
          Host has its principal place of business at Mumbai.
        </p>
        <p className='font-serif' style={{ paddingLeft: '13pt', textIndent: '0pt', textAlign: 'justify' }}>
        You hereby agree and acknowledge that by clicking on I Agree / entering this Platform 
        You are providing an unequivocal consent to access, store, process your Data 
        (including Personal Data and Sensitive Personal Data or Information as the case maybe) 
        as provided by you and the same shall not constitute breach of any applicable Data Protection 
        Laws / Regulations as maybe applicable from time to time.
        </p>
        <br />
        <h1 className='text-black font-serif font-bold' style={{ paddingLeft: '13pt', textIndent: '0pt', textAlign: 'justify' }}>
          Part I- Introduction and Definitions
        </h1>
        <br />
        <ol className='list-outside px-10' style={{ listStyleType: 'lower-roman' }}>
          <li className='font-serif pl-4'>
            Please read the Terms carefully before using and accessing the
            Platform. Before accessing or using the Platform or availing the ODR
            Facility, you:
            <br />
            <ol className='list-outside pl-10' style={{ listStyleType: 'lower-alpha' }}>
              <li className='font-serif pl-4'>
                acknowledge that you have read, understood and accepted these
                Terms and other such documents as may be required in relation
                to the use of the ODR Facility by clicking on the check-box
                option/providing your e-sign/digital signature.
              </li>
              <li className='font-serif pl-4'>
              warrant that you meet the eligibility criteria as stipulated under the SEBI ODR Circular, and;
              </li>
              <li className='font-serif pl-4'>
              represent that you are legally competent to enter into a 
              binding agreement in accordance with the Indian Contracts Act, 1872.
                <br />
              </li>
            </ol>
          </li>
          <li className='font-serif pl-4'>
          You acknowledge and agree that We may modify the Terms at any time by posting 
          the changes on the Platform and you agree that it shall be your responsibility
           to ascertain the changes to the Terms, by viewing the revised Terms, 
           as and when amended.
            <br />
          </li>

          <div className="html2pdf__page-break"></div> 
          {/* ///////////////////// ///////////////////// ///////////////////// */}

          <li className='font-serif pl-4'>
            In order to avail the ODR Facility offered on the Platform, you will
            be required to register on the Platform by providing details about
            yourself, including your name, contact details, registration category,
            registration number and such other details as may be required and
            create an account (<b className='font-serif'>Account</b>), by setting up your username and
            password (MPIN). You shall not have more than one active account on
            the Platform. Additionally, you are prohibited from selling, trading,
            or otherwise transferring your Account to another party or
            impersonating any other person for the purposing of creating an
            Account with the Platform.
            <br />
          </li>

          <li className='font-serif pl-4'>
            Definitions-
            <p className='font-serif' style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <ol className='list-outside pl-10' style={{ listStyleType: 'lower-alpha' }}>
              <li className='font-serif pl-4'>

                <b className='font-serif'>User </b>means and refers to any Market Participant/Investor/Client 
                (institutional clients or corporate clients) who will avail the ODR Facility
                (<i className='font-serif'>as defined below</i>) or any Person that visits, uses or accesses the Platform; 
              
              </li>

       

              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>ODR Facility </b>
                shall mean and refer to all the dispute resolution facility
                provided by the Host and other facilitating Market
                Infrastructure Institutions (MIIs) and empanelled dispute resolution institutions in relation to
                the resolution of disputes through conciliation and/or
                arbitration pursuant to SEBI ODR Circular, as amended from time
                to time;
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>User Information </b>
                shall mean any information provided by the User including but
                not limited to name, Permanent Account Number (PAN), mobile
                number, email address, for the creation of an Account or
                otherwise for the purpose of availing/accessing the ODR
                Facility as maybe required under SEBI Circular; 
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>Applicable Law </b>
                means, with respect to any Person, any applicable laws,
                statutes, ordinances, regulations, guidelines, policies and
                other pronouncements having the effect of law of any
                jurisdiction by state, municipality, court, tribunal, agency,
                government, ministry, department, commission, arbitrator, board,
                bureau, or instrumentality thereof, or of any other Regulatory
                Authority as currently interpreted and administered that is
                binding upon or applicable to such Person or that has been
                agreed to by the Person under applicable Rules, Byelaws or
                Regulations including that of SPDI Rules.
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>Award </b>
                means award by the sole arbitral or the arbitral tribunal in
                terms of Arbitration and Conciliation Act, 1996;
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>Companies Act </b>
                means the Companies Act, 2013, including any amendments and any
                statutory re-enactment or replacement thereof and any rules,
                regulations, notifications and clarifications made
                thereunder;
                <br />
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>Complainant </b>
                means the User who requests availing of ODR Facility on the Platform 
                for the purposes lodging / registering a complaint or a dispute;
                <br />
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>INR </b>
                means Indian Rupee, the lawful currency of the Republic of
                India;
                <br />
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>Intellectual Property </b>
                means patents, trademarks, trade names, rights in designs,
                copyrights and database rights (whether or not any of these is
                registered and including applications for registration of any
                such thing) and all rights or forms of protection of a similar
                nature or having equivalent or similar effect to any of these
                which may subsist anywhere in the world;
                <br />
              </li>

              <div className="html2pdf__page-break"></div> 
          {/* ///////////////////// ///////////////////// ///////////////////// */}

              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>Market Participants </b>
                means intermediaries registered with stock exchanges,  depositories; 
                listed companies, mutual funds and Regulated Entities/ Specified 
                Intermediaries or any other intermediaries as maybe notified by SEBI from time to time.
                <br />
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>Material Adverse Effect</b>shall mean,
                <br />
                <ol className='list-outside pl-10' style={{ listStyleType: 'lower-alpha' }}>
                  <li className='font-serif pl-4 pt-2'>
                    cancellation by SEBI and/or the MIIs, the certificate of
                    registration held by the Market Participants; and/or
                    <br />
                  </li>
                  <li className='font-serif pl-4 pt-2'>
                    any impairment of any Party’s ability to participate on the
                    ODR Platform, where such impairment is: (x) solely on account
                    of any change in Applicable Law, and/ or (y) pursuant to any
                    order or written notice from a Regulatory Authority.
                  </li>
                </ol>
                <br />
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>MIIs </b>
                refers to Market Infrastructure Institutions and includes stock exchanges and depositories;
                <br />
              </li>

      
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>ODR Institutions </b>
                means refer to such institutions who have been empaneled by the
                respective MIIs to facilitate and provide conciliation and arbitration
                facility;
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>ODR Platform </b>
                means the platform provided by the ODR Institutions for
                resolution of grievances by way of conciliation and
                arbitration
                <br />
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>Person </b>
                means any individual, corporation, company, limited liability
                company, partnership (limited or general), joint venture,
                association, trust or other entity permitted by the MIIs;
                <br />
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>Proceedings </b>
                means any proceeding, suit or action arising out of or in
                connection with this Agreement, whether contractual or
                non-contractual;
                <br />
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>Regulatory Authority </b>
                means the RBI, SEBI, and any other governmental or regulatory
                body, statutory authority, government department, agency,
                commission, board, tribunal, arbitral tribunal or court or other
                law, rule or regulation-making entity (whether in India or
                otherwise) which is responsible for the authorisation,
                regulation, licensing and/or supervision of the  Market Participant;
                <br />
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>SEBI </b>
                means the Securities and Exchange Board of India;
                <br />
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>SEBI ODR Circular </b>
                means the circular dated July 31, 2023, issued by SEBI, in
                relation to leveraging use of Online Dispute Resolution
                institutions for redressal of investor grievances;
                <br />
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>Specified Intermediaries/Regulated Entities </b>
                means the entities covered in Schedule A and B of the SEBI ODR
                Circular, as amended from time to time;
                <br />
              </li>
              <li className='font-serif pl-4 pt-2'>
                <b className='font-serif'>SPDI Rules </b>
                shall mean the Information Technology (Reasonable Security
                Practices and Procedures and Sensitive Personal Data or
                Information) Rules, 2011.
              </li>
            </ol>
          </li>
        </ol>
        <div className="html2pdf__page-break"></div> 
          {/* ///////////////////// ///////////////////// ///////////////////// */}

        <br />
        <h1 className='text-black font-serif font-bold text-sm' style={{ paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>
          Part II- Access to the Platform
        </h1>
        <br />
        <ol className='list-outside pl-10' style={{ listStyleType: 'lower-roman' }}>
          <li className='font-serif pl-4 pt-2'>
            To avail ODR Facility, you will be required to register on the
            Platform and create an Account with us, after completing procedures as
            may be prescribed by the Host/us. Before you avail the ODR Facility
            through your Account, you represent that you have all rights required
            to provide the information that you have provided on the Platform,
            including the User Information and represent that they are true and
            accurate.
            <br />
          </li>
          <li className='font-serif pl-4 pt-2'>
            You will be solely responsible for your acts on the Platform including those 
            conducted under your username or using your User Information. Unless 
            otherwise permitted under these Terms, you are prohibited from sharing your 
            username or password/MPIN or other login credentials to any other Person.
            <br />
          </li>
          <li className='font-serif pl-4 pt-2'>
            All User Information will be used and processed in accordance with
            these Terms.
            <br />
          </li>
          

          <li className='font-serif pl-4 pt-2'>
          You understand that the Host shall adhere to reasonable security measures
           deemed to be appropriate by the Host for the provision of the ODR Facility 
           and the Platform, including, without limitation, using reasonable measures 
           to secure your information (including User Information) from unauthorized 
           access and modifications. The User will not take any action that will cause a 
           breach of the security measures used by the Host. 
            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="v.">
            Your access and use of the Platform may possibly be disrupted due to
            technical or operational difficulties, without prior notice of
            downtime.
            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="vi.">
            You hereby provide your consent to be contacted by the Host, MIIs and
            ODR Institutions through calls, SMS, e-mails, push notifications or
            any other form of electronic communication in connection with your
            access to the Platform and ODR Facility.
            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="vii.">
          You shall only use the platform and services 
          solely for legitimate grievances pursuant to the SEBI ODR circular. 
          </li>
        </ol>
        <br />
        <h1 className='text-black font-serif font-bold text-sm' style={{ paddingTop: '10pt', paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>
          Part III- Account, Password, and Security
        </h1>
        <br />
        <ol className='list-outside pl-10' style={{ listStyleType: 'lower-roman' }}>
          <li className='font-serif pl-4 pt-2' data-list-text="i.">
            You shall ensure and confirm that the User Information provided by you
            is complete, accurate and up to date. If there is any change in the
            User Information, you shall promptly update your User Information on
            the Platform. If you provide any information that is untrue,
            inaccurate, not current, incomplete, misleading, fraudulent, (or
            becomes untrue, inaccurate, not current, incomplete, misleading or
            fraudulent), or if the Host has reasonable grounds to suspect that
            such information belongs to any of the foregoing categories, it will
            be deemed to be a breach of these Terms and the Host has the right to
            suspend or terminate your Account and refuse any and all current or
            future use of the Platform (or any portion thereof) at its discretion,
            in addition to any right that the Host may have against you at law or
            in equity.
            <br />
          </li>

          <div className="html2pdf__page-break"></div>
          {/* ///////////////////// ///////////////////// ///////////////////// */}

          <li className='font-serif pl-4 pt-2' data-list-text="ii.">
            You will be responsible for maintaining the confidentiality of the
            information received on the Platform i.e., information of the
            investor/ clients and are fully responsible for all activities that
            occur in your Account. You agree to (a) immediately notify the Host of
            any unauthorized use of your User Information or any other breach of
            security, loss of device and (b) ensure that you exit from your
            Account at the end of each session. The Host cannot and will not be
            liable for any loss or damage arising from your failure to comply with
            this provision.
            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="iii.">
            Use of another User’s Account information for availing the ODR
            Facility is expressly prohibited.
          </li>
        </ol>
        <br />
        <h1 className='text-black font-serif font-bold text-sm' style={{ paddingLeft: '16pt', textIndent: '0pt', textAlign: 'left' }}>
          Part IV - Scope and Conditions of ODR Facility
        </h1>
        <br />
        <ol className='list-outside pl-10' style={{ listStyleType: 'lower-roman' }}>
          <li className='font-serif pl-4 pt-2' data-list-text="i.">
            The Hosts hereby permit the User, with effect from August 16, 2023, to
            participate on the ODR Platform for resolution of disputes referred
            through the ODR Platform (“<b className='font-serif'>Dispute</b>”).
            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="ii.">
            By accepting these Terms, you expressly submit to the grievance
            redressal procedure (including conciliation and arbitration)
            prescribed by the Hosts, in accordance with the SEBI ODR Circular,
            Arbitration and Conciliation Act, 1996 and Rules, Byelaws and
            Regulations of MIIs.
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="iii.">
            Any reference to a MII in these Terms of Use, unless the context
            indicates otherwise, would be read as a reference to any each MII,
            either solely or jointly with any other MII.
            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="iv.">
            The MIIs may require the Market Participant to maintain accounts with
            any or each of the MIIs that may be utilized to provide (a) amount
            determined through conciliation, (b) interim relief amount granted by
            the Arbitral Tribunal, (c) amount of the Award, (d) fees of the
            conciliator and/or Arbitral Tribunal and any other amount as may be
            directed by SEBI, from time to time<b className='font-serif'>.</b>
            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="v.">
            This website (<b className='font-serif'>website</b>), and the technological or online portal
            (<b className='font-serif'>portal</b>), and access to any online dispute resolution services
            (<b className='font-serif'>ODR services</b>) made available on the portal by the various
            service providers (<b className='font-serif'>ODR institutions) </b>to you (<b className='font-serif'>you </b>or
            <b className='font-serif'>user</b>) are subject to these terms of use (<b className='font-serif'>Terms</b>), published
            on the website (available at: [<u> </u>www.smartodr .in<u> &nbsp; </u>]).

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="vi.">
            A dispute is considered to be an ‘<b className='font-serif'>active dispute</b>’ after an
            invitation by a party to a dispute to use ODR services through SMART
            ODR Portal in relation to a dispute is accepted by all the other
            parties to such dispute and SMART ODR Portal agrees to allow all
            parties to such dispute access to the ODR services through its portal
            for resolution of such dispute. Any violation of these Terms by the user
            or any other party to an active dispute may lead to SMART ODR Portal
            suspending, aborting or limiting the ODR services to the parties to
            such active dispute. The user is solely and completely responsible for
            understanding and complying with any and all laws, rules and
            regulations of India that may be applicable to in connection with the
            user’s use of the website, portal and the ODR services.
          </li>

          <div className="html2pdf__page-break"></div>
          {/* ///////////////////// ///////////////////// ///////////////////// */}

          <li className='font-serif pl-4 pt-2' data-list-text="vii.">
            Access to the ODR services facilitated by SMART ODR Portal and any
            award made by any dispute resolution professional (drp) in relation to
            an active dispute do not and do not purport to constitute the
            expression of any legal opinion or view on any provision of any law by
            SMART ODR Portal. Any award, order, settlement proposal, direction, or
            any other communication (written or otherwise) of any nature provided
            by SMART ODR Portal or a drp to the user (SMART ODR Portal
            communication) is intended to be relied upon only by the user and any
            parties to the active dispute in relation to which such SMART ODR
            Portal communication is issued and only in connection with the matters
            to which the SMART ODR Portal communication relates and should not be
            used, quoted or referred to in any document or for any purpose other
            than as explicitly mentioned in such SMART ODR Portal communication,
            unless the same is required for the purpose of dealing with an appeal
            application made with respect to the award.
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="viii.">
            SMART ODR Portal does not have any control over the legality or
            enforceability of the <i>inter- se </i>agreement between the parties
            to an active dispute that form the basis for its reference to SMART
            ODR Portal, and the user agrees and undertakes that they have
            satisfied themselves about the legality or enforceability thereof.

          </li>
        </ol>
        <br />
        <h1 className='text-black font-serif font-bold text-sm' style={{ paddingLeft: '16pt', textIndent: '0pt', textAlign: 'left' }}>
          Part V - User and Market Participants undertakings
        </h1>
        <br />
        <ol className='list-outside pl-10' style={{ listStyleType: 'lower-roman' }}>
          <li className='font-serif pl-4 pt-2' data-list-text="i.">
            The user shall provide complete cooperation to the conciliator and/or
            arbitrator and provide any information the latter requires to resolve
            the complaint in effective matter and within stipulated timelines.

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="ii.">
            The Market Participant shall take reasonable efforts to undertake
            promotion of investor education and investor awareness programs
            through seminars, workshops, publications, training programs etc.
            Aimed at creating awareness about the revised ODR mechanism in terms
            of the SEBI ODR circular.

            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="iii.">
            The Market Participant shall comply with code of conduct stipulated
            in the SEBI ODR circular.

            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="iv.">
          The User hereby acknowledges and agrees that it shall bear any 
          fees or charges as may be prescribed in the SEBI ODR Circular. 
          The User expressly agrees that unless provided in the order by the 
          Conciliator / Arbitrator it shall not institute any claims against Us for 
          accessing this ODR Portal / Platform as the case maybe.

            <br />

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="iv.">
          The User hereby understands, agrees and undertakes that it shall at all 
          times adhere to the timelines provided in the SEBI ODR Circular or any 
          other timelines as may be provided by SEBI from time to time.

            <br />

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="iv.">
          The disputes filed on the ODR Portal should not be part of any ongoing litigation, 
          arbitration or any other legal proceedings initiated. Matters under alternate 
          legal proceedings do not fall within the ambit of the ODR Portal.

            <br />

          </li>
        </ol>
        <br />
        <h1 className='text-black font-serif font-bold text-sm' style={{ paddingLeft: '16pt', textIndent: '0pt', textAlign: 'left' }}>
          Part VI - Market Participant's Undertakings
        </h1>
        <br />
        <ol className='list-outside pl-10' style={{ listStyleType: 'lower-roman' }}>
          <li className='font-serif pl-4 pt-2' data-list-text="i.">
            The Market Participant shall provide complete cooperation to the
            conciliator and/or arbitrator and provide any information the latter
            requires to resolve the complaint in effective matter and within
            stipulated timelines.

            <br />
          </li>

          <div className="html2pdf__page-break"></div>
          {/* ///////////////////// ///////////////////// ///////////////////// */}
          <li className='font-serif pl-4 pt-2' data-list-text="vi.">
            The Market Participant shall take reasonable efforts to undertake
            promotion of investor education and investor awareness programmes
            through seminars, workshops, publications, training programmes etc.
            aimed at creating awareness about the revised ODR mechanism in terms
            of the SEBI ODR Circular.

            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="vii.">
            The Market Participant shall comply with Code of Conduct stipulated in
            the SEBI ODR Circular, if any.

            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="viii.">
            The details to be provided i.e., your name, email address, physical
            address and contact numbers should be accurate so that the Host may be
            in a position to verify details or check the authenticity of the
            complaints.

          </li>
        </ol>
        <br />
        <h1 className='text-black font-serif font-bold text-sm' style={{ paddingTop: '10pt', paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>
          Part VII - Intellectual Property:
        </h1>
        <br />
        <ol className='list-outside pl-10' style={{ listStyleType: 'lower-roman' }}>
          <li className='font-serif pl-4 pt-2' data-list-text="i.">
            The Host is granting a limited right to you to access and use the
            Platform, including the content and material therein, in accordance
            with the Terms, on a non-exclusive, worldwide, revocable,
            royalty-free, non-transferable and non-sublicensable basis. There are
            no other rights being granted to you on the Platform or any of its
            contents and functionalities.

            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="ii.">
            You have no right to use any search mechanisms other than that
            provided on the Platform, and you will not use any web-crawler, robot
            or any data harvesting tools to access, monitor, harvest, copy or
            scrape data in any manner from the Platform.

          </li>
        </ol>
        <br />
        <br />
        <h1 className='text-black font-serif font-bold text-sm' style={{ paddingTop: '4pt', paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>
          Part VIII - Eligibility, restrictions and undertakings
        </h1>
        <br />
        <ol className='list-outside pl-10' style={{ listStyleType: 'lower-roman' }}>
          <li className='font-serif pl-4 pt-2' data-list-text="i.">
            The user represents that it is either a body corporate duly
            incorporated under the laws of India or a citizen of India with
            capacity to contract under the laws of India.

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="ii.">
            The user undertakes that the user has been allowed access to ODR
            services on the portal subject to the user undertaking not to do
            any of the following under any circumstances:

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="iii.">
            Use or attempt to use the systems of SMART ODR Portal by way of
            any automated means, or take any action that, in the view of SMART
            ODR Portal, causes or potentially may cause an unreasonable or
            disproportionate load on the servers or network infrastructure of
            SMART ODR Portal; to clarify, the foregoing will not be applicable
            to any action taken by a user with the prior consent of SMART ODR
            Portal;


          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="iv.">
            Bypass any computer program or system used by SMART ODR Portal
            that intended to distinguish human input from machine input,
            prevent spam or automated extraction of data, robot exclusion
            headers or other measures that restrict access to the ODR services
            or data contained in the website or the portal, or use any
            software, technology, or device to scrape, spider, or crawl the
            website, the portal, or the systems of SMART ODR Portal or harvest
            or manipulate data of SMART ODR Portal or any users of the ODR
            services;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="v.">
            Provide false, misleading, deceptive, fabricated or inaccurate
            evidence purposefully, testimony or witness statements or use the
            ODR services for any unlawful or illegal purpose;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="vi.">
            Engage in communication or post any material on the website or the
            portal that is abusive, offensive, threatening, obscene,
            defamatory, casteist, sexist, pornographic, inciting violence, or
            otherwise victimises or intimidates an individual or group on the
            basis of religion, gender, sexual orientation, race, caste,
            ethnicity, age, or disability, other than to the extent that such
            material constitutes an essential and indispensable part of the
            claims, pleadings or counter- claims of the user submitted in
            relation to an active dispute;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="vii.">
            Use another user’s account on the portal without permission or
            authorisation; to clarify, in the case of an institutional user
            with multiple accounts, all the accounts of the institutional user
            shall be deemed to be the accounts of the same user for the
            purpose of this clause;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="viii.">
            Provide false, fabricated, misleading, or inaccurate information
            during account registration;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="ix.">
            Destabilise, attempt to destabilise, interfere with or attempt to
            interfere with the proper functioning of the portal or the ODR
            services by using or accessing the portal or the ODR services
            through means other than what SMART ODR Portal has provided;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="x.">
            Publish or link to malicious content intended to damage or disrupt
            another user’s browser or computer;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xi.">
            Modify, translate, download, print, archive, display, adapt,
            enhance, decompile, disassemble, reverse engineer, exploit
            commercially or otherwise, create derivative works from, make
            copies or create archives of the website, the portal or any
            content thereon for any purpose, except as explicitly permitted as
            part of the ODR services made available in relation to the active
            dispute or as agreed with SMART ODR Portal ; to clarify, the
            foregoing will not be applicable to any action taken by a user
            with the prior consent of SMART ODR Portal ;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xii.">
            Facilitate the use of the website, the portal or the ODR services
            by or to the benefit of, any unauthorised third parties;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xiii.">
            Abuse, ‘stalk’, harm, threaten, impersonate, harass, deceive, any
            other person or entity through the use of the portal, the website
            or the ODR services;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xiv.">
            Hack, infiltrate or otherwise seek to gain unauthorized access to,
            or compromise the integrity of, the website or the portal;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xv.">
            Breach or attempt to breach the security of software, network,
            servers, data, computers or other hardware relating to the website
            or the portal or that of any third party that is hosting or
            interfacing with any part of the website, the portal;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xvi.">
            Violate any applicable local, state, national or international law
            or regulation.

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xvii.">
            The user acknowledges and agrees that:

          </li>


          <li className='font-serif pl-4 pt-2' data-list-text="xviii.">
            The user is not aware of any ground to believe that it will derive
            any mala fide or illegitimate benefit by use of ODR services, the
            website or the portal in relation to the active dispute;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xix.">
            The user is solely liable to SMART ODR Portal for the service fees
            associated with the use of the ODR services in relation to the
            active dispute, regardless of any outcome of such application or
            use of the ODR services;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xx.">
            The user shall make best endeavours to provide to SMART ODR Portal
            and any drp appointed by SMART ODR Portal, all information, data,
            content, messages, graphics, drawings, sound and image files,
            photographs, recordings, software and, in general, whatever type
            of material as is sought by SMART ODR Portal or the drp in
            relation to the active dispute, to the extent the same is
            available with the user in order to expedite the resolution of the
            active dispute;

          </li>

          <div className="html2pdf__page-break"></div>
          {/* ///////////////////// ///////////////////// ///////////////////// */}
          <li className='font-serif pl-4 pt-2' data-list-text="xxi.">
            The user has all the necessary equipment, systems, connectivity,
            resources, capabilities, understanding, competency, technological
            and other awareness to use the ODR services in relation to the
            active dispute in a fair, just and transparent manner;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xxii.">
            SMART ODR Portal shall not be responsible for any malfunctions,
            errors, crashes or other adverse events that is solely
            attributable to the user’s use of the website or the portal;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xxiii.">
            The website, the portal, any software owned or developed or used
            by SMART ODR Portal (software) in relation to the ODR services and
            all right, title, and interest in such website, portal and
            software shall be the sole property of MII’s and any licensors of
            SMART ODR Portal, if any;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xxiv.">
            The user’s use of, access to, the website, the portal, the
            software, hereunder constitute a limited license from SMART ODR
            Portal to the user, to use the ODR services made available on the
            portal in relation to the active dispute as contemplated
            hereunder, and that the user has no other rights or interest in,
            or claim over the website, the portal, the software or any other
            intellectual property of SMART ODR Portal whatsoever;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xxv.">
            SMART ODR Portal does not solicit nor does it wish to receive any
            confidential, secret or proprietary information, personal data, or
            other material from the user through the portal, by e- mail, or in
            any other way, which is not required for resolving an active
            dispute. Any confidential, secret or proprietary information,
            personal data, any ideas, concepts, methods, systems, designs,
            plans, techniques or other similar materials submitted or sent by
            the user to SMART ODR Portal , in relation to an active dispute
            (user content)through the portal, by e- mail, or in any other way
            are subject to any applicable privacy obligations of SMART ODR
            Portal under the pp or any separate written agreements entered
            into by SMART ODR Portal with the user; SMART ODR Portal does not
            review, filter, pre-screen, monitor, or otherwise edit user
            content posted, or otherwise made available to any other person,
            on or through the portal; however, SMART ODR Portal reserves the
            right (but assumes no obligation) to, at its sole discretion,
            refuse, filter out, pre-screen, edit or remove any user content,
            in whole or part, that, does not comply with these Terms, is
            illegal, or is otherwise undesirable or inappropriate from any
            members’ forum, public forum, message boards or

            comments sections on the website or the portal that can also be
            accessed by persons other than the user, SMART ODR Portal and the
            drps;

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xxvi.">
            All communications and evidence related to intake, case
            assessment, settlement discussions, negotiation and mediation
            conducted on SMART ODR Portal, whether via the platform itself or
            SMART ODR Portal initiated video hearings, phone calls or other
            discussions, will remain confidential in the interests of
            furthering settlement. The parties further agree that neither
            party shall take any action that will harm the reputation of any
            of the other, or which would reasonably be expected to lead to
            unwanted or unfavourable publicity to either of the parties or
            entities. This confidentiality clause does not apply to facts,
            communication, documentation or other information received or
            gathered outside of the SMART ODR Portal dispute resolution
            process.

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="xxvii.">
            Without prejudice to any other rights of SMART ODR Portal, SMART
            ODR Portal may, at any time, limit, suspend, or revoke the user’s
            access to the website, the portal or the ODR services if the user
            is in breach, or if SMART ODR Portal has reason to believe such
            user is in breach, of these Terms. SMART ODR Portal may change, add
            or remove any of the ODR services or features available on the
            portal, or may discontinue the portal as a whole, at any time subject to prior approval of MIIs.
            However, SMART ODR Portal will not discontinue any feature or ODR
            service for which a user has already paid fees to SMART ODR Portal
            without mutual agreement with such user, unless SMART ODR Portal
            replaces such feature or ODR service with a different feature or
            ODR service that has the same effect as such discontinued feature
            or ODR service under applicable law.

          </li>
        </ol>
        <br />
        <br />
        <h1 className='text-black font-serif font-bold text-sm' style={{ paddingLeft: '13pt', textIndent: '0pt', textAlign: 'justify' }}>
          Part IX - Indemnity
        </h1>

        <br />
        <p style={{ paddingLeft: '13pt', textIndent: '0pt', textAlign: 'justify' }}>
          The Market Participant shall indemnify the MIIs against any claims
          (including any third-party claims), demands, proceedings, actions,
          losses, costs, damages, charges (including reasonable legal fees and
          disbursements) arising as a result of (a) gross negligence, misrepresentation, 
          fraud, wilful default or (b) the former’s participation on the ODR Platform or 
          (c) any non-compliance with the provisions of these Terms of Use.
        </p>
        <br />
        <h1 className='text-black font-serif font-bold text-sm' style={{ paddingLeft: '13pt', textIndent: '0pt', textAlign: 'justify' }}>
          Part X - No liability for lost data
        </h1>
        <ol className='list-outside pl-10' style={{ listStyleType: 'lower-roman' }}>
          <br />
          <li className='font-serif pl-4 pt-2' data-list-text="iii.">
            SMART ODR Portal shall not be liable for any loss, deletion, removal,
            or failure of delivery (“losses”) to the intended recipient of any
            user content, whether caused by computer virus, unauthorised access,
            malicious attack or otherwise unless such losses are solely
            attributable to SMART ODR Portal. The user is encouraged to retain a
            back-up copy of all user content.
            <br />

          </li>
          <br />
        </ol>
        <h1 className='text-black font-serif font-bold text-sm' style={{ paddingLeft: '13pt', textIndent: '0pt', textAlign: 'justify' }}>
          Part XI - Disclaimer of warranties and limitation of liability
        </h1>
        <ol className='list-outside pl-10' style={{ listStyleType: 'lower-roman' }}>
          <br />
          <li className='font-serif pl-4 pt-2' data-list-text="iv.">
            Your use of the website and the portal is at your sole risk, which is
            provided on an “as is” and “as available” basis. We and our licensors
            expressly disclaim all warranties of any kind, whether express or
            implied. We do not guarantee the accuracy, completeness, justness,
            fairness, neutrality, validity or usefulness of the ODR services, any
            award or settlement made by any drp, any other service or content
            provided by SMART ODR Portal, and you rely on the ODR

            services, the drp allocated for an active dispute and other service or
            content of SMART ODR Portal at your own risk. Any act by Us in pursuance of any order 
            passed by the ODR institution shall be legitimate and you shall have no claim against
             Us in this regard. Any material that you
            access or obtain through our services is done at your own discretion
            and risk and you will be solely responsible for any damage to your
            computer or loss of data that results from the download of any
            material through our services. Some jurisdictions may prohibit a
            disclaimer of warranties and you may have other rights that vary from
            jurisdiction to jurisdiction.            
          </li>

          <li className='font-serif pl-4 pt-2' data-list-text="v.">
            To the maximum extent permitted by applicable law, we and our
            licensors will not be liable for any direct, indirect, incidental,
            special, consequential, or exemplary losses or damages, including but
            not limited to, damages for loss of profits, goodwill, use, data, or
            other intangible losses (even if we have been advised of the
            possibility of these damages), resulting from your use of the ODR
            services, the portal or the services of the drp allocated to your
            dispute. Under no circumstances will the total liability of SMART ODR
            Portal and our licensors of any kind arising out of or related to your
            use of the services (including but not limited to warranty claims),
            regardless of the forum and regardless of whether any action or claim
            is based on contract, tort, or otherwise, exceed the amounts, if any,
            that you have paid to us for your use of the ODR services in relation
            to the active dispute to which such losses or damages relate.

          </li>
        </ol>


        <div className="html2pdf__page-break"></div> 
          {/* ///////////////////// ///////////////////// ///////////////////// */}
        <br />
        <h1 className='text-black font-serif font-bold' style={{ paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>
          Part XII - Complaints
        </h1>
        <br />
        <ol className='list-outside pl-10' style={{ listStyleType: 'lower-roman' }}>
          <li className='font-serif pl-4 pt-2' data-list-text="i.">
            You may communicate with the host/other facilitating MIIs for any
            grievance that you experience in connection with the services or the
            platform, at

            <br />
            <p className='font-serif pl-4 pt-2 text-sm'>
              Name: Jishnu Veetil
            </p>
            <p className='font-serif pl-4 pt-2'>
              E-mail: help@smartODR.in
            </p>
            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="ii.">
            Please provide your name, E-mail address, physical address and contact
            numbers so that the host may be in a position to verify details or
            check the authenticity of the complaints.
          </li>
        </ol>
        <br />
        <h1 className='text-black font-serif font-bold text-sm' style={{ paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>
          Part XIII- Miscellaneous
        </h1>
        <br />
        <ol className='list-outside pl-10' style={{ listStyleType: 'lower-roman' }}>
          <li className='font-serif pl-4 pt-2' data-list-text="i.">
            <b className='font-serif'>Termination/ Suspension</b>: This Terms and Conditions may be Termination/ Suspension, only in the
            following circumstance(s):
            <br />
            <ol className='list-outside pl-10' style={{ listStyleType: 'lower-alpha' }}>
              <li className='font-serif pl-4 pt-2' data-list-text="(a)">
                by the MIIs, for any reason, including if you violate these Terms
                or on account of inactive/dormant User status, death, insolvency,
                or bankruptcy of the User, any restriction imposed by any
                legal/governmental/judicial/regulatory/other authority, or for any
                other cause arising out of applicable laws;

                <br />
              </li>
            </ol>
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="ii.">
            Notwithstanding such termination, all provisions of these Terms which
            by their nature are intended to survive, shall survive termination and
            continue to be applicable. Notwithstanding any termination under this
            Clause, you will not be relieved of your obligations incurred
            hereunder prior to the date on which such termination shall become
            effective.

          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="iii.">
            <b className='font-serif'>Waiver</b>: No waiver of any provision of these Terms shall be binding unless
            executed and notified by the Host in writing to you. No waiver of
            any of the provisions of these Terms shall be deemed or shall
            constitute a waiver of any other provision and no waiver shall
            constitute a continuing waiver. You hereby waive any present or
            future claims you have against the Host arising from your use of the
            Platform.
            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="iv.">
            <b className='font-serif'>Assignment</b>: You may not assign your obligations under these Terms or any part
            thereof without the prior written consent of the MIIs.
            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="v.">
            <b className='font-serif'>Severability</b>: If any provision of these Terms is determined to be invalid or
            unenforceable, it will not affect the validity or enforceability of
            the other provisions of these Terms, which shall remain in full
            force and effect.
            <br />
          </li>
          <li className='font-serif pl-4 pt-2' data-list-text="vi.">
            <b className='font-serif'>Governing Law</b>: These Terms are governed by the laws of India. Any disputes that
            may arise in connection herewith or relating to these Terms or
            anything done hereunder shall be subject to the exclusive
            jurisdiction of the courts at Mumbai, Maharashtra, India.
          </li>
        </ol>
      </div>
    </Fragment>
  )
}

export default TermsAndCondition