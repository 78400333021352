import { useEffect, useRef, useState } from "react"
import { ApiController } from "../../../apiController";
import { useCombinedStore } from "../../../store";
import { Link } from "react-router-dom";
import InfoTooltip from "../../../helpers/Tooltip";
import { MergeOrganisation, Organisation } from "../../../swagger/models";
import _ from "lodash";

function MergeAccountSettings() {
  const [intermediaryPrimarysearchTerm, setintermediaryPrimarysearchTerm] = useState<string>('');
  const [intermediarySecondarysearchTerm, setintermediarySecondarysearchTerm] = useState<string>('');
  const [intermediary, setIntermediary] = useState<MergeOrganisation>({})
  const delayedQuery = useRef(_.debounce((organisationName) => searchOrganisations(organisationName), 500)).current;
  const [filteredPrimaryIntermediaries, setfilteredPrimaryIntermediaries] = useState<Organisation[]>([]);
  const [filteredSecondaryIntermediaries, setfilteredSecondaryIntermediaries] = useState<Organisation[]>([]);

  const searchOrganisations = useCombinedStore((state: any) => state.searchOrganisations)
  const matchingOrganisations = useCombinedStore((state: any) => state.matchingOrganisations)
  const mergeDuplicateAccounts = useCombinedStore((state: any) => state.mergeDuplicateAccounts)
  const currentURL = window.location.href.split('/')[3]

  useEffect(() => {
    ApiController.getInstance().user$.subscribe((user: any) => {
      if (user && user.Organisation) {
        const length = user?.Organisation?.organisationName?.length
        const orgName = user?.Organisation?.organisationName.substring(0, Math.floor(length * 0.6))
        setintermediaryPrimarysearchTerm(orgName)
      }
    });
  }, [])

  useEffect(() => {
    delayedQuery(intermediaryPrimarysearchTerm);
  }, [intermediaryPrimarysearchTerm])

  useEffect(() => {
    delayedQuery(intermediarySecondarysearchTerm);
  }, [intermediarySecondarysearchTerm])

  useEffect(() => {
    if (intermediaryPrimarysearchTerm !== matchingOrganisations[0]?.organisationName) {
      setfilteredPrimaryIntermediaries(matchingOrganisations);
    }
    if (intermediarySecondarysearchTerm !== matchingOrganisations[0]?.organisationName) {
      setfilteredSecondaryIntermediaries(matchingOrganisations);
    }
  }, [matchingOrganisations])

  const mergeAccounts = () => {
    mergeDuplicateAccounts(intermediary)
  }

  return (
    <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl">
        <p className='sm:block hidden text-xs text-back-gray'><Link to={`/${currentURL}/settings`}>Settings</Link> &gt; Duplicate account Settings</p>
        <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue pb-3">Duplicate account Settings</h1>
        <div className="bg-white w-full flex flex-col rounded-xl gap-4">
          <Link to={`/${currentURL}/settings`} className="py-3 border-b border-F5F5F5 text-xs pl-7">Back</Link>
          <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
            <i className="ph-bold ph-bell sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
            <div className="flex flex-col sm:gap-2 gap-1">
              <p className="sm:text-sm text-xs">Duplicate accounts </p>
              <p className="sm:text-xs text-[10px] text-back-gray">Enable or Disable Notifications (This will only impact dispute related notifications.)</p>
            </div>
          </div>
          <div className="pl-7 pb-8 pt-4 flex flex-col gap-3">
            <div className="flex gap-5 items-end">
              <div className="relative w-full">
                <div className="inline-flex flex-col gap-2 w-full">
                  <div className="flex flex-row gap-2 items-center">
                    <label htmlFor="CompanyName" className="text-xs">Select Primary Intermediary</label>
                    <InfoTooltip title="Enter the name of the specific Intermediary against whom you wish to file a dispute and select it from the dropdown." />
                  </div>
                  <input
                    className="sm:w-1/2 p-2 border border-back-gray text-xs bg-white rounded-xl"
                    type="text"
                    placeholder="Search..."
                    value={intermediaryPrimarysearchTerm}
                    onChange={(event: any) => setintermediaryPrimarysearchTerm(event.target.value)}
                  />
                </div>
                {filteredPrimaryIntermediaries.length > 0 && !intermediary?.secondaryEmail && (
                  <ul className="origin-top-right absolute left-0 mt-2 sm:w-1/2 w-fit rounded-lg bg-white text-xs px-1 py-2">
                    {filteredPrimaryIntermediaries?.map((item: any, index: any) => (
                      <li
                        className="px-3 py-1 hover:bg-back-gray rounded-lg cursor-pointer"
                        key={index} onClick={() => {
                          setintermediaryPrimarysearchTerm(item.organisationName)
                          setIntermediary({
                            ...intermediary,
                            primaryEmail: item.primaryEmail,
                            primaryOrgId: item.orgId
                          })
                          setfilteredPrimaryIntermediaries([])
                        }}>
                        {item.organisationName}
                      </li>
                    ))}
                  </ul>
                )}
                <div className="flex gap-6 items-end">
                  {intermediary?.primaryEmail}
                </div>

                <div className="inline-flex flex-col gap-2 w-full">
                  <div className="flex flex-row gap-2 items-center">
                    <label htmlFor="CompanyName" className="text-xs">Select Secondary Intermediary</label>
                    <InfoTooltip title="Enter the name of the specific Intermediary against whom you wish to file a dispute and select it from the dropdown." />
                  </div>
                  <input
                    className="sm:w-1/2 p-2 border border-back-gray text-xs bg-white rounded-xl"
                    type="text"
                    placeholder="Search..."
                    value={intermediarySecondarysearchTerm}
                    onChange={(event: any) => setintermediarySecondarysearchTerm(event.target.value)}
                  />
                </div>
                {filteredSecondaryIntermediaries.length > 0 && intermediary?.primaryEmail && (
                  <ul className="origin-top-right absolute left-0 mt-2 sm:w-1/2 w-fit rounded-lg bg-white text-xs px-1 py-2">
                    {filteredSecondaryIntermediaries?.map((item: any, index: any) => (
                      <li
                        className="px-3 py-1 hover:bg-back-gray rounded-lg cursor-pointer"
                        key={index} onClick={() => {
                          setintermediarySecondarysearchTerm(item.organisationName)
                          setIntermediary({
                            ...intermediary,
                            secondaryEmail: item.primaryEmail,
                            secondaryOrgId: item.orgId,
                            organisationName: item.organisationName,
                          })
                          setfilteredSecondaryIntermediaries([])
                        }}>
                        {item.organisationName}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

            </div>
            <div className="flex gap-6 items-end">
              {intermediary?.secondaryEmail}
            </div>
            <div className="flex flex-row gap-3 py-3">
              <button onClick={mergeAccounts} className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2.5 rounded-lg disabled:bg-highlight-blue/[0.4]" aria-label="Save Notification">
                Merge
              </button>
            </div>
          </div>
          <p className="pb-3 text-xs pl-7">{" "}</p>
        </div>

      </div>
    </div>
  )
}

export default MergeAccountSettings