import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useCombinedStore } from "../../../store";
import { ConciliationReport, DisputeArbitrator, User } from "../../../swagger/models";
import { notifyError } from "../../../helpers/helper";
import { dateHelper } from "../../common-module/helper";
import { Tooltip } from "react-tippy";

type ConciliatorDetail = {
  name: string;
  dateOfAppointment: string;
  dateOfRejection: string;
  dateOfApproval: string;
};

interface ConciliationReportProps {
  disputeId: any;
  claimAmount: number;
  status: string;
  isEditDisabled: boolean;
  miiId: number;
}

function ConciliationReportComponent({ disputeId, claimAmount, status, isEditDisabled, miiId }: ConciliationReportProps) {

  const { t } = useTranslation();
  const [isSegmentDetailsEdit, setIsSegmentDetailsEdit] = useState<boolean>(false)
  const [isProcessdetailsEdit, setIsProcessdetailsEdit] = useState<boolean>(false)
  const [isOutcomedetailsEdit, setIsOutcomedetailsEdit] = useState<boolean>(false)
  const [isCompliancedetailsEdit, setIsCompliancedetailsEdit] = useState<boolean>(false)
  const [isOfficerDetailsEdit, setIsOfficerDetailsEdit] = useState<boolean>(false)
  const [awardFileUploadDetails, setAwardFileUploadDetails] = useState<any>({
    file: '',
    documentType: '',
    summary: ''
  })
  const [awardUploadModal, setAwardUploadModal] = useState(false)
  const [conciliatorAppointmentModal, setConciliatorAppointmentModal] = useState<boolean>(false)
  const [isConcilatorEdit, setIsConcilatorEdit] = useState<boolean>(true)
  const [conciliatorDetails, setConciliatorDetails] = useState<DisputeArbitrator[]>([]);

  const [conciliationReport, setConciliationReport] = useState<ConciliationReport>({
    complainId: disputeId,
  })

  const updateConciliatorAward = useCombinedStore((state: any) => state.updateConciliatorAward);
  const createConciliatorAward = useCombinedStore((state: any) => state.createConciliatorAward);
  const createArbitrator = useCombinedStore((state: any) => state.createArbitrator);
  const getConciliatorAward = useCombinedStore((state: any) => state.getConciliatorAward);
  const conciliatorFiles = useCombinedStore((state: any) => state.conciliatorFiles);
  const conciliatorAwardDetails = useCombinedStore((state: any) => state.conciliatorAwardDetails);
  const downloadFileOfComplaint = useCombinedStore((state: any) => state.downloadFile)
  const uploadConciliatorFile = useCombinedStore((state: any) => state.uploadConciliatorFile);
  const listGetUsers = useCombinedStore((state: any) => state.listGetUsers);
  const officers = useCombinedStore((state: any) => state.officers);
  const getArbitrators = useCombinedStore((state: any) => state.getArbitrators);
  const selectedArbitrators = useCombinedStore((state: any) => state.selectedArbitrators);

  const handleAddRow = () => {
    setConciliatorDetails([
      ...conciliatorDetails,
      {
        arbitratorName: '',
        arbitratorRejectionDate: '',
        arbitratorAcceptanceDate: '',
        complainId: disputeId,
        process: 'Conciliation',
        arbIndex: 1,
      },
    ]);
  };

  const handleInputChange = (
    index: number,
    field: keyof DisputeArbitrator,
    value: string
  ) => {
    const updatedDetails = [...conciliatorDetails];
    if (field === 'isReappointed') {
      updatedDetails[index].reappointedBehalfOf = conciliatorDetails[index - 1].disputeArbitratorId;
    }
    if (field === 'isApproved') {
      updatedDetails[index].arbitratorRejectionDate = null;
      updatedDetails[index].arbitratorAcceptanceDate = null;
    }
    updatedDetails[index][field] = value;
    console.log(updatedDetails)
    setConciliatorDetails(updatedDetails);
  };

  const currentURL = window.location.href.split('/')[3]

  const saveOutComeDetails = (detailType: string) => {
    if (conciliationReport.conciliationReportId) {
      updateConciliatorAward(conciliationReport, detailType)
    } else {
      createConciliatorAward(conciliationReport, detailType)
    }
    setIsOutcomedetailsEdit(false)
    setIsProcessdetailsEdit(false)
    setIsCompliancedetailsEdit(false)
    setIsSegmentDetailsEdit(false)
    setIsOfficerDetailsEdit(false)
  }

  const captureInputChange = (e: any) => {
    const { name, value } = e.target
    setConciliationReport({
      ...conciliationReport,
      [name]: value,
    });
  }

  useEffect(() => {
    setConciliationReport({
      ...conciliationReport,
      complainId: disputeId
    })
    if (disputeId) {
      getConciliatorAward(disputeId)
      getArbitrators(disputeId)
    }
    if (currentURL === 'mii' || currentURL === 'odr') {
      listGetUsers(miiId)
    }
  }, [disputeId])

  useEffect(() => {
    if (conciliatorAwardDetails?.conciliationReportId) {
      setConciliationReport(conciliatorAwardDetails)
    }
  }, [conciliatorAwardDetails])

  useEffect(() => {
    if (selectedArbitrators?.length > 0) {
      setConciliatorDetails(selectedArbitrators?.filter((arbitrator: DisputeArbitrator) => arbitrator.process === 'Conciliation'))
    }
  }, [selectedArbitrators])

  const handleUploadAwards = (e: any) => {
    const file = e.target.files;
    let fileSize = 0
    for (const f of file) {
      fileSize += f.size;
    }
    if (fileSize > 20 * 1000 * 1024) {
      notifyError(t('UploadFileExceeds'))
      return false;
    }
    console.log(file)
    setAwardFileUploadDetails({
      ...awardFileUploadDetails,
      file: file[0],
    })
  }

  const submitFileDetails = (e: any) => {
    e.preventDefault();
    uploadConciliatorFile(awardFileUploadDetails, conciliationReport?.conciliationReportId);
    setAwardUploadModal(false)
    handleRemoveFile()
  }

  const handleRemoveFile = () => {
    setAwardFileUploadDetails({
      ...awardFileUploadDetails,
      file: null,
    });
  }

  const downloadFile = async (key: string) => {
    let res = await downloadFileOfComplaint(key, disputeId, null, null, conciliationReport?.conciliationReportId)
    if (!res.error) window.open(res.file)
  }

  const selectOfficer = (e: any) => {
    setConciliationReport({
      ...conciliationReport,
      officerName: officers.find((o: User) => o.userId === parseInt(e.target.value, 10)).userName,
      officerId: e.target.value,
    });
  }


  const saveConArbDetails = () => {
    createArbitrator({
      complainId: disputeId,
      DisputeArbitrators: conciliatorDetails.filter((e: DisputeArbitrator) => e.arbitratorName.length > 2)
    }, 'Conciliation')
    setIsConcilatorEdit(false)
  }

  return (
    <div className="hidden sm:flex flex-col gap-4 border-l border-border-grey">
      {/* Segment  */}
      <div className="flex flex-col gap-4 pl-8 pb-4 border-b border-border-grey">
        <div className="flex items-center justify-between">
          <p className="sm:text-sm text-highlight-blue text-xs font-semibold">{t('Segment')}</p>
          <div className='flex gap-2'>
            {(currentURL === 'mii' || currentURL === 'odr') && status !== 'AWAITING_MII_REVIEW' && isEditDisabled &&
              <button className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsSegmentDetailsEdit(true)}>{t('Edit')}</button>
            }
            {isSegmentDetailsEdit &&
              <button onClick={() => saveOutComeDetails('Segment')} className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
            }
          </div>
        </div>
        {!isSegmentDetailsEdit && conciliationReport?.segment &&
          <span className="bg-white w-fit text-reports-grey rounded-lg text-xs font-semibold sm:text-sm px-4 py-2">{conciliationReport?.segment}</span>
        }
        {isSegmentDetailsEdit &&
          <div className="flex sm:flex-row items-center flex-col gap-2 sm:justify-between w-full">
            <select onChange={captureInputChange} value={conciliationReport?.segment}
              aria-label={'Segment'} required name='segment' id='segment'
              className="text-xs p-2 w-1/2 border border-back-gray bg-white rounded-xl"

            >
              <option>{t('SelectSegment')}</option>
              <option value={'1'}>1</option>
              <option value={'2'}>2</option>
            </select>
          </div>
        }
      </div>
      {/* Process Details  */}
      <div className="flex flex-col gap-4 pl-8 pb-4 border-b border-border-grey">
        <div className="flex items-center justify-between">
          <p className="sm:text-sm text-xs font-semibold text-highlight-blue">{t('ProcessDetails')}</p>
          <div className='flex gap-2'>
            {(currentURL === 'mii' || currentURL === 'odr') && status !== 'AWAITING_MII_REVIEW' && isEditDisabled &&
              <button className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsProcessdetailsEdit(true)}>{t('Edit')}</button>
            }
            {isProcessdetailsEdit &&
              <button onClick={() => saveOutComeDetails('Process Details')} className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
            }
          </div>
        </div>
        <div>
          {!isProcessdetailsEdit &&
            <>
              <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                <p className="text-reports-light-grey w-1/2">{t('ClaimAmount')}</p>
                <p className='text-highlight-blue w-1/2'>₹{claimAmount}</p>
              </div>
              <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                <p className="text-reports-light-grey w-1/2">{t('FeePaidMarketParticipant')}</p>
                <p className='text-highlight-blue w-1/2'>{(conciliationReport?.isFeePaidByMP !== null && conciliationReport?.isFeePaidByMP !== undefined) ? (conciliationReport?.isFeePaidByMP ? 'Yes' : 'No') : ''}</p>
              </div>
              <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                <p className="text-reports-light-grey w-1/2">{t('DateFeePaidMarketParticipant')}</p>
                <p className='text-highlight-blue w-1/2'>{dateHelper(conciliationReport?.mpFeesDate)}</p>
              </div>
            </>
          }
        </div>
        <div className="flex flex-col w-full h-fit gap-14 overflow-hidden">
          <div className="flex flex-col gap-4">
            {isProcessdetailsEdit &&
              <>
                <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                  <p className="text-sm text-reports-light-grey w-1/2">{t('ClaimAmount')}</p>
                  <input type="text" readOnly value={claimAmount} name="claimAmount" id="claimAmount" className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                </div>
                <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                  <p className="text-sm text-reports-light-grey w-1/2">{t('FeePaidMarketParticipant')}</p>
                  <select onChange={captureInputChange} value={conciliationReport?.isFeePaidByMP}
                    aria-label={'FeePaid'} required name='isFeePaidByMP' id='isFeePaidByMP'
                    className="text-xs p-2 w-1/2 border border-back-gray bg-white rounded-xl"
                  >
                    <option disabled selected>Select</option>
                    <option value={'true'}>{t('Yes')}</option>
                    <option value={'false'}>{t('No')}</option>
                  </select>
                </div>
                <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                  <p className="text-sm text-reports-light-grey w-1/2">{t('DateFeePaidMarketParticipant')}</p>
                  <input type="date" onChange={captureInputChange} value={conciliationReport?.mpFeesDate} name="mpFeesDate" id="mpFeesDate"
                    className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                </div>
              </>
            }
          </div>
        </div>
      </div>
      {/* Conciliator Details  */}
      <div className="flex flex-col gap-4 pl-8 pb-4 border-b border-border-grey">
        <div>
          <>
            <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
              <p className="text-reports-light-grey w-1/2">{t('ConciliatorName')}</p>
              <p className='text-highlight-blue w-1/2'>{conciliatorDetails?.[conciliatorDetails.length - 1]?.arbitratorName}</p>
            </div>
            <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
              <p className="text-reports-light-grey w-1/2">{t('ConciliatorDateAppointment')}</p>
              <p className='text-highlight-blue w-1/2'>{dateHelper(conciliatorDetails?.[conciliatorDetails.length - 1]?.arbitratorAppointedDate)}</p>
            </div>
            {conciliatorDetails?.[conciliatorDetails.length - 1]?.arbitratorAcceptanceDate &&
              <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                <p className="text-reports-light-grey w-1/2">{t('DateOfApproval')}</p>
                <p className='text-highlight-blue w-1/2'>{dateHelper(conciliatorDetails?.[conciliatorDetails.length - 1]?.arbitratorAcceptanceDate)}</p>
              </div>
            }
            {conciliatorDetails?.[conciliatorDetails.length - 1]?.arbitratorRejectionDate &&
              <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                <p className="text-reports-light-grey w-1/2">{t('DateOfRejection')}</p>
                <p className='text-highlight-blue w-1/2'>{dateHelper(conciliatorDetails?.[conciliatorDetails.length - 1]?.arbitratorRejectionDate)}</p>
              </div>
            }
            {isEditDisabled && (currentURL === 'mii' || currentURL === 'odr') && (
              <p className="text-sm text-document-blue hover:underline cursor-pointer"
                onClick={() => { setConciliatorAppointmentModal(true); handleAddRow() }}>{t('EditConciliatorDetails')}</p>
            )}
          </>
        </div>

      </div>
      {/* Outcome Details  */}
      <div className="flex flex-col gap-4 pl-8 pb-4 border-b border-border-grey">
        <div className="flex items-center justify-between">
          <p className="sm:text-sm text-xs font-semibold text-highlight-blue">{t('OutcomeDetails')}</p>
          <div className='flex gap-2'>
            {(currentURL === 'mii' || currentURL === 'odr') && status !== 'AWAITING_MII_REVIEW' && isEditDisabled &&
              <button className="text-xs text-label-grey pl-3 pr-2"
                onClick={(e: any) => setIsOutcomedetailsEdit(true)}>{t('Edit')}</button>
            }
            {isOutcomedetailsEdit &&
              <button onClick={() => saveOutComeDetails('Outcome Details')} className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
            }
          </div>
        </div>
        <div className="grid grid-cols-2 xl:grid-cols-3 gap-4">
          {!isOutcomedetailsEdit && conciliatorFiles?.map((file: any) =>
            <div className="border flex flex-col gap-3 rounded-lg p-4 bg-white">
              <h3 className="text-sm text-highlight-blue font-semibold">{file?.documentType}</h3>
              <p className="text-xs text-low-grey mt-2">
                {file?.summary?.length > 60 ? `${file.summary.slice(0, 60)}...` : file.summary}
              </p>
              <hr />
              <div onClick={(e) => downloadFile(file?.file)} className='flex gap-2 items-center'>
                {/* <button className="text-xs text-low-grey w-fit hover:underline">View</button>
                <i className='ph ph-eye text-low-grey'></i> */}
                <button className="text-xs text-low-grey w-fit hover:underline">{t('Download')}</button>
                <i className='ph ph-file-arrow-down text-low-grey'></i>
              </div>
            </div>
          )}
        </div>
        <div>
          <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
            <p className="text-reports-light-grey w-1/2">{t('ResolutionTime')}</p>
            {/* <p className='text-highlight-blue w-1/2'>0 Days</p> */}
          </div>
          {!isOutcomedetailsEdit &&
            <>
              <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                <p className="text-reports-light-grey w-1/2">{t('ConciliationOrderDate')}</p>
                <p className='text-highlight-blue w-1/2'>{dateHelper(conciliationReport?.conOrderDate)}</p>
              </div>
              <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                <p className="text-reports-light-grey w-1/2">{t('ConciliationOutcome')}</p>
                <p className='text-highlight-blue w-1/2'>{conciliationReport?.conOutcome}</p>
              </div>
              <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                <p className="text-reports-light-grey w-1/2">{t('AdmissibleClaimValue')}</p>
                <p className='text-highlight-blue w-1/2'>{conciliationReport?.admissibleClaimAmount ? '₹' : ''}{conciliationReport?.admissibleClaimAmount}</p>
              </div>
            </>
          }
        </div>
        <div className="flex flex-col w-full h-fit gap-14 overflow-hidden">
          <div className="flex flex-col gap-4">
            {isOutcomedetailsEdit &&
              <>
                <div className="grid grid-cols-2 xl:grid-cols-3 gap-4">
                  {conciliatorFiles?.map((file: any) =>
                    <div className="border flex flex-col gap-3 rounded-lg p-4 bg-white">
                      <h3 className="text-sm text-highlight-blue font-semibold">{file.documentType}</h3>
                      <p className="text-xs text-low-grey mt-2">{t('ViewFile')}</p>
                      <hr />
                      <div onClick={(e) => downloadFile(file?.file)} className='flex gap-2 items-center'>
                        <button className="text-xs text-low-grey w-fit hover:underline">{t('Download')}</button>
                        <i className='ph ph-file-arrow-down text-low-grey'></i>
                      </div>
                    </div>
                  )}
                  <label htmlFor='upload-award' className="flex flex-col gap-1">
                    <Tooltip
                      title={t('AddDocuments')}
                      position="top"
                      arrow={false}
                      animation="shift"
                      size="small"
                      {...({
                        children: <><i className="text-base text-highlight-blue cursor-pointer hover:text-highlight-blue/50 border border-highlight-blue w-fit px-2 py-1 items-center flex justify-center ph-bold ph-plus" style={{ borderRadius: '50%' }}></i>
                          <button onClick={() => setAwardUploadModal(true)} id="upload-award" style={{ display: 'none' }} /></>
                      } as any)} // Type assertion to bypass TS errors
                    >
                    </Tooltip>
                  </label>
                </div>
                <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                  <p className="text-sm text-reports-light-grey w-1/2">{t('ConciliationOrderDate')}</p>
                  <input type="date" onChange={captureInputChange} value={conciliationReport?.conOrderDate} name="conOrderDate" id="conOrderDate"
                    className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                </div>
                <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                  <p className="text-sm text-reports-light-grey w-1/2">{t('ConciliationOutcome')}</p>
                  <select
                    aria-label={t('ConciliationOutcome')} required
                    className="sm:w-full text-xs p-2 border border-back-gray bg-white rounded-xl"
                    onChange={captureInputChange} value={conciliationReport?.conOutcome} name="conOutcome" id="conOutcome"
                  >
                    <option disabled selected>Select</option>
                    <option value={'In favour of Investor'}>{t('InfavourofInvestor')}</option>
                    <option value={'Matter Withdrawn After referred to Conciliation'}>{t('MatterWithdrawn')}</option>
                    <option value={'Matter Dismissed'}>{t('MatterDismissed')}</option>
                    <option value={'Mutual Settlement'}>{t('MutualSettlement')}</option>
                  </select>
                </div>
                <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                  <p className="text-sm text-reports-light-grey w-1/2">{t('AdmissibleClaimValue')}</p>
                  <input type="number" step="0.001" onChange={captureInputChange} value={conciliationReport?.admissibleClaimAmount} name="admissibleClaimAmount" id="admissibleClaimAmount"
                    className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                </div>
              </>
            }
          </div>
        </div>
      </div>
      {/* Compliance Status  */}
      <div className="flex flex-col gap-4 pb-4 pl-8 border-b border-border-grey">
        <div className="flex items-center justify-between">
          <p className="sm:text-sm text-xs font-semibold text-highlight-blue">{t('ComplianceStatus')}</p>
          <div className='flex gap-2'>
            {(currentURL === 'mii' || currentURL === 'odr') && status !== 'AWAITING_MII_REVIEW' && isEditDisabled &&
              <button className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsCompliancedetailsEdit(true)}>{t('Edit')}</button>
            }
            {isCompliancedetailsEdit &&
              <button onClick={() => saveOutComeDetails('Compliance Status')} className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
            }
          </div>
        </div>
        {!isCompliancedetailsEdit &&
          <>
            {conciliationReport?.complianceStatus &&
              <span className="bg-white w-fit border border-reports-border text-reports-grey rounded-lg text-xs sm:text-sm px-4 py-2">{t(conciliationReport?.complianceStatus)}</span>
            }
            <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
              <p className="text-reports-light-grey w-1/2">{t('OrderCompliedMarketParticipant')}</p>
              <p className='text-highlight-blue w-1/2'>{(conciliationReport?.orderCompliedByMP !== null && conciliationReport.orderCompliedByMP !== undefined) ? (conciliationReport?.orderCompliedByMP ? 'Yes' : 'No') : ''}</p>
            </div>
            <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
              <p className="text-reports-light-grey w-1/2">{t('DateConciliationOrderComplied')}</p>
              <p className='text-highlight-blue w-1/2'>{dateHelper(conciliationReport?.conOrderCompliedDate)}</p>
            </div>
            <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
              <p className="text-reports-light-grey w-1/2">{t('SettlementAgreementExecuted')}</p>
              <p className='text-highlight-blue w-1/2'>{(conciliationReport?.settlementAgreementExecuted !== null && conciliationReport?.settlementAgreementExecuted !== undefined) ? (conciliationReport?.settlementAgreementExecuted ? 'Yes' : 'No') : ''}</p>
            </div>
            <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
              <p className="text-reports-light-grey w-1/2">{t('DateSettlementAgreementExecuted')}</p>
              <p className='text-highlight-blue w-1/2'>{dateHelper(conciliationReport?.settlementAgreementDate)}</p>
            </div>
          </>
        }
        {isCompliancedetailsEdit &&
          <div className="flex flex-col w-full h-fit gap-14 overflow-hidden">
            <div className="flex flex-col gap-4">
              <div className="flex sm:flex-row flex-col gap-2 justify-between w-full items-center">
                <p className="text-sm text-reports-light-grey w-1/2">{t('Compliedby')}:</p>
                <select
                  aria-label={t('Compliedby')} required name='complianceStatus' id='complianceStatus'
                  className="sm:w-full text-xs p-2 border border-back-gray bg-white rounded-xl"
                  onChange={captureInputChange} value={conciliationReport?.complianceStatus}
                >
                  <option disabled selected>Select</option>
                  <option value={'Complied by Market Participant'}>{t('CompliedbyMarketParticipant')}</option>
                  <option value={'Complied by Investor'}>{t('CompliedbyInvestor')}</option>
                  <option value={'Not Complied by Market Participant'}>{t('NotCompliedByMarketParticipant')}</option>
                  <option value={'Not Complied by Investor'}>{t('NotCompliedByInvestor')}</option>
                </select>
              </div>
              <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                <p className="text-sm text-reports-light-grey">{t('OrderCompliedMarketParticipant')}</p>
                <select
                  aria-label={'Order complied by Market Participant'} required name='orderCompliedByMP' id='orderCompliedByMP'
                  className="text-xs p-2 w-1/2 border border-back-gray bg-white rounded-xl"
                  onChange={captureInputChange} value={conciliationReport?.orderCompliedByMP}
                >
                  <option disabled selected>Select</option>
                  <option value={'true'}>{t('Yes')}</option>
                  <option value={'false'}>{t('No')}</option>
                </select>
              </div>
              <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                <p className="text-sm text-reports-light-grey">{t('DateConciliationOrderComplied')}</p>
                <input type="date" name="conOrderCompliedDate" id="conOrderCompliedDate" onChange={captureInputChange} value={conciliationReport?.conOrderCompliedDate}
                  className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
              </div>
              <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                <p className="text-sm text-reports-light-grey">{t('SettlementAgreementExecuted')}</p>
                <select
                  aria-label={'Settlement Agreement Executed'} required name='settlementAgreementExecuted' id='SsettlementAgreementExecutedettlementExecuted'
                  className="text-xs p-2 w-1/2 border border-back-gray bg-white rounded-xl"
                  onChange={captureInputChange} value={conciliationReport?.settlementAgreementExecuted}
                >
                  <option disabled selected>Select</option>
                  <option value={'true'}>{t('Yes')}</option>
                  <option value={'false'}>{t('No')}</option>
                </select>
              </div>
              <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                <p className="text-sm text-reports-light-grey">{t('DateSettlementAgreementExecuted')}</p>
                <input type="date" name="settlementAgreementDate" id="settlementAgreementDate" onChange={captureInputChange} value={conciliationReport?.settlementAgreementDate}
                  className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
              </div>

            </div>
          </div>
        }
      </div>
      {/* Officer Name  */}
      <div className="flex flex-col gap-4 pl-8 pb-4">
        <div className="flex items-center justify-between">
          <p className="sm:text-sm text-highlight-blue text-xs font-semibold">{t('OfficerName')}</p>
          <div className='flex gap-2'>
            {(currentURL === 'mii' || currentURL === 'odr') && status !== 'AWAITING_MII_REVIEW' && isEditDisabled &&
              <button className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsOfficerDetailsEdit(true)}>{t('Edit')}</button>
            }
            {isOfficerDetailsEdit &&
              <button onClick={() => saveOutComeDetails('Officer Name')} className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
            }
          </div>
        </div>
        {!isOfficerDetailsEdit && conciliationReport?.officerName &&
          <span className="bg-white w-fit text-reports-grey rounded-lg text-xs font-semibold sm:text-sm px-4 py-2">{conciliationReport?.officerName}</span>
        }
        {isOfficerDetailsEdit &&
          <div className="flex sm:flex-row items-center flex-col gap-2 sm:justify-between w-full">
            <select
              aria-label={' Select Officer Name'} required name='officerName' id='officerName'
              className="text-xs p-2 w-1/2 border border-back-gray bg-white rounded-xl"
              onChange={selectOfficer}
            >
              <option disabled selected>{t('SelectOfficer')}</option>
              {officers?.map((officer: User) =>
                <option value={officer?.userId}>{officer?.userName}</option>
              )}
            </select>
          </div>
        }
      </div>
      {/* Document Upload Modal  */}
      {awardUploadModal &&
        (
          <div
            className="fixed z-10 inset-0 flex items-center justify-center "
            id="modal"
          >
            <div className="px-8 sm:px-0 sm:w-1/2">
              <div className="flex justify-center pt-4 pb-20 w-full h-fit">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setAwardUploadModal(false)}
                >
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                  className="inline-block align-center w-full bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <form onSubmit={submitFileDetails}>
                    <div className="flex flex-col gap-4 px-8 py-5">
                      <div className='flex gap-4 items-center'>
                        <i className='ph ph-buildings text-2xl px-2 py-1 border border-border-grey rounded-md'></i>
                        <div className='flex flex-col w-full'>
                          <div className='flex justify-between w-full items-center'>
                            <p className='font-semibold sm:text-lg text-sm'>{t('AddOutcomeDocument')}</p>
                            <i onClick={() => { setAwardUploadModal(false); handleRemoveFile() }} className='ph ph-x hover:text-modal-grey cursor-pointer'></i>
                          </div>
                          <p className='text-xs text-modal-grey'>{t('AttachConArbReport')}</p>
                        </div>
                      </div>
                      <hr />
                      <div className='flex flex-col gap-6 text-label-grey'>
                        <div className="flex sm:flex-row flex-col gap-2 sm:gap-8 justify-between items-baseline">
                          <p className="text-sm sm:whitespace-nowrap font-medium">{t('DocumentType')}</p>
                          <select
                            aria-label={'Settlement Agreement Executed'} required name='documentType' id='documentType'
                            className="text-xs p-2 w-1/2 border border-back-gray bg-white rounded-xl"
                            onChange={(e) => {
                              setAwardFileUploadDetails({
                                ...awardFileUploadDetails,
                                documentType: e.target.value
                              })
                            }} value={awardFileUploadDetails?.documentType}
                          >
                            <option selected>Select</option>
                            <option value={'Conciliation Failure Report'}>{t('ConciliationFailureReport')}</option>
                            <option value={'Conciliation Settlement Agreement'}>{t('ConciliationSettlementAgreement')}</option>
                          </select>
                        </div>
                        <hr />
                        <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 justify-between items-baseline">
                          <div className="flex flex-row gap-2 items-center">
                            <label htmlFor="ReferenceNo" className="text-sm sm:whitespace-nowrap font-medium">{t('UploadFile')}*</label>
                          </div>
                          <div className="flex flex-row gap-3 items-center w-2/3 justify-end rounded-lg">
                            <div className="flex gap-2 items-center">
                              <div className="flex p-2.5 bg-base-gray" style={{ borderRadius: '50%' }}>
                                <i className="ph text-4xl ph-image-square"></i>
                              </div>
                              <label htmlFor="fileInput" className="cursor-pointer">
                                <div className="w-full 
                                  border
                                  border-dashed 
                                  border-border-secondary py-4
                                  rounded-lg
                                  flex 
                                  flex-col gap-3
                                  items-center px-4
                                  justify-center border-border-secondary">
                                  <div className="flex flex-col gap-1 items-center">
                                    <p className="text-xs sm:whitespace-nowrap"><span className="text-document-blue font-semibold">{t('ClickToUpload')}</span> {t('orDragAndDrop')}</p>
                                    <p className="text-xs sm:whitespace-nowrap">{t('FileFormat')}</p>
                                  </div>
                                </div>

                              </label>
                              <input onChange={handleUploadAwards} id="fileInput" aria-label={t('Choosefile')} type="file" style={{ display: 'none' }} accept={".pdf, .docx"}
                              />
                            </div>
                          </div>
                        </div>
                        {/* Display Uploaded File Names */}
                        {awardFileUploadDetails?.file && (
                          <div className="flex flex-wrap justify-end gap-2">
                            <span className="bg-gray-200 text-xs px-2 py-1 rounded-full">
                              {awardFileUploadDetails.file.name}
                              <span onClick={() => handleRemoveFile()} className="ml-2 cursor-pointer text-red-500">×</span>
                            </span>
                          </div>
                        )}
                        <hr />
                        <div className="flex sm:flex-row flex-col gap-2 sm:gap-8 justify-between items-baseline">
                          <label htmlFor="Summary" className="text-sm font-medium">{t('Summary')}*</label>
                          <textarea name="summary" onChange={(e) => {
                            setAwardFileUploadDetails({
                              ...awardFileUploadDetails,
                              summary: e.target.value
                            })
                          }} placeholder={t('Add10Keywords')} id="Summary" className={`border rounded-lg w-3/4 text-sm p-2 border-border-grey`} />
                        </div>
                        <hr />
                      </div>
                      <div className='flex flex-col sm:flex-row w-full gap-4'>
                        <button aria-label={t('cancel')} onClick={() => setAwardUploadModal(false)} className=" hover:bg-base-gray w-1/2 border-border-grey border cursor-pointer hover:text-white font-semibold justify-center text-sm text-modal-grey px-12 py-3 rounded-xl">
                          {t('cancel')}
                        </button>
                        <button type="submit" className="hover:bg-document-blue/[0.4] w-1/2 bg-document-blue justify-center text-white flex flex-row gap-2 text-sm py-3 rounded-xl disabled:bg-document-blue/[0.4] px-12" aria-label={t('Proceed')}
                          onClick={(e) => {
                          }}
                        >
                          <p>{t('AddDocument')}</p>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        )
      }
      {/* Conciliator Appointment Modal  */}
      {conciliatorAppointmentModal &&
        (
          <div
            className="fixed z-10 inset-0 flex items-center justify-center "
            id="modal"
          >
            <div className="px-8 sm:px-0 sm:w-2/3">
              <div className="flex justify-center pt-4 pb-20 w-full h-fit">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setConciliatorAppointmentModal(false)}
                >
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                  className="inline-block align-center w-full bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <form>
                    <div className="flex flex-col gap-4 px-8 py-5 overflow-auto">
                      <div className="flex flex-col w-full">
                        <div className="flex justify-between w-full items-center">
                          <p className="font-bold text-highlight-blue sm:text-lg text-sm">
                            {t('ConciliatorAppointmentDetails')}
                          </p>
                          <div className="flex gap-4 items-center">
                            <button
                              type="button"
                              onClick={() => setIsConcilatorEdit(!isConcilatorEdit)}
                              className="text-reports-light-grey text-xs hover:underline"
                            >
                              {isConcilatorEdit ? t('cancel') : t('Edit')}
                            </button>
                            <button
                              type="button"
                              onClick={() => saveConArbDetails()}
                              className="text-reports-light-grey text-xs hover:underline"
                            >
                              {isConcilatorEdit ? t('Save') : ''}
                            </button>
                          </div>
                        </div>
                        <p className="text-xs w-1/2 text-modal-grey">
                          {t('ViewEdit')}
                        </p>
                      </div>
                      <table className="table-auto w-full">
                        <thead>
                          <tr>
                            <th className="p-3 whitespace-nowrap rounded-tl-lg rounded-bl-lg bg-base-gray font-normal text-left text-sm text-label-grey">
                              {t('ConciliatorName')}
                            </th>
                            <th className="p-3 bg-base-gray font-normal text-left text-sm text-label-grey">
                              {t('ConciliatorDateAppointment')}
                            </th>
                            <th className="p-3 bg-base-gray font-normal text-left text-sm text-label-grey">
                              {t('IsApproved')}
                            </th>
                            <th className="p-3 bg-base-gray font-normal text-left text-sm text-label-grey">
                              {t('IsReappointed')}
                            </th>
                            <th className="p-3 bg-base-gray font-normal text-left text-sm text-label-grey">
                              {t('DateOfRejection')}
                            </th>
                            <th className="p-3 bg-base-gray font-normal text-left text-sm text-label-grey">
                              {t('DateOfApproval')}
                            </th>
                            <th className="p-3 rounded-tr-lg rounded-br-lg bg-base-gray font-normal text-left text-sm text-label-grey">
                              {' '}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {conciliatorDetails.map((detail, index) => (
                            <tr key={index}>
                              <td className="border-b p-3 font-normal text-xs">
                                {isConcilatorEdit ? (
                                  <input
                                    type="text"
                                    value={detail.arbitratorName}
                                    onChange={(e) =>
                                      handleInputChange(index, 'arbitratorName', e.target.value)
                                    }
                                    className="text-xs py-3 sm:w-72 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                  />
                                ) : (
                                  <span>{detail.arbitratorName}</span>
                                )}
                              </td>
                              <td className="border-b p-3 font-normal text-xs">
                                {isConcilatorEdit ? (
                                  <input
                                    type="date"
                                    value={detail.arbitratorAppointedDate}
                                    onChange={(e) =>
                                      handleInputChange(index, 'arbitratorAppointedDate', e.target.value)
                                    }
                                    className="text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                  />
                                ) : (
                                  <span>{dateHelper(detail.arbitratorAppointedDate)}</span>
                                )}
                              </td>
                              <td className="border-b p-3 font-normal text-xs">
                                {isConcilatorEdit ? (
                                  <select onChange={(e) =>
                                    handleInputChange(index, 'isApproved', e.target.value)
                                  }
                                    value={detail.isApproved}
                                    aria-label={'IsApproved'} required name='isApproved' id='isApproved'
                                    className="text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                  >
                                    <option selected disabled>{t('Select')}</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                ) : (
                                  <span>{detail.isApproved && detail.isApproved !== null ? (detail.isApproved ? 'Yes' : 'No') : ''}</span>
                                )}
                              </td>
                              <td className="border-b p-3 font-normal text-xs">
                                {isConcilatorEdit && index !== 0 ? (
                                  <select onChange={(e) =>
                                    handleInputChange(index, 'isReappointed', e.target.value)
                                  }
                                    value={detail.isReappointed}
                                    aria-label={'IsReappointed'} required name='isReappointed' id='isReappointed'
                                    className="text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                  >
                                    <option selected disabled>{t('Select')}</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                ) : (
                                  <span>{detail.isReappointed && detail.isReappointed !== null ? (detail.isReappointed ? 'Yes' : 'No') : ''}</span>
                                )}
                              </td>
                              <td className="border-b p-3 font-normal text-xs">
                                {isConcilatorEdit ? (
                                  <input
                                    type="date"
                                    value={detail.arbitratorRejectionDate}
                                    disabled={!(detail.isApproved === 'No')}
                                    min={detail?.arbitratorAppointedDate}
                                    onChange={(e) =>
                                      handleInputChange(index, 'arbitratorRejectionDate', e.target.value)
                                    }
                                    className="text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                  />
                                ) : (
                                  <span>{dateHelper(detail.arbitratorRejectionDate)}</span>
                                )}
                              </td>
                              <td className="border-b p-3 font-normal text-xs">
                                {isConcilatorEdit ? (
                                  <input
                                    type="date"
                                    value={detail.arbitratorAcceptanceDate}
                                    disabled={!(detail.isApproved === 'Yes')}
                                    min={detail?.arbitratorAppointedDate}
                                    onChange={(e) =>
                                      handleInputChange(index, 'arbitratorAcceptanceDate', e.target.value)
                                    }
                                    className="text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                  />
                                ) : (
                                  <span>{dateHelper(detail.arbitratorAcceptanceDate)}</span>
                                )}
                              </td>
                              <td className="border-b p-3 font-normal text-xs">
                                <i className="ph ph-dots-three-vertical"></i>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {isConcilatorEdit && (conciliatorDetails?.[conciliatorDetails.length - 1]?.arbitratorRejectionDate || conciliatorDetails.length === 0) && (
                        <div className="flex w-full justify-end">
                          <button
                            type="button"
                            onClick={handleAddRow}
                            className="text-reports-light-grey text-xs hover:underline"
                          >
                            + {t('AddRow')}
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default ConciliationReportComponent