import { useCallback, useEffect, useState } from 'react'
import ScoresFilingModal from './ScoresFilingModal'
import { useCombinedStore } from '../../../store';
import { useTranslation } from 'react-i18next';
import { Complain, Organisation, ThirdLevelCategory } from '../../../swagger/models';
import { notifyError } from '../../../helpers/helper';
import ClaimValueAlert from './ClaimValueAlert';
import InfoTooltip from '../../../helpers/Tooltip';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import ScoresComplainPreview from './ScoresComplainPreview';


interface ScoresDisputeFilingProps {
  authLetterFile: any
}

function ScoresDisputeFiling({ authLetterFile }: ScoresDisputeFilingProps) {
  const scoresDisputeDetails = useCombinedStore((state: any) => state.scoresDisputeDetails);

  const { t } = useTranslation();
  const [category, setCategory] = useState<string>("")
  const [subCategory, setSubCategory] = useState<string>("")
  const [thirdCategory, setThirdCategory] = useState<string>("")
  const [captcha, setCaptcha] = useState<string>("")
  const [intermediary, setIntermediary] = useState<Organisation>({})

  const [miis, setmiis] = useState<any>([])
  const [selectedMII, setSelectedMII] = useState("")

  const [complaint, setComplaint] = useState<Complain>({})
  const [natureOfComplaint, setNatureOfComplaint] = useState<any>()
  const [selectedNatureOfComplaints, setSelectedNatureOfComplaints] = useState<any>([])

  const [intermediarysearchTerm, setintermediarysearchTerm] = useState('');

  const [modeOfHoldingSecurities, setmodeOfHoldingSecurities] = useState<any>({})
  const [typeOfSecurity, setTypeOfSecurity] = useState<any>("");
  const [complainFilingDetail, setComplaintFilingDetail] = useState<Complain>({})
  const [selectedAttachmentNature, setSelectedAttachmentNature] = useState<any>({
    nature: null,
    natureType: null
  });

  const [previewModal, setPreviewModal] = useState<boolean>(false)
  const [claimValueModal, setClaimValueModal] = useState<boolean>(false)
  const [editClaimValue, setEditClaimValue] = useState<boolean>(false)

  const files: any[] = useCombinedStore((state: any) => state.natureFiles)

  const fetchSubCategoryMaster = useCombinedStore((state: any) => state.fetchSubCategoryMaster);
  const subCategoryMaster = useCombinedStore((state: any) => state.subCategoryMaster);
  const deleteFile = useCombinedStore((state: any) => state.deleteFile);

  const fetchIntermediaries = useCombinedStore((state: any) => state.fetchIntermediaries);
  const [tAndC, setTAndC] = useState(false)

  const uploadFile = useCombinedStore((state: any) => state.uploadFile)

  const MIIsforSB = [
    { organisationName: "Sample MII SB", type: "SB", parentId: 1 },
    { organisationName: "Jupitice MII", type: "SB", parentId: 10 },
    { organisationName: "CADRE MII", type: "SB", parentId: 10 },
    { organisationName: "Webnyay MII", type: "SB", parentId: 10 },
  ]

  const MIIsforDP = [
    { organisationName: "Sample MII DP", type: "DP", parentId: 2 },
    { organisationName: "Jupitice MII", type: "SB", parentId: 10 },
    { organisationName: "CADRE MII", type: "DP", parentId: 10 },
    { organisationName: "Webnyay MII", type: "DP", parentId: 10 },
  ]

  const MIIsforLC = [
    { organisationName: "Sample MII SB", type: "SB", parentId: 1 },
    { organisationName: "Jupitice MII", type: "SB", parentId: 10 },
    { organisationName: "CADRE MII", type: "SB", parentId: 10 },
    { organisationName: "Webnyay MII", type: "SB", parentId: 10 },
  ]

  useEffect(() => {
  }, [files]);

  /**
   * 
   * @param event 
   * @param nature 
   */
  const updateNatureOfComplaint = (event: any, nature: any) => {
    if (event?.target?.checked) {
      if (nature.hasOwnProperty('subNatureType') && nature.subNatureType.length > 0) {
        setSelectedNatureOfComplaints([
          ...selectedNatureOfComplaints, {
            natureType: nature?.natureType,
            subNatureType: nature?.subNatureType,
            selectedSubComplaintType: []
          }
        ])
      } else {
        setSelectedNatureOfComplaints([
          ...selectedNatureOfComplaints, {
            natureType: nature?.natureType,
            subNatureType: "",
            selectedSubComplaintType: [{ natureType: nature.natureType, description: "", subNatureType: "" }]
          }
        ])
      }
    } else {
      const index = complaint?.natureOfComplaint?.findIndex((i: any) => i.natureType === nature?.natureType)
      complaint?.natureOfComplaint?.splice(index, 1)
      setSelectedNatureOfComplaints((prevSelectedNatureOfComplaints: any) =>
        prevSelectedNatureOfComplaints.filter((complaint: any) => complaint.natureType !== nature?.natureType)
      );

    }
  }

  /**
   * 
   * @param event 
   * @param subNature 
   * @param nature 
   */
  const updateSubNatureOfComplaint = (event: any, subNature: any, nature: any) => {
    setSelectedNatureOfComplaints((prevSelectedNatureOfComplaints: any) => {
      const updatedSelectedNatureOfComplaints = prevSelectedNatureOfComplaints?.map((selectedNatureOfComplaint: any) => {
        if (selectedNatureOfComplaint.natureType === nature?.natureType) {
          const updatedSelectedSubComplaintType = event?.target?.checked
            ? [
              ...selectedNatureOfComplaint.selectedSubComplaintType,
              {
                natureType: nature.natureType,
                subNatureType: subNature,
                description: "",
              },
            ]
            : selectedNatureOfComplaint.selectedSubComplaintType.filter(
              (subComplaint: any) => subComplaint.subNatureType !== subNature
            );

          return {
            ...selectedNatureOfComplaint,
            selectedSubComplaintType: updatedSelectedSubComplaintType,
          };
        }
        return selectedNatureOfComplaint;
      });

      return updatedSelectedNatureOfComplaints;
    });
  };

  /**
   * 
   * @param event 
   */
  const setModeOfHolding = (event: any) => {
    if (event.type === 'click') {
      const { id } = event.target
      setmodeOfHoldingSecurities({ mode: id })
    } else {
      const { name, value } = event.target
      setmodeOfHoldingSecurities((prevMode: any) => ({
        ...prevMode,
        [name]: value,
      }));
    }
  }

  const handleSubDescriptionChange = (e: any, natureType: any, nature: any) => {
    if (e.target.value.length > 2) {
      setSelectedNatureOfComplaints((prevComplaints: any) => {
        const updatedComplaints = prevComplaints?.map((complaint: any) => {
          if (complaint.subNatureType.includes(natureType)) {
            return {
              ...complaint,
              selectedSubComplaintType: complaint.selectedSubComplaintType?.map((selected: any) => {
                if (selected.subNatureType === natureType) {
                  return {
                    ...selected,
                    description: e.target.value
                  };
                }
                return selected;
              })
            };
          }
          return complaint;
        });
        return updatedComplaints;
      });
    } else {
      setSelectedNatureOfComplaints((prevComplaints: any) => {
        const updatedComplaints = prevComplaints?.map((complaint: any) => {
          if (complaint.subNatureType.includes(natureType)) {
            return {
              ...complaint,
              selectedSubComplaintType: complaint.selectedSubComplaintType?.map((selected: any) => {
                if (selected.subNatureType === natureType) {
                  return {
                    ...selected,
                    description: null
                  };
                }
                return selected;
              })
            };
          }
          return complaint;
        });
        return updatedComplaints;
      });
    }
  }

  const handleDescriptionChange = (e: any, natureType: any) => {
    setSelectedNatureOfComplaints((prevComplaints: any) => {
      const updatedComplaints = prevComplaints?.map((complaint: any) => {
        return {
          ...complaint,
          selectedSubComplaintType: complaint.selectedSubComplaintType?.map((selected: any) => {
            if (selected.natureType === natureType) {
              return {
                ...selected,
                description: e.target.value
              };
            }
            return selected;
          })
        };
      });
      return updatedComplaints;
    });
  }

  const handleFilesInDescription = (e: any) => {
    const file = e.target.files;
    const formData = new FormData();
    formData.append('file', file);

    let fileSize = 0
    for (const f of file) {
      fileSize += f.size;
    }
    if (fileSize > 20 * 1000 * 1024) {
      notifyError(t('UploadFileExceeds'))
      return false;
    }

    for (let i = 0; i < file.length; i++) {
      uploadFile(e.target.files[i], "", selectedAttachmentNature.natureType, complaint.subCategoryId)
    }
  }

  const handleFilesInSubDescription = (e: any) => {
    const file = e.target.files;
    const formData = new FormData();
    formData.append('file', file);

    let fileSize = 0
    for (const f of file) {
      fileSize += f.size;
    }
    if (fileSize > 20 * 1000 * 1024) {
      notifyError(t('UploadFileExceeds'))
      return false;
    }

    for (let i = 0; i < file.length; i++) {
      uploadFile(e.target.files[i], selectedAttachmentNature.natureType, selectedAttachmentNature.nature, complaint.subCategoryId)
    }
  }

  const fileAComplaint = async (e: any) => {
    e.preventDefault()

    if (complaint.subCategoryId === "") {
      notifyError(t('PleaseSelectCategory'))
      return
    }

    if ((complaint.subCategoryId == "1" || complaint.subCategoryId == "4" || complaint.subCategoryId == "6") && selectedMII === "") {
      notifyError(t('PleaseSelectTrade'))
      return
    }

    if (!intermediary || !intermediary.uniqueSEBIId) {
      notifyError(t('PleaseSelectIntermediary'))
      return
    }

    if (complaint.subCategoryId == "4" && !complainFilingDetail.UCC) {
      notifyError(t('PleaseAddUCC'))
      return
    }

    if (selectedMII === 'National Securities Depository Limited') {
      if (modeOfHoldingSecurities?.clientId?.length !== 8 || modeOfHoldingSecurities?.dpId?.length !== 8) {
        notifyError(t('DematId8'))
        return
      }
    } else if (selectedMII === 'Central Depository Services (India) Limited ') {
      if (modeOfHoldingSecurities?.dpId?.length !== 16) {
        notifyError(t('DematId16'))
        return
      }
    }

    if (authLetterFile) {
      let file = await uploadFile(authLetterFile, "", "", "")
      setComplaint({
        ...complaint,
        authLetterFile: {
          file: file.data.url,
          fileName: file.data.fileName,
          fileType: file.data.contentType,
          uploadedBy: file.data.uploadedBy,
          timestamp: file.data.timestamp,
          uploadedAt: new Date(),
        }
      })
    }

    setComplaint({
      ...complaint,
      typeOfSecurity: typeOfSecurity,
      captchaToken: captcha,
      natureOfComplaint: selectedNatureOfComplaints,
      natureFiles: files,
      modeOfHoldingSecurities: modeOfHoldingSecurities,
      claimAmount: complainFilingDetail?.claimAmount,
      authorizedPersonName: complainFilingDetail?.authorizedPersonName,
      authorizedPersonPAN: complainFilingDetail?.authorizedPersonPAN,
    })
    setPreviewModal(true)
  }

  const removeFile = (timestamp: string) => {
    deleteFile(timestamp)
  }

  const onVerify = useCallback((token: string) => {
    setCaptcha(token)
  }, []);


  const uploadingFile = (natureType: string, nature?: string) => {
    setSelectedAttachmentNature({
      nature,
      natureType
    })
  }

  useEffect(() => {
    if (selectedMII !== '') {
      const parentId = miis?.filter((mii: any) => mii.organisationName === selectedMII)?.[0]?.parentId
      fetchIntermediaries(complaint.subCategoryId, parentId)
    }
  }, [selectedMII])

  const checkClaimAmount = (claimValue: string) => {
    const claimValueNumber = parseFloat(claimValue)
    if (claimValueNumber > 100000000 && !editClaimValue) {
      setClaimValueModal(true)
    } else if (claimValueNumber < 100000000) {
      setClaimValueModal(false)
      setEditClaimValue(false)
    }
  }

  useEffect(() => {
    if (scoresDisputeDetails?.primaryCaseRefId) {
      fetchSubCategoryMaster(scoresDisputeDetails?.subCategoryId)
      setCategory(scoresDisputeDetails?.categoryName)
      setSubCategory(scoresDisputeDetails?.subCategoryName)
      setThirdCategory(scoresDisputeDetails?.thirdCategoryName)
      setComplaint({
        ...complaint,
        categoryId: scoresDisputeDetails?.categoryId,
        subCategoryId: scoresDisputeDetails?.subCategoryId,
        subSubCategoryId: scoresDisputeDetails?.thirdLevelCategoryId,
        miiId: scoresDisputeDetails?.miiDetails?.orgId,
        intermediaryId: scoresDisputeDetails?.respondent?.orgId,
        primaryCaseRefId: scoresDisputeDetails?.primaryCaseRefId,
        primaryCaseRefDate: scoresDisputeDetails?.primaryCaseRefDate,
        natureOfComplaints: scoresDisputeDetails?.natureOfComplaints,
        ComplainTimelines: scoresDisputeDetails?.disputeTimeline,
        mIIName: scoresDisputeDetails?.miiDetails?.organisationName,
        pendingComplaints: scoresDisputeDetails?.pendingComplaints
      })

      if (scoresDisputeDetails?.subCategoryName === "Stock Broker") {
        setmiis(MIIsforSB)
      }
      if (scoresDisputeDetails?.subCategoryName === "Depository Participants") {
        setmiis(MIIsforDP)
      }
      if (scoresDisputeDetails?.subCategoryName === "Listed Companies") {
        setmiis(MIIsforLC)
      }
      setSelectedMII(scoresDisputeDetails?.miiDetails?.organisationName)
      setSelectedNatureOfComplaints([])
      setmodeOfHoldingSecurities({})
      setIntermediary(scoresDisputeDetails?.respondent)
      setintermediarysearchTerm(scoresDisputeDetails?.respondent.organisationName)
    }
    console.log(scoresDisputeDetails)
  }, [scoresDisputeDetails])

  useEffect(() => {
    if (subCategoryMaster && subCategoryMaster.subCategoryId) {
      if (subCategoryMaster.ThirdLevelCategories?.length > 0) {
        const thirdCategory = subCategoryMaster.ThirdLevelCategories.find((thirdCategory: ThirdLevelCategory) => thirdCategory.thirdLevelCategoryId === scoresDisputeDetails?.thirdLevelCategoryId)
        setNatureOfComplaint(thirdCategory?.natureOfComplaint);
      } else {
        setNatureOfComplaint(subCategoryMaster?.natureOfComplaint);
      }
    }
  }, [subCategoryMaster])

  return (
    <div className="pt-7 w-full h-full pr-1 sm:pr-4 pl-1 pb-7">
      {previewModal && (
        <ScoresComplainPreview
          complainObject={complaint}
          previewModal={previewModal}
          setPreviewModal={setPreviewModal}
          category={`${category}/${subCategory}${thirdCategory !== "" ? `/${thirdCategory}` : ""}`}
          natureOfComplainDescription={files}
          intermediary={intermediary}
          selectedNatureOfComplaints={selectedNatureOfComplaints}
          natureOfComplaints={complaint?.natureOfComplaints}
        />
      )}
      <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={true} />

      <div
        style={{ background: "#F5F5F5" }}
        className=" w-full h-full flex-initial  pr-3 rounded-2xl "
      >
        <div className="flex w-full h-full flex-initial overflow-y-scroll overflow-hidden  px-8 py-7 flex-col gap-2 rounded-2xl">
          <p className="hidden sm:block text-xs text-back-gray">{t('FileADispute')}</p>
          <div className="w-full flex flex-row justify-between">
            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <h1 className="sm:text-3xl text-lg font-semibold text-back-gray">
                  {t('SelectIntermediaryType')}
                </h1>
                <p className="text-xs text-back-gray">{category.length !== 0 && category}</p>
                <hr />
              </div>
              <div className="flex flex-col gap-2">
                <h1 className="sm:text-3xl text-lg font-semibold text-back-gray">
                  {t('SelectIntermediary')}
                </h1>
                <p className="text-xs text-back-gray">{subCategory.length !== 0 && subCategory}</p>
                <hr />
              </div>
              {thirdCategory.length !== 0 &&

                <div className="flex flex-col gap-2">
                  <h1 className="sm:text-3xl text-lg font-semibold text-back-gray">
                    {t('SelectCategory')}
                  </h1>
                  <p className="text-xs text-back-gray">{thirdCategory.length !== 0 && thirdCategory}</p>
                  <hr />
                </div>
              }
              {complaint.subCategoryId && complaint.categoryId && Object.keys(subCategory).length !== 0 && Object.keys(category).length !== 0 ? (
                <>
                  <div className='flex flex-col gap-1'>
                    <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue">
                      {t('ScoresComplaintHistory')}
                    </h1>
                    <p className='text-sm text-scores-blue font-semibold'>{t('InformationBelowCannotEdited')}</p>
                  </div>
                  <div className="flex flex-col gap-3">
                    {miis && miis.length > 0 && (
                      <div className="flex flex-col pt-1 gap-2">
                        <div className="flex flex-row gap-2 items-center">
                          <label htmlFor="CompanyName" className="text-xs">{t('DisputedTradeExecutedOn')}</label>
                          <InfoTooltip title={t('SelectStockExchange')} />
                        </div>
                        <select
                          aria-label={t('SelectState')} required name='state' id='state'
                          className="sm:w-1/2 text-xs p-4 border border-back-gray bg-white rounded-xl"
                          disabled
                          value={selectedMII}
                        >
                          {(subCategory === 'Stock Broker' || subCategory === 'Listed Companies') &&
                            <option>{t('SelectExchange')}</option>
                          }
                          {subCategory === 'Depository Participants' &&
                            <option>{t('SelectDepository')}</option>
                          }
                          {miis?.map((org: Organisation) => {
                            return <option key={org.organisationName} value={org.organisationName}>{org.organisationName}</option>
                          })}
                        </select>

                      </div>
                    )}
                    <div className="flex flex-col pt-1 gap-2">
                      <div className="relative w-full">
                        <div className="inline-flex flex-col gap-2 w-full">
                          <div className="flex flex-row gap-2 items-center">
                            <label htmlFor="CompanyName" className="text-xs">{subCategory === 'Depository Participants' ? t('SelectDepositoryParticipant') : t('SelectIntermediary')}</label>
                            <InfoTooltip title={t('EnterSpecificIntermediary')} />
                          </div>
                          <input
                            className="sm:w-1/2 p-2 border border-back-gray text-xs bg-white rounded-xl"
                            type="text"
                            placeholder={t('EnterNameofIntermediary')}
                            value={intermediarysearchTerm}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-xs">{t('NatureOfDisputeRelatedTo')} <span className="text-xs text-highlight-red">*</span></p>
                      <div className="flex flex-col gap-3">
                        {scoresDisputeDetails?.natureOfComplaints && Array.from(scoresDisputeDetails?.natureOfComplaints).length > 0 && Array.from(scoresDisputeDetails?.natureOfComplaints)?.map((nature: any, index: number) => {
                          return (
                            <>
                              {nature &&
                                <div key={index} className="flex flex-row gap-2">
                                  <input type="checkbox" checked={true} disabled />
                                  <label htmlFor={nature?.natureType} className="text-xs">{nature?.natureType}</label>
                                </div>
                              }
                              <div className="flex flex-col gap-2 pl-2">
                                {nature?.subNatureType &&
                                  <>
                                    <div className="flex flex-row gap-2">
                                      <input type="checkbox" checked disabled />
                                      <label htmlFor={nature?.subNatureType} className="text-xs">{nature?.subNatureType}</label>
                                    </div>
                                    <div className="flex flex-col gap-2 pl-2">
                                      <div className="bg-white rounded-xl sm:w-1/2 p-2 flex flex-col gap-2">
                                        <textarea value={nature?.description} placeholder={t('AddDisputeDescription')} rows={3} className="text-xs p-2 border-none"></textarea>
                                        <div className="flex flex-row justify-between">
                                          <div className="grid grid-cols-2  sm:grid-cols-3 sm:gap-1 gap-2">
                                            {nature?.files?.map((file: any, i: any) => {
                                              return (
                                                <div className="flex flex-row gap-2 p-2 border border-border-grey rounded-xl">
                                                  <i className="ph-bold ph-file-arrow-up"></i>
                                                  <p className="sm:text-xs text-[10px]">{file.fileName.length > 10 ? (file.fileName).slice(0, 5) + '...' : file.fileName}</p>
                                                </div>
                                              )
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                }
                                <div className="flex flex-col gap-2 pl-2">
                                  {!nature?.subNatureType &&
                                    <div className="bg-white rounded-xl sm:w-1/2 p-2 flex flex-col gap-2">
                                      <textarea value={nature?.description} placeholder={t('AddDisputeDescription')} rows={3} className="text-xs p-2 border-none"></textarea>
                                      <div className="flex flex-row justify-between">
                                        <div className="grid grid-cols-2  sm:grid-cols-3 sm:gap-1 gap-2">
                                          {nature?.files?.map((file: any, i: any) => {
                                            return (
                                              <div className="flex flex-row gap-2 p-2 border border-border-grey rounded-xl">
                                                <i className="ph-bold ph-file-arrow-up"></i>
                                                <p className="sm:text-xs text-[10px]">{file.fileName.length > 10 ? (file.fileName).slice(0, 5) + '...' : file.fileName}</p>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    </div>}
                                </div>
                              </div>
                            </>
                          )
                        })}

                      </div>

                    </div>
                  </div>
                  <hr />

                </>
              ) :
                (
                  <div className="flex flex-col gap-2">
                    <h1 className="text-3xl font-semibold text-back-gray">
                      {t('AddDisputeDetails')}
                    </h1>
                    <hr />
                  </div>
                )}
              {complaint.subCategoryId && complaint.categoryId && Object.keys(subCategory).length !== 0 && Object.keys(category).length !== 0 ? (
                <>
                  <div className='flex flex-col gap-1'>
                    <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue">
                      {t('AddDisputeDetails')}
                    </h1>
                    <p className='text-sm text-scores-blue font-semibold'>{t('YouAddOriginalComplaint')}</p>
                  </div>
                  <div className="flex flex-col gap-3">
                    {subCategory === 'Stock Broker' &&
                      <>
                        <div className="flex flex-col pt-1 gap-2">
                          <label htmlFor="NameOfAuthorizedPerson" className="text-xs">{t('NameOfAuthorizedPerson')}</label>
                          <input type="text" name="authorizedPersonName" id="authorizedPersonName" value={complainFilingDetail?.authorizedPersonName} onChange={(e) => setComplaintFilingDetail({
                            ...complainFilingDetail,
                            authorizedPersonName: e.target.value
                          })} className="sm:w-1/2 p-2 border text-xs border-back-gray bg-white rounded-xl" />
                        </div>
                        <div className="flex flex-col pt-1 gap-2">
                          <label htmlFor="PANOfAuthorizedPerson" className="text-xs">{t('PANOfAuthorizedPerson')}</label>
                          <input type="text" name="authorizedPersonPAN" id="authorizedPersonPAN" value={complainFilingDetail?.authorizedPersonPAN} onChange={(e) => setComplaintFilingDetail({
                            ...complainFilingDetail,
                            authorizedPersonPAN: e.target.value
                          })} className="sm:w-1/2 p-2 border text-xs border-back-gray bg-white rounded-xl" />
                        </div>
                      </>
                    }
                    <div className="flex flex-col gap-2">
                      <p className="text-xs">{t('NatureOfDisputeRelatedTo')}</p>
                      <div className="flex flex-col gap-3">
                        {natureOfComplaint && Array.from(natureOfComplaint).length > 0 && Array.from(natureOfComplaint)?.map((nature: any, index: number) => {
                          return (
                            <>
                              {nature &&
                                <div key={index} className="flex flex-row gap-2">
                                  <input type="checkbox" onChange={(e) => updateNatureOfComplaint(e, nature)} />
                                  <label htmlFor={nature.natureType} className="text-xs">{nature.natureType}</label>
                                </div>
                              }
                              {selectedNatureOfComplaints?.filter((complaintNature: any) => complaintNature.natureType === nature?.natureType)?.length > 0 && (
                                <div className="flex flex-col gap-2 pl-2">
                                  {selectedNatureOfComplaints[0].subNatureType !== "" && selectedNatureOfComplaints?.filter((complaintNature: any) => complaintNature.natureType === nature?.natureType)?.[0]?.subNatureType?.map((natureType: any, complaintIndex: number) => {
                                    return (
                                      <>
                                        <div key={complaintIndex} className="flex flex-row gap-2">
                                          <input type="checkbox" onChange={(e) => updateSubNatureOfComplaint(e, natureType, nature)} />
                                          <label htmlFor={natureType} className="text-xs">{natureType}</label>
                                        </div>
                                        {selectedNatureOfComplaints?.filter((complaintNature: any) => complaintNature.natureType === nature?.natureType)?.[0]?.selectedSubComplaintType?.filter((selectedSubType: any) => selectedSubType.subNatureType === natureType)?.[0] && (
                                          <div className="flex flex-col gap-2 pl-2">
                                            <div className="bg-white rounded-xl sm:w-1/2 p-2 flex flex-col gap-2">
                                              <textarea onChange={(e) => { handleSubDescriptionChange(e, natureType, nature) }} placeholder={t('AddDisputeDescription')} rows={3} className="text-xs p-2 border-none"></textarea>
                                              <div className="flex flex-row justify-between">
                                                <div className="grid grid-cols-2  sm:grid-cols-3 sm:gap-1 gap-2">
                                                  {files.filter((i: any) => i.natureType === natureType && i.nature === nature.natureType)?.map((file: any, i: any) => {
                                                    return (
                                                      <div className="flex flex-row gap-2 p-2 border border-border-grey rounded-xl">
                                                        <i className="ph-bold ph-file-arrow-up"></i>
                                                        <p className="sm:text-xs text-[10px]">{file.fileName.length > 10 ? (file.fileName).slice(0, 5) + '...' : file.fileName}</p>
                                                        <i className="ph-bold ph-x text-xs" onClick={() => removeFile(file.timestamp)}></i>
                                                      </div>
                                                    )
                                                  })}
                                                </div>
                                                <div className="flex flex-row gap-6 items-end pr-4">
                                                  <label htmlFor='upload' className="cursor-pointer">
                                                    <i onClick={(e) => uploadingFile(natureType, nature.natureType)} className="ph-bold ph-paperclip"></i>
                                                    <input multiple aria-label={t('Choosefile')} type="file" id='upload' style={{ display: 'none' }} accept=".jpeg, .pdf, .png, .jpg, .xlsx, .csv, .mp3, .zip, .mp4, .doc, .docx" onClick={(e) => e.currentTarget.value = ""}
                                                      onChange={(e) => handleFilesInSubDescription(e)} />
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="flex flex-col gap-1 text-[10px] text-back-gray">
                                              <p>{t('AcceptedFormat')}</p>
                                              <p>{t('Note')}: {t('TotalFileSize')}</p>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )
                                  })}

                                  {selectedNatureOfComplaints[0].subNatureType == "" && selectedNatureOfComplaints?.filter((complaintNature: any) => complaintNature.natureType === nature?.natureType)?.[0] && (
                                    <div className="flex flex-col gap-2 pl-2">
                                      <div className="bg-white rounded-xl sm:w-1/2 p-2 flex flex-col gap-2">
                                        <textarea onChange={(e) => { handleDescriptionChange(e, nature.natureType) }} placeholder={t('AddDisputeDescription')} rows={3} className="text-xs p-2 border-none"></textarea>
                                        <div className="flex flex-row justify-between">
                                          <div className="grid grid-cols-2  sm:grid-cols-3 sm:gap-1 gap-2">
                                            {files.filter((i: any) => i.nature === nature.natureType)?.map((file: any, i: any) => {
                                              return (
                                                <div className="flex flex-row gap-2 p-2 border border-border-grey rounded-xl">
                                                  <i className="ph-bold ph-file-arrow-up"></i>
                                                  <p className="sm:text-xs text-[10px]">{file.fileName.length > 10 ? (file.fileName).slice(0, 5) + '...' : file.fileName}</p>
                                                  <i className="ph-bold ph-x text-xs" onClick={() => removeFile(file.timestamp)}></i>
                                                </div>
                                              )
                                            })}
                                          </div>
                                          <div className="flex flex-row gap-6 items-end pr-4">
                                            <label htmlFor='upload' className="cursor-pointer">
                                              <i onClick={(e) => uploadingFile(nature.natureType)} className="ph-bold ph-paperclip"></i>
                                              <input multiple aria-label={t('Choosefile')} type="file" id='upload' style={{ display: 'none' }} name={nature.natureType} onClick={(e) => e.currentTarget.value = ""}
                                                accept=".jpeg, .pdf, .png, .jpg, .xlsx, .csv, .mp3, .zip, .mp4, .doc, .docx" onChange={(e) => handleFilesInDescription(e)} />
                                            </label>
                                          </div>
                                        </div>
                                        <div className="flex flex-col gap-1 text-[10px] text-back-gray">
                                          <p>{t('AcceptedFormat')}</p>
                                          <p>{t('Note')}: {t('TotalFileSize')}</p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          )
                        })}

                      </div>

                    </div>
                    <div className="flex flex-col pt-1 gap-2">
                      <label htmlFor="CompanyName" className="text-xs">{t('TypeofSecurities')}</label>
                      <input type="text" onChange={(e) => { setTypeOfSecurity(e.target.value) }} className="sm:w-1/2 p-2 border text-xs border-back-gray bg-white rounded-xl" />
                    </div>
                    <div className="flex flex-col pt-1 gap-2">
                      <label htmlFor="CompanyName" className="text-xs">{t('ModeofHoldingSecurities')}</label>
                      <div className="flex flex-col gap-3">
                        <div className="flex flex-row gap-2">
                          <input type="radio" name="mode" id="Physical" value={complaint?.modeOfHoldingSecurities?.mode} onClick={(e) => setModeOfHolding(e)} />
                          <label htmlFor="Physical" className="text-xs">{t('Physical')}</label>
                        </div>
                        {
                          modeOfHoldingSecurities?.mode === "Physical" &&
                          <div className="flex flex-col gap-2 pl-2">
                            <div className="flex flex-col pt-1 gap-2">
                              <label htmlFor="Folio No." className="text-xs">{t('FolioNo')}</label>
                              <input type="text" name="folioNo" id="folioNo" value={complaint?.modeOfHoldingSecurities?.folioNo} onChange={(e) => setModeOfHolding(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                            </div>
                            <div className="flex flex-col pt-1 gap-2">
                              <label htmlFor="Distinctive Nos." className="text-xs">{t('DistinctiveNo')}</label>
                              <input type="text" name="distinctiveNo" id="distinctiveNo" value={complaint?.modeOfHoldingSecurities?.distinctiveNo} onChange={(e) => setModeOfHolding(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                            </div>
                            <div className="flex flex-col pt-1 gap-2">
                              <label htmlFor="Certificate No." className="text-xs">{t("CertificateNo")}</label>
                              <input type="text" name="certificateNo" id="certificateNo" value={complaint?.modeOfHoldingSecurities?.certificateNo} onChange={(e) => setModeOfHolding(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                            </div>
                          </div>
                        }
                        <div className="flex flex-row gap-2">
                          <input type="radio" name="mode" id="Demat" value={complaint?.modeOfHoldingSecurities?.mode} onClick={(e) => setModeOfHolding(e)} />
                          <label htmlFor="Demat" className="text-xs">{t('Demat')}</label>
                        </div>
                        {modeOfHoldingSecurities?.mode === "Demat" &&
                          <div className="flex flex-col gap-2 pl-2">
                            <div className="flex flex-col pt-1 gap-2">
                              <label htmlFor="DP ID" className="text-xs">{t('DPID')}</label>
                              <input type="text" name="dpId" pattern={selectedMII === "Dummy MII DP" ? '\d{8}' : '\d{16}'} id="dpId" value={complaint?.modeOfHoldingSecurities?.dpId} onChange={(e) => setModeOfHolding(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                            </div>
                            <div className="flex flex-col pt-1 gap-2">
                              <label htmlFor="Client ID" className="text-xs">{t('ClientID')}</label>
                              <input type="text" pattern={selectedMII === "Dummy MII DP" ? '\d{8}' : '\d{16}'} name="clientId" id="clientId" value={complaint?.modeOfHoldingSecurities?.clientId} onChange={(e) => setModeOfHolding(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                            </div>
                          </div>
                        }

                      </div>
                    </div>
                    <div className="flex flex-col pt-1 gap-2">
                      <label htmlFor="claimAmount" className="text-xs">{t('ClaimAmount')}<span className="text-highlight-red">*</span> ({t('Input0')})</label>
                      <input required type="number" value={complainFilingDetail?.claimAmount} step="0.001" onChange={(e) => {
                        checkClaimAmount(e.target.value);
                          setComplaintFilingDetail({
                            ...complainFilingDetail,
                            claimAmount: e.target.value
                          })
                      }} className="sm:w-1/2 p-2 border text-xs border-back-gray bg-white rounded-xl" />
                    </div>
                    {subCategory === 'Stock Broker' &&
                      <div className="flex flex-col pt-1 gap-2">
                        <label htmlFor="InvestorAddress" className="text-xs">{t('EnterUCCNumber')}<span className="text-highlight-red">*</span></label>
                        <input type="text" required name="UCC" id="UCC" value={complainFilingDetail?.UCC} onChange={(e) => setComplaintFilingDetail({
                          ...complainFilingDetail,
                          UCC: e.target.value
                        })} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                      </div>
                    }
                  </div>
                  <hr />
                  <div className="flex flex-row gap-2">
                    <input type="checkbox" checked={tAndC} onChange={() => setTAndC(!tAndC)} />
                    <label className="text-xs">{t('IConfirmDispute')} <span className="text-xs text-highlight-red">*</span></label>
                  </div>
                </>
              ) :
                (
                  <div className="flex flex-col gap-2">
                    <h1 className="text-3xl font-semibold text-back-gray">
                      {t('AddDisputeDetails')}
                    </h1>
                    <hr />
                  </div>
                )}

              <div className="flex flex-row gap-3 justify-end">
                <button aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2 rounded-lg">
                  {t('cancel')}
                </button>
                <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2 rounded-lg disabled:bg-highlight-blue/[0.4]" disabled={!tAndC} aria-label={t('Proceed')}
                  onClick={(e) => { fileAComplaint(e) }}
                >
                  <p>{t('Proceed')}</p>
                </button>
              </div>
            </div>

          </div>
          {claimValueModal &&
            <ClaimValueAlert setClaimValueModal={setClaimValueModal} claimValueModal={claimValueModal} setEditClaimValue={setEditClaimValue} ></ClaimValueAlert>
          }
        </div>
      </div>

    </div>
  )
}

export default ScoresDisputeFiling