import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { changePasswordValidationSchema } from '../../helpers/validation'
import { useCombinedStore } from '../../store'
import { ChangePassword1 } from '../../swagger/models'
import { encryptString } from '../common-module/helper'
import { useTranslation } from 'react-i18next';

/**
 * ChangePassword component allows users to change their password by filling out a form.
 * It includes input fields for the new password and confirmation, validates inputs against a schema,
 * encrypts the data before submission, and navigates the user upon successful password update.
 *
 * @returns A JSX element that renders the change password form.
 */
function ChangePassword() {
  // Get the translation function from the i18n library
  const { t } = useTranslation();

  // Initialize the state for the form
  const [changePasswordObject, setChangePasswordObject] = useState<ChangePassword1>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    token: ''
  });

  // Initialize the state for the encrypted password
  const [encryptedPassword, setEncryptedPassword] = useState<ChangePassword1>({})

  // Get the parameters from the URL
  const params = useParams();
  const searchParams = new URLSearchParams(location.search);
  const token: string = params.token! // Get the token from the URL
  const role = searchParams.get('role'); // Get the role from the URL

  // Navigate the user to a different page
  const navigate = useNavigate();

  // Get the functions and state from the store
  const openChangePassowrd = useCombinedStore((state: any) => state.openChangePassowrd);
  const passwordUpdated = useCombinedStore((state: any) => state.passwordUpdated);

  // Define the form options
  const formOptions = {
    resolver: yupResolver(changePasswordValidationSchema), // Validate the form using the schema
  };

  // Initialize the form
  const { register, handleSubmit, formState } = useForm(formOptions);

  // Get the errors from the form state
  const { errors } = formState;

  // Handle the input change
  const onInputChange = (e: any) => {
    const { name, value } = e.target;

    // Update the state
    setChangePasswordObject({
      ...changePasswordObject,
      [name]: value,
    });

    // Encrypt the password
    setEncryptedPassword({
      ...encryptedPassword,
      [name]: encryptString(value),
    });
  };

  // Handle the form submission
  async function onSubmit(e: any) {
    // Call the function from the store
    openChangePassowrd(encryptedPassword);

    // Prevent the default form submission
    return false;
  }

  // Run this code when the component mounts
  useEffect(() => {
    // Update the state
    setChangePasswordObject({
      ...changePasswordObject,
      token: token
    });

    // Encrypt the password
    setEncryptedPassword({
      ...encryptedPassword,
      token: token
    });
  }, [token]);

  // Run this code when the password is updated
  useEffect(() => {
    // Navigate the user to a different page
    if (passwordUpdated) {
      navigate(`/${role?.toLowerCase()}/login`);
    }
  }, [passwordUpdated]);

  // Return the JSX
  return (
    <div className="flex flex-row justify-between pt-7 px-10 lg:pl-32 pb-7 w-full h-screen">
      <div className="flex flex-col h-full w-full lg:w-1/2 pt-10 sm:pt-20 justify-between">
        <div>
          <div className='flex flex-row justify-between'>
            <NavLink to="/login">
              <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '175px', height: 'fit' }}/>
            </NavLink>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-2 mt-5'>
            <label htmlFor="password" className="text-xs">{t('NewPassword')}</label>
            <input aria-label={t('NewPassword')} {...register('newPassword')} type='password' id='newPassword' onChange={onInputChange} className={`rounded-lg border p-2 border-solid ${errors.newPassword?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
            <span className="text-xs text-highlight-red">{errors.newPassword?.message}</span>
            <label htmlFor="password" className="text-xs">{t('ConfirmPassword')}</label>
            <input aria-label={t('ConfirmPassword')} {...register('confirmPassword')} type='password' id='confirmPassword' onChange={onInputChange} className={`rounded-lg border p-2 border-solid ${errors.confirmPassword?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
            <span className="text-xs text-highlight-red">{errors.confirmPassword?.message}</span>
            <button aria-label={t('ResetPassword')} type='submit' className='bg-highlight-blue hover:bg-highlight-blue/[0.8] mt-4 text-white text-sm py-4 px-28 rounded-lg'>
              {t('ResetPassword')}
            </button>
          </form>
        </div>
      </div>
      <div className='hidden lg:flex w-1/2 justify-end'>
        <div className='hidden lg:block h-full mb-8 pl-8 py-8 top-0 right-0 rounded-2xl'>
          <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/HeroIMAGESMARTODR.png"
            className='w-fit overflow-hidden h-full rounded-xl' alt="hero" />
        </div>
      </div>
    </div>
  )
}

export default ChangePassword