import { useTranslation } from 'react-i18next';

/**
 * UnderMaintenance is a functional component that displays a maintenance page.
 * It features the SMARTODR logo, a message indicating that the platform is under maintenance,
 * and an embedded animated spinning gears graphic. The component utilizes the `useTranslation` 
 * hook for internationalization support.
 */
function UnderMaintenance() {
    const { t } = useTranslation();
    return (
        <div className="pt-7 w-full h-screen pr-1 sm:px-4 pl-1 pb-7">
            <div
                style={{ background: "#F4F4F4" }}
                className="flex w-full h-full flex-initial justify-center items-center  px-8 py-7 flex-col gap-2 rounded-2xl"
            >
                <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '250px', height: 'fit' }} />
                <p className="text-sm text-label-grey pt-4">{t('PlatformUnderMaintenance')} </p>
                <p className="text-sm text-label-grey pb-4">{t('WeWillbebackShortly')}</p>
                <iframe width="400" height="400" src="https://rive.app/community/2359-4685-spinning-gears/embed"></iframe>

            </div>

        </div>
    )
}

export default UnderMaintenance