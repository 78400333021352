/* tslint:disable */
/* eslint-disable */
/**
 * OCP Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ChangePassword } from '../models';
import { ChangePassword1 } from '../models';
import { ChangePassword2 } from '../models';
import { ChangePassword3 } from '../models';
import { Enabled } from '../models';
import { ForgotPasswordParams } from '../models';
import { IntermediaryLogin } from '../models';
import { LoginRequest } from '../models';
import { LoginRequest1 } from '../models';
import { Organisation } from '../models';
import { ResendOTP } from '../models';
import { SignedUrl } from '../models';
import { User } from '../models';
import { UserOrUserParent } from '../models';
/**
 * OpenApi - axios parameter creator
 * @export
 */
export const OpenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of all the intermedaries
         * @param {string} organisationName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allIntermediaries: async (organisationName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationName' is not null or undefined
            if (organisationName === null || organisationName === undefined) {
                throw new RequiredError('organisationName','Required parameter organisationName was null or undefined when calling allIntermediaries.');
            }
            const localVarPath = `/open/all_intermediaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (organisationName !== undefined) {
                localVarQueryParameter['organisationName'] = organisationName;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Send forgot password link to the user.
         * @summary change user's password link
         * @param {ForgotPasswordParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (body: ForgotPasswordParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling forgotPassword.');
            }
            const localVarPath = `/open/forgot_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Login for Intermediary
         * @summary Login API for user
         * @param {IntermediaryLogin} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        intermediaryLogin: async (body: IntermediaryLogin, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling intermediaryLogin.');
            }
            const localVarPath = `/open/intermediary_login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Set new account password of the user.
         * @summary Set new account user's password
         * @param {ChangePassword2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        intermediaryNewAccountPassword: async (body: ChangePassword2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling intermediaryNewAccountPassword.');
            }
            const localVarPath = `/open/intermediary_new_account_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the investors
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorProfile: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling investorProfile.');
            }
            const localVarPath = `/open/investor_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the MIIs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMiis: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/list_miis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Login for Admin
         * @summary Login API for user
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (body: LoginRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling login.');
            }
            const localVarPath = `/open/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create the details of specific organisation
         * @param {Organisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketParticipantReRegistration: async (body: Organisation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling marketParticipantReRegistration.');
            }
            const localVarPath = `/open/market_participant_re_registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create the details of specific organisation
         * @param {Organisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketParticipantRegistration: async (body: Organisation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling marketParticipantRegistration.');
            }
            const localVarPath = `/open/market_participant_registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Set new account password of the user.
         * @summary Set new account user's password
         * @param {ChangePassword1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAccountPassword: async (body: ChangePassword1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling newAccountPassword.');
            }
            const localVarPath = `/open/new_account_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Set new account password of the user.
         * @summary Set new account user's password
         * @param {ChangePassword3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newInvestorAccountPassword: async (body: ChangePassword3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling newInvestorAccountPassword.');
            }
            const localVarPath = `/open/new_investor_account_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Registration for Investor
         * @summary Registration API for investor
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (body: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling register.');
            }
            const localVarPath = `/open/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Resend OTP to the user.
         * @summary Resend OTP to the user.
         * @param {ResendOTP} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendOtp: async (body: ResendOTP, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling resendOtp.');
            }
            const localVarPath = `/open/resend_otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset password of the user.
         * @summary reset user's password
         * @param {ChangePassword} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (body: ChangePassword, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling resetPassword.');
            }
            const localVarPath = `/open/reset_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Send forgot password link to the user.
         * @summary change user's password link
         * @param {LoginRequest1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOtp: async (body: LoginRequest1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendOtp.');
            }
            const localVarPath = `/open/send_otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Send verification link to the intermediary user.
         * @param {number} [orgId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerIntermediaryVerification: async (orgId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/trigger_intermediary_verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Send verification link to the intermediary user.
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerInvestorVerification: async (userId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/trigger_investor_verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify Email Address of Investor
         * @summary Verify Email Address of Investor
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling verifyEmail.');
            }
            const localVarPath = `/open/verify_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create the details of specific organisation
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMpRegistrationLink: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling verifyMpRegistrationLink.');
            }
            const localVarPath = `/open/verify_mp_registration_link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpenApi - functional programming interface
 * @export
 */
export const OpenApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of all the intermedaries
         * @param {string} organisationName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allIntermediaries(organisationName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Organisation>>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).allIntermediaries(organisationName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Send forgot password link to the user.
         * @summary change user's password link
         * @param {ForgotPasswordParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(body: ForgotPasswordParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).forgotPassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Login for Intermediary
         * @summary Login API for user
         * @param {IntermediaryLogin} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async intermediaryLogin(body: IntermediaryLogin, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOrUserParent>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).intermediaryLogin(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Set new account password of the user.
         * @summary Set new account user's password
         * @param {ChangePassword2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async intermediaryNewAccountPassword(body: ChangePassword2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).intermediaryNewAccountPassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the investors
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investorProfile(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).investorProfile(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the MIIs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMiis(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Organisation>>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).listMiis(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Login for Admin
         * @summary Login API for user
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(body: LoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).login(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create the details of specific organisation
         * @param {Organisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketParticipantReRegistration(body: Organisation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).marketParticipantReRegistration(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create the details of specific organisation
         * @param {Organisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketParticipantRegistration(body: Organisation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).marketParticipantRegistration(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Set new account password of the user.
         * @summary Set new account user's password
         * @param {ChangePassword1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newAccountPassword(body: ChangePassword1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).newAccountPassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Set new account password of the user.
         * @summary Set new account user's password
         * @param {ChangePassword3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newInvestorAccountPassword(body: ChangePassword3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrl>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).newInvestorAccountPassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Registration for Investor
         * @summary Registration API for investor
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(body: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).register(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Resend OTP to the user.
         * @summary Resend OTP to the user.
         * @param {ResendOTP} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendOtp(body: ResendOTP, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Enabled>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).resendOtp(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Reset password of the user.
         * @summary reset user's password
         * @param {ChangePassword} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(body: ChangePassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).resetPassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Send forgot password link to the user.
         * @summary change user's password link
         * @param {LoginRequest1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendOtp(body: LoginRequest1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Enabled>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).sendOtp(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Send verification link to the intermediary user.
         * @param {number} [orgId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerIntermediaryVerification(orgId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).triggerIntermediaryVerification(orgId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Send verification link to the intermediary user.
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerInvestorVerification(userId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).triggerInvestorVerification(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Verify Email Address of Investor
         * @summary Verify Email Address of Investor
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmail(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).verifyEmail(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create the details of specific organisation
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyMpRegistrationLink(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).verifyMpRegistrationLink(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OpenApi - factory interface
 * @export
 */
export const OpenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary List of all the intermedaries
         * @param {string} organisationName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allIntermediaries(organisationName: string, options?: any): AxiosPromise<Array<Organisation>> {
            return OpenApiFp(configuration).allIntermediaries(organisationName, options).then((request) => request(axios, basePath));
        },
        /**
         * Send forgot password link to the user.
         * @summary change user's password link
         * @param {ForgotPasswordParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(body: ForgotPasswordParams, options?: any): AxiosPromise<void> {
            return OpenApiFp(configuration).forgotPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Login for Intermediary
         * @summary Login API for user
         * @param {IntermediaryLogin} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        intermediaryLogin(body: IntermediaryLogin, options?: any): AxiosPromise<UserOrUserParent> {
            return OpenApiFp(configuration).intermediaryLogin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Set new account password of the user.
         * @summary Set new account user's password
         * @param {ChangePassword2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        intermediaryNewAccountPassword(body: ChangePassword2, options?: any): AxiosPromise<void> {
            return OpenApiFp(configuration).intermediaryNewAccountPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the investors
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorProfile(email: string, options?: any): AxiosPromise<Array<User>> {
            return OpenApiFp(configuration).investorProfile(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the MIIs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMiis(options?: any): AxiosPromise<Array<Organisation>> {
            return OpenApiFp(configuration).listMiis(options).then((request) => request(axios, basePath));
        },
        /**
         * Login for Admin
         * @summary Login API for user
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: LoginRequest, options?: any): AxiosPromise<User> {
            return OpenApiFp(configuration).login(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create the details of specific organisation
         * @param {Organisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketParticipantReRegistration(body: Organisation, options?: any): AxiosPromise<Organisation> {
            return OpenApiFp(configuration).marketParticipantReRegistration(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create the details of specific organisation
         * @param {Organisation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketParticipantRegistration(body: Organisation, options?: any): AxiosPromise<Organisation> {
            return OpenApiFp(configuration).marketParticipantRegistration(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Set new account password of the user.
         * @summary Set new account user's password
         * @param {ChangePassword1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAccountPassword(body: ChangePassword1, options?: any): AxiosPromise<void> {
            return OpenApiFp(configuration).newAccountPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Set new account password of the user.
         * @summary Set new account user's password
         * @param {ChangePassword3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newInvestorAccountPassword(body: ChangePassword3, options?: any): AxiosPromise<SignedUrl> {
            return OpenApiFp(configuration).newInvestorAccountPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Registration for Investor
         * @summary Registration API for investor
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(body: User, options?: any): AxiosPromise<User> {
            return OpenApiFp(configuration).register(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Resend OTP to the user.
         * @summary Resend OTP to the user.
         * @param {ResendOTP} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendOtp(body: ResendOTP, options?: any): AxiosPromise<Enabled> {
            return OpenApiFp(configuration).resendOtp(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Reset password of the user.
         * @summary reset user's password
         * @param {ChangePassword} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(body: ChangePassword, options?: any): AxiosPromise<void> {
            return OpenApiFp(configuration).resetPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Send forgot password link to the user.
         * @summary change user's password link
         * @param {LoginRequest1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOtp(body: LoginRequest1, options?: any): AxiosPromise<Enabled> {
            return OpenApiFp(configuration).sendOtp(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Send verification link to the intermediary user.
         * @param {number} [orgId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerIntermediaryVerification(orgId?: number, options?: any): AxiosPromise<void> {
            return OpenApiFp(configuration).triggerIntermediaryVerification(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * Send verification link to the intermediary user.
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerInvestorVerification(userId?: number, options?: any): AxiosPromise<void> {
            return OpenApiFp(configuration).triggerInvestorVerification(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify Email Address of Investor
         * @summary Verify Email Address of Investor
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail(token: string, options?: any): AxiosPromise<void> {
            return OpenApiFp(configuration).verifyEmail(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create the details of specific organisation
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMpRegistrationLink(token: string, options?: any): AxiosPromise<Organisation> {
            return OpenApiFp(configuration).verifyMpRegistrationLink(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OpenApi - object-oriented interface
 * @export
 * @class OpenApi
 * @extends {BaseAPI}
 */
export class OpenApi extends BaseAPI {
    /**
     * 
     * @summary List of all the intermedaries
     * @param {string} organisationName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public allIntermediaries(organisationName: string, options?: any) {
        return OpenApiFp(this.configuration).allIntermediaries(organisationName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Send forgot password link to the user.
     * @summary change user's password link
     * @param {ForgotPasswordParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public forgotPassword(body: ForgotPasswordParams, options?: any) {
        return OpenApiFp(this.configuration).forgotPassword(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Login for Intermediary
     * @summary Login API for user
     * @param {IntermediaryLogin} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public intermediaryLogin(body: IntermediaryLogin, options?: any) {
        return OpenApiFp(this.configuration).intermediaryLogin(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Set new account password of the user.
     * @summary Set new account user's password
     * @param {ChangePassword2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public intermediaryNewAccountPassword(body: ChangePassword2, options?: any) {
        return OpenApiFp(this.configuration).intermediaryNewAccountPassword(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the investors
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public investorProfile(email: string, options?: any) {
        return OpenApiFp(this.configuration).investorProfile(email, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the MIIs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public listMiis(options?: any) {
        return OpenApiFp(this.configuration).listMiis(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Login for Admin
     * @summary Login API for user
     * @param {LoginRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public login(body: LoginRequest, options?: any) {
        return OpenApiFp(this.configuration).login(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create the details of specific organisation
     * @param {Organisation} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public marketParticipantReRegistration(body: Organisation, options?: any) {
        return OpenApiFp(this.configuration).marketParticipantReRegistration(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create the details of specific organisation
     * @param {Organisation} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public marketParticipantRegistration(body: Organisation, options?: any) {
        return OpenApiFp(this.configuration).marketParticipantRegistration(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Set new account password of the user.
     * @summary Set new account user's password
     * @param {ChangePassword1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public newAccountPassword(body: ChangePassword1, options?: any) {
        return OpenApiFp(this.configuration).newAccountPassword(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Set new account password of the user.
     * @summary Set new account user's password
     * @param {ChangePassword3} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public newInvestorAccountPassword(body: ChangePassword3, options?: any) {
        return OpenApiFp(this.configuration).newInvestorAccountPassword(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Registration for Investor
     * @summary Registration API for investor
     * @param {User} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public register(body: User, options?: any) {
        return OpenApiFp(this.configuration).register(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Resend OTP to the user.
     * @summary Resend OTP to the user.
     * @param {ResendOTP} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public resendOtp(body: ResendOTP, options?: any) {
        return OpenApiFp(this.configuration).resendOtp(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Reset password of the user.
     * @summary reset user's password
     * @param {ChangePassword} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public resetPassword(body: ChangePassword, options?: any) {
        return OpenApiFp(this.configuration).resetPassword(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Send forgot password link to the user.
     * @summary change user's password link
     * @param {LoginRequest1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public sendOtp(body: LoginRequest1, options?: any) {
        return OpenApiFp(this.configuration).sendOtp(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Send verification link to the intermediary user.
     * @param {number} [orgId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public triggerIntermediaryVerification(orgId?: number, options?: any) {
        return OpenApiFp(this.configuration).triggerIntermediaryVerification(orgId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Send verification link to the intermediary user.
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public triggerInvestorVerification(userId?: number, options?: any) {
        return OpenApiFp(this.configuration).triggerInvestorVerification(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Verify Email Address of Investor
     * @summary Verify Email Address of Investor
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public verifyEmail(token: string, options?: any) {
        return OpenApiFp(this.configuration).verifyEmail(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create the details of specific organisation
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public verifyMpRegistrationLink(token: string, options?: any) {
        return OpenApiFp(this.configuration).verifyMpRegistrationLink(token, options).then((request) => request(this.axios, this.basePath));
    }
}
