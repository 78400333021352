import { useState } from "react";
import { notifyError } from '../../../helpers/helper'
import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useCombinedStore } from "../../../store";

    /**
     * TransferDisputeModal component displays a modal to transfer a dispute to another MII.
     *
     * The modal includes a "Transfer" button which triggers the handleStatusChange callback with
     * 'AWAITING_MII_REVIEW' status and is enabled only if the terms and conditions are accepted.
     * There is also a cancel button which closes the modal without proceeding.
     *
     * Props:
     * - setTransferDispute: Function to control the visibility of the modal.
     * - handleStatusChange: Callback function executed upon clicking the "Transfer" button.
     * - complainId: The id of the dispute to be transferred.
     * - miis: An array of all the MII objects.
     */
const TransferDisputeModal = ({ setTransferDispute, handleStatusChange, complainId, miis }: any) => {

    const navigate = useNavigate()
    const { t } = useTranslation();

    const MIITransfer = useCombinedStore((state: any) => state.transferMIIs)

    const [isMiiOpen, setIsMiiOpen] = useState(false)
    const [selectedMii, setSelectedMii] = useState(Object)

    const handleMiiReassignment = async () => {
        if (selectedMii === null) {
            notifyError(t('PleaseSelectCategory'))
            return
        }

        const response = await MIITransfer(complainId, selectedMii.orgId)
        if (response.success) {
            redirect()
        } else {
            notifyError(t('SomeErrorOccured'))

        }
    }

    const redirect = () => {
        navigate(`/mii/dashboard`)
    }


    return (
        <div className="fixed z-10 inset-0 flex items-center justify-center " id="modal">
            <div className="px-8 sm:px-0 sm:w-1/3">
                <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
                    <div
                        className="fixed inset-0 transition-opacity"
                        onClick={() => setTransferDispute(false)}
                    >
                        <div className="absolute inset-0 bg-gray-900 opacity-75" />
                    </div>
                    <div
                        className="inline-block align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        {/* <form > */}
                        <div className="flex flex-col gap-1 px-8 pb-5">
                            <h1 className='font-semibold sm:text-2xl text-lg pt-3'>Transfer Dispute to another MII?</h1>
                            <div className='flex flex-col gap-4 pb-3 pt-4 text-label-grey'>
                                <p className="text-xs text-label-grey">If this dispute has been wrongly assigned to {"mii"}, you can transfer the dispute to the correct MII by clicking the button below. Please note that this action is not reversible.</p>
                                <div className="w-1/2 relative">
                                    <button
                                        type="button"
                                        className={`inline-flex cursor-default items-center justify-center w-full px-8 py-3 text-xs text-label-grey bg-light-blue rounded-lg`}
                                        id="status-button"
                                        onClick={() => {
                                            setIsMiiOpen(!isMiiOpen)
                                        }}
                                    >
                                        <p>Dispute Actions</p>
                                    </button>
                                    {isMiiOpen && (
                                        <div
                                            className="origin-top-right absolute left-0 mt-2 w-full rounded-lg bg-EBEBEB"
                                            role="menu"
                                            aria-orientation="vertical"
                                            aria-labelledby="dropdown-menu-button"
                                        >
                                            <div className="py-1" role="none">
                                                {miis &&
                                                    miis.map((mii: any, key: any) => (
                                                        <div
                                                            className="flex flex-row gap-2 py-2 whitespace-nowrap px-3 text-xs rounded-lg cursor-pointer items-center hover:bg-white"
                                                            onClick={(e: any) => {
                                                                setIsMiiOpen(false);
                                                                setSelectedMii(mii);
                                                            }}
                                                        >
                                                            <p>{mii.organisationName}</p>
                                                        </div>
                                                    ))}


                                            </div>
                                        </div>
                                    )}
                                </div>
                                <p className="text-xs font-bold">{selectedMii.organisationName}</p>
                                <p className="text-xs">The Dispute will be transferred to {selectedMii.organisationName} ({selectedMii.uniqueSEBIId}).The new dispute ID will be {complainId}-{selectedMii.uniqueSEBIId}-TR.</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>
                                <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12" aria-label={t('Proceed')}
                                    onClick={(e: any) => handleMiiReassignment()}
                                >
                                    <p>{t('Proceed')}</p>
                                </button>
                                <button aria-label={t('cancel')} onClick={() => setTransferDispute(false)} className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                                    {t('cancel')}
                                </button>
                            </div>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>

        </div>
    );
};


export default TransferDisputeModal;