import { Fragment, useCallback, useEffect, useState } from "react"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"
import { useCombinedStore } from "../../../store"
import { CategoryMaster, Complain, Organisation, SubCategoryMaster, ThirdLevelCategory } from "../../../swagger/models"
import IntermediaryComplaintPreview from "./IntermediaryComplaintPreview"
import AgainstInvestorPreview from "./againstInvestorPreview"
import { useTranslation } from 'react-i18next';
import InfoTooltip from "../../../helpers/Tooltip"
import { notifyError } from "../../../helpers/helper"
import { CityType, StateType } from "../../common-module/helper"
import ClaimValueAlert from "./ClaimValueAlert"

function IntermediaryComplaintFiling() {
  const { t } = useTranslation();

  const defaultMIIS = [
    { organisationName: "Sample MII SB", type: "INV" },
    { organisationName: "Jupitice MII", type: "INV" },
    { organisationName: "Sample MII DP", type: "INV" },
  ]

  const [category, setCategory] = useState<string>("")

  const [investorDetails, setInvestorDetails] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
    panNumber: "",
    address: "",
    UCC: "",
    city: "",
    state: "",
  })
  const [complainBrief, setComplainBrief] = useState("")
  const [categoryId, setCategoryId] = useState<string>("")
  const [subCategory, setSubCategory] = useState<string>("")
  const [subCategoryId, setsubCategoryId] = useState<string>("")
  const [thirdCategory, setThirdCategory] = useState<string>("")
  const [captcha, setCaptcha] = useState<string>("")
  const [subSubCategoryId, setsubSubCategoryId] = useState<string>("")
  const [intermediary, setIntermediary] = useState<Organisation>({})


  const [miis, setmiis] = useState<any>(defaultMIIS)
  const [selectedMII, setSelectedMII] = useState("")
  const [againstInvestorModal, setAgainstInvestorModal] = useState(false)
  const [complaint, setComplaint] = useState<Complain>({})
  const [natureOfComplaint, setNatureOfComplaint] = useState<any>()
  const [selectedNatureOfComplaints, setSelectedNatureOfComplaints] = useState<any>([])

  const [intermediarysearchTerm, setintermediarysearchTerm] = useState('');
  const [filteredIntermediaries, setfilteredIntermediaries] = useState([]);


  const [modeOfHoldingSecurities, setmodeOfHoldingSecurities] = useState<any>({})
  const [typeOfSecurity, setTypeOfSecurity] = useState<any>("");
  const [complainFilingDetail, setComplaintFilingDetail] = useState<Complain>({})
  const [selectedAttachmentNature, setSelectedAttachmentNature] = useState<any>({
    nature: null,
    natureType: null
  });

  const [previewModal, setPreviewModal] = useState<any>(false)
  const [claimValueModal, setClaimValueModal] = useState<boolean>(false)
  const [editClaimValue, setEditClaimValue] = useState<boolean>(false)

  const [toggleCategory, setToggleCategory] = useState(true)
  const [toggleSubCategory, setToggleSubCategory] = useState(true)
  const [toggleSubSubCategory, setToggleSubSubCategory] = useState(true)

  const files: any[] = useCombinedStore((state: any) => state.natureFiles)

  const categoryMasters: CategoryMaster[] = useCombinedStore((state: any) => state.categoryMasters);
  const subCategoryMasters: SubCategoryMaster[] = useCombinedStore((state: any) => state.subCategoryMasters);
  const subsubCategoryMasters: ThirdLevelCategory[] = useCombinedStore((state: any) => state.subsubCategoryMasters);

  const fetchCategoryMasters = useCombinedStore((state: any) => state.fetchCategoryMasters);
  const fetchSubCategoryMasters = useCombinedStore((state: any) => state.fetchSubCategoryMasters);
  const fetchSubSubCategoryMasters = useCombinedStore((state: any) => state.fetchSubSubCategoryMasters);
  const deleteFile = useCombinedStore((state: any) => state.deleteFile);
  const [filingasSB, setFilingAsSB] = useState('no')

  const intermediaries = useCombinedStore((state: any) => state.intermediaries)
  const fetchIntermediaries = useCombinedStore((state: any) => state.fetchIntermediaries);
  const [tAndC, setTAndC] = useState(false)

  const uploadFile = useCombinedStore((state: any) => state.uploadFile)

  const MIIsforSB = [
    { organisationName: "Sample MII SB", type: "SB", parentId: 1 },
    { organisationName: "Jupitice MII", type: "SB", parentId: 10 },
    { organisationName: "CADRE MII", type: "SB", parentId: 10 },
    { organisationName: "Webnyay MII", type: "SB", parentId: 10 },
    { organisationName: "Securitybulls MII", type: "SB", parentId: 37 }, 
  ]

  const MIIsforDP = [
    { organisationName: "Sample MII DP", type: "DP", parentId: 2 },
    { organisationName: "Jupitice MII", type: "SB", parentId: 10 },
    { organisationName: "CADRE MII", type: "DP", parentId: 10 },
    { organisationName: "Webnyay MII", type: "DP", parentId: 10 },
    { organisationName: "Securitybulls MII", type: "DP", parentId: 37 }, 
  ]

  const MIIsforLC = [
    { organisationName: "Sample MII SB", type: "SB", parentId: 1 },
    { organisationName: "Jupitice MII", type: "SB", parentId: 10 },
    { organisationName: "CADRE MII", type: "SB", parentId: 10 },
    { organisationName: "Webnyay MII", type: "SB", parentId: 10 },
    { organisationName: "Securitybulls MII", type: "SB", parentId: 37 }, 
  ]

  useEffect(() => {
    fetchCategoryMasters();
  }, []);

  useEffect(() => {
  }, [files]);


  const selectCategory = (category: CategoryMaster) => {
    fetchSubCategoryMasters(category.categoryId);
    setCategory(category.categoryName)
    setCategoryId(category.categoryId)
    setsubCategoryId('')
    setmiis('')
    if (category.categoryName === "Clients / Investors") {
      setmiis(defaultMIIS)
    }
    setSubCategory('')
    setNatureOfComplaint('');
    setsubSubCategoryId('')
    setThirdCategory('')
    setintermediarysearchTerm('');
    setfilteredIntermediaries([])
    setComplaint({
      ...complaint,
      categoryId: category?.categoryId,
      subCategoryId: ''
    })
    setToggleCategory(false)
    setToggleSubCategory(true)
    setSelectedNatureOfComplaints([])
    setmodeOfHoldingSecurities({})
    setIntermediary({})
    setSelectedMII('')
  }

  /**
   * 
   * @param scoreDetails  
   */

  const selectSubCategory = (subCategory: SubCategoryMaster) => {

    fetchIntermediaries(subCategory.subCategoryId)
    setsubCategoryId(subCategory.subCategoryId)
    fetchSubSubCategoryMasters(subCategory.subCategoryId);
    setsubSubCategoryId('')
    setThirdCategory('')
    setNatureOfComplaint('');
    setintermediarysearchTerm('');
    setfilteredIntermediaries([])

    if (subCategory.categoryName === "Stock Broker") {
      setmiis(MIIsforSB)
    }
    if (subCategory.categoryName === "Depository Participants") {
      setmiis(MIIsforDP)
    }
    if (subCategory.categoryName === "Listed Companies") {
      setmiis(MIIsforLC)
    }
    if (subCategory.categoryName === "Clients / Investors") {
      setmiis(defaultMIIS)
    }
    setSubCategory(subCategory.categoryName)
    setComplaint({
      ...complaint,
      subCategoryId: subCategory?.subCategoryId
    })
    if (subCategory?.natureOfComplaint?.length > 0) {
      setNatureOfComplaint((prevNatureOfComplaint: any) => new Set([
        ...subCategory.natureOfComplaint
      ]));

      setToggleSubSubCategory(false)
    } else {
      setToggleSubSubCategory(true)
    }
    setToggleSubCategory(false)
    setSelectedNatureOfComplaints([])
    setmodeOfHoldingSecurities({})
    setIntermediary({})
    setSelectedMII('')
  }

  /**
   * 
   * @param subCategory 
   */
  const selectThirdCategory = (subCategory: ThirdLevelCategory) => {
    setsubSubCategoryId(subCategory.thirdLevelCategoryId)
    setThirdCategory(subCategory.categoryName)
    setintermediarysearchTerm('');
    setfilteredIntermediaries([])
    setNatureOfComplaint((prevNatureOfComplaint: any) => new Set([
      ...subCategory.natureOfComplaint
    ]));
    setComplaint({
      ...complaint,
      subSubCategoryId: subCategory?.thirdLevelCategoryId
    })
    setToggleSubSubCategory(false)
  }
  /**
   * 
   * @param event 
   * @param nature 
   */
  const updateNatureOfComplaint = (event: any, nature: any) => {
    if (event?.target?.checked) {
      if (nature.hasOwnProperty('subNatureType')) {
        setSelectedNatureOfComplaints([
          ...selectedNatureOfComplaints, {
            natureType: nature?.natureType,
            subNatureType: nature?.subNatureType,
            selectedSubComplaintType: []
          }
        ])
      } else {
        setSelectedNatureOfComplaints([
          ...selectedNatureOfComplaints, {
            natureType: nature?.natureType,
            subNatureType: "",
            selectedSubComplaintType: [{ natureType: nature.natureType, description: "", subNatureType: "" }]
          }
        ])
      }
    } else {
      const index = complaint?.natureOfComplaint?.findIndex((i: any) => i.natureType === nature?.natureType)
      complaint?.natureOfComplaint?.splice(index, 1)
      setSelectedNatureOfComplaints((prevSelectedNatureOfComplaints: any) =>
        prevSelectedNatureOfComplaints.filter((complaint: any) => complaint.natureType !== nature?.natureType)
      );

    }
  }

  /**
   * 
   * @param event 
   * @param subNature 
   * @param nature 
   */
  const updateSubNatureOfComplaint = (event: any, subNature: any, nature: any) => {
    setSelectedNatureOfComplaints((prevSelectedNatureOfComplaints: any) => {
      const updatedSelectedNatureOfComplaints = prevSelectedNatureOfComplaints?.map((selectedNatureOfComplaint: any) => {
        if (selectedNatureOfComplaint.natureType === nature?.natureType) {
          const updatedSelectedSubComplaintType = event?.target?.checked
            ? [
              ...selectedNatureOfComplaint.selectedSubComplaintType,
              {
                natureType: nature.natureType,
                subNatureType: subNature,
                description: "",
              },
            ]
            : selectedNatureOfComplaint.selectedSubComplaintType.filter(
              (subComplaint: any) => subComplaint.subNatureType !== subNature
            );

          return {
            ...selectedNatureOfComplaint,
            selectedSubComplaintType: updatedSelectedSubComplaintType,
          };
        }
        return selectedNatureOfComplaint;
      });

      return updatedSelectedNatureOfComplaints;
    });
  };

  /**
   * 
   * @param event 
   */
  const setModeOfHolding = (event: any) => {
    if (event.type === 'click') {
      const { id } = event.target
      setmodeOfHoldingSecurities({ mode: id })
    } else {
      const { name, value } = event.target
      setmodeOfHoldingSecurities((prevMode: any) => ({
        ...prevMode,
        [name]: value,
      }));
    }
  }

  const isValidEmail = (email: any) => {
    const tester = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
    if (!email) return false

    if (email.length > 256) return false

    if (!tester.test(email)) return false

    // Further checking of some things regex can't handle
    const [account, address] = email.split('@')
    if (account.length > 64) return false

    const domainParts = address.split('.')
    return !domainParts.some((part: any) => part.length > 63)
  }

  const isValidPhoneNumber = (phoneNumber: any) => {
    const phoneRegexIndia = /^(\+?91|0)?[6789]\d{9}$/
    return phoneRegexIndia.test(phoneNumber)
  }

  const isValidPanNumber = (panNumber: any) => {
    if (panNumber) {
      const panNumberRegex = /^([A-Z]{5})(\d{4})([A-Z])$/ // 5 Uppercase,4digits,1 Uppercase
      return panNumberRegex.test(panNumber)
    }
  }

  const [error, setErrors] = useState({
    userName: '',
    email: '',
    phoneNumber: '',
    panNumber: '',
    address: '',
    city: '',
    state: '',
  });

  const validateField = (name: any, value: any) => {
    const newErrors = { ...error };
    switch (name) {
      case 'userName':
        newErrors.userName = value ? '' : 'Name is required';
        break;
      case 'email':
        newErrors.email = isValidEmail(value) ? '' : 'Invalid email address';
        break;
      case 'phoneNumber':
        newErrors.phoneNumber = isValidPhoneNumber(value) ? '' : 'Invalid phone number';
        break;
      case 'panNumber':
        newErrors.panNumber = isValidPanNumber(value) ? '' : 'Invalid PAN number';
        break;
      case 'address':
        newErrors.address = value ? '' : 'Invalid address';
        break;
      case 'city':
        newErrors.city = value ? '' : 'Invalid city';
        break;
      case 'state':
        newErrors.state = value ? '' : 'Invalid state';
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleInvestorDetails = (e: any) => {
    const { name, value } = e.target
    setInvestorDetails((prevMode: any) => ({
      ...prevMode,
      [name]: value,
    }));
    validateField(name, value);
  }

  //change description
  const handleSubDescriptionChange = (e: any, natureType: any, nature: any) => {
    if (e.target.value.length > 2) {
      setSelectedNatureOfComplaints((prevComplaints: any) => {
        const updatedComplaints = prevComplaints?.map((complaint: any) => {
          if (complaint.subNatureType.includes(natureType)) {
            return {
              ...complaint,
              selectedSubComplaintType: complaint.selectedSubComplaintType?.map((selected: any) => {
                if (selected.subNatureType === natureType) {
                  return {
                    ...selected,
                    description: e.target.value
                  };
                }
                return selected;
              })
            };
          }
          return complaint;
        });
        return updatedComplaints;
      });
    } else {
      setSelectedNatureOfComplaints((prevComplaints: any) => {
        const updatedComplaints = prevComplaints?.map((complaint: any) => {
          if (complaint.subNatureType.includes(natureType)) {
            return {
              ...complaint,
              selectedSubComplaintType: complaint.selectedSubComplaintType?.map((selected: any) => {
                if (selected.subNatureType === natureType) {
                  return {
                    ...selected,
                    description: null
                  };
                }
                return selected;
              })
            };
          }
          return complaint;
        });
        return updatedComplaints;
      });
    }
  }

  const handleDescriptionChange = (e: any, natureType: any) => {
    setSelectedNatureOfComplaints((prevComplaints: any) => {
      const updatedComplaints = prevComplaints?.map((complaint: any) => {
        return {
          ...complaint,
          selectedSubComplaintType: complaint.selectedSubComplaintType?.map((selected: any) => {
            if (selected.natureType === natureType) {
              return {
                ...selected,
                description: e.target.value
              };
            }
            return selected;
          })
        };
      });
      return updatedComplaints;
    });
  }

  const handleFilesInDescription = (e: any) => {
    const file = e.target.files;
    const formData = new FormData();
    formData.append('file', file);

    let fileSize = 0
    for (const f of file) {
      fileSize += f.size;
    }
    if (fileSize > 20 * 1000 * 1024) {
      notifyError(t('UploadFileExceeds'))
      return false;
    }

    for (let i = 0; i < file.length; i++) {
      uploadFile(e.target.files[i], "", selectedAttachmentNature.natureType, complaint.subCategoryId)
    }
  }

  const handleFilesInSubDescription = (e: any) => {
    const file = e.target.files;
    const formData = new FormData();
    formData.append('file', file);

    let fileSize = 0
    for (const f of file) {
      fileSize += f.size;
    }
    if (fileSize > 20 * 1000 * 1024) {
      notifyError(t('UploadFileExceeds'))
      return false;
    }

    for (let i = 0; i < file.length; i++) {
      uploadFile(e.target.files[i], selectedAttachmentNature.natureType, selectedAttachmentNature.nature, complaint.subCategoryId)
    }
  }

  const fileAComplaint = (e: any) => {
    e.preventDefault()
    let complainObject: { [key: string]: any } = complainFilingDetail;
    complainObject.categoryId = categoryId;

    if (subCategoryId === "") {
      notifyError(t('PleaseSelectCategory'))
      return
    }

    if (category === "Clients / Investors") {

      if (subCategoryId !== "") complainObject.subCategoryId = subCategoryId;
      if (subSubCategoryId !== "") complainObject.subSubCategoryId = subCategoryId;
      complainObject.investorDetails = investorDetails
      if (selectedMII !== "") complainObject.mIIName = selectedMII;
      complainObject.complainBrief = complainBrief
      complainObject.investorDetails = investorDetails;
      complainObject.natureOfComplaint = selectedNatureOfComplaints;
      if (files && files.length > 0) complainObject.natureFiles = files;

      setComplaint(complainObject)

      setAgainstInvestorModal(true)
    } else {

      if (subCategoryId === "") {
        notifyError(t('PleaseSelectCategory'))
        return
      }

      if ((subCategoryId == "1" || subCategoryId == "4" || subCategoryId == "6") && selectedMII === "") {
        notifyError(t('PleaseSelectTrade'))
        return
      }

      if (!intermediary || !intermediary.orgId) {
        notifyError(t('PleaseSelectIntermediary'))
        return
      }

      complainObject.categoryId = categoryId;
      if (subCategoryId !== "") complainObject.subCategoryId = subCategoryId;
      if (subSubCategoryId !== "") complainObject.subSubCategoryId = subCategoryId;

      if (typeOfSecurity !== "") complainObject.typeOfSecurity = typeOfSecurity
      if (Object.keys(modeOfHoldingSecurities).length !== 0) complainObject.modeOfHoldingSecurites = modeOfHoldingSecurities
      complainObject.intermediaryId = intermediary.orgId;
      if (selectedMII !== "") complainObject.mIIName = selectedMII
      complainObject.captchaToken = captcha
      complainObject.natureOfComplaint = selectedNatureOfComplaints;
      if (files && files.length > 0) complainObject.natureFiles = files;
      setComplaint(complainObject)
      setPreviewModal(true)

    }

  }

  const updateIntermediary = (orgId: any) => {
    setIntermediary(orgId)
  }

  const removeFile = (timestamp: string) => {
    deleteFile(timestamp)
  }

  const onVerify = useCallback((token: string) => {
    setCaptcha(token)
  }, []);

  const handleIntermediarySearch = (e: any) => {
    const { value } = e.target;
    setintermediarysearchTerm(value);

    if (value) {
      const filteredData = intermediaries.filter((item: any) => {
        return item.organisationName.toLowerCase().includes(value.toLowerCase())
      }
      );
      setfilteredIntermediaries(filteredData);
    } else {
      setfilteredIntermediaries([])
    }
  }

  const uploadingFile = (natureType: string, nature?: string) => {
    setSelectedAttachmentNature({
      nature,
      natureType
    })
  }

  useEffect(() => {
    if (selectedMII !== '') {
      const parentId = miis?.filter((mii: any) => mii.organisationName === selectedMII)?.[0]?.parentId
      fetchIntermediaries(subCategoryId, parentId)
    }
  }, [selectedMII])

  const checkClaimAmount = (claimValue: string) => {
    const claimValueNumber = parseFloat(claimValue)
    if (claimValueNumber > 100000000 && !editClaimValue) {
      setClaimValueModal(true)
    } else if (claimValueNumber < 100000000) {
      setClaimValueModal(false)
      setEditClaimValue(false)
    }
  }

  return (
    <div className="pt-7 w-full h-full pr-1 sm:pr-4 pl-1 pb-7">
      {previewModal && (
        <IntermediaryComplaintPreview
          complainObject={complaint}
          previewModal={previewModal}
          setPreviewModal={setPreviewModal}
          categoryType={category}
          category={`${category}/${subCategory}${thirdCategory !== "" ? `/${thirdCategory}` : ""}`}
          natureOfComplainDescription={files}
          intermediary={intermediary}
          selectedNatureOfComplaints={selectedNatureOfComplaints} />
      )}
      {againstInvestorModal && (
        <AgainstInvestorPreview
          category={category}
          complainObject={complaint}
          againstInvestorModal={againstInvestorModal}
          setAgainstInvestorModal={setAgainstInvestorModal}
          selectedMII={selectedMII}
          investorDetails={investorDetails}

        />
      )}
      <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={true} />

      <div
        style={{ background: "#F5F5F5" }}
        className=" w-full h-full flex-initial  pr-3 rounded-2xl "
      >
        <div className="flex w-full h-full flex-initial overflow-y-scroll overflow-hidden  px-8 py-7 flex-col gap-2 rounded-2xl">
          <p className="hidden sm:block text-xs text-back-gray">{t('FileADispute')}</p>
          <div className="w-full flex flex-row justify-between">
            <div className="w-full flex flex-col gap-4">
              {categoryMasters.length > 0 && toggleCategory ? (
                <>
                  <div className="flex flex-row gap-3 items-center text-highlight-blue">
                    <h1 className="sm:text-3xl text-lg font-semibold ">
                      {t('SelectIntermediaryInvestor')}
                    </h1>
                    <InfoTooltip title={t('FinancialIntermediaries')} />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 gap-9 sm:pr-36 w-full">
                    {categoryMasters?.map((categoryMaster: CategoryMaster, index: number) => {

                      return (
                        <div className={`${category === categoryMaster?.categoryName ? 'text-white bg-highlight-blue' : 'text-highlight-blue bg-light-blue'} flex flex-col py-12 px-6  hover:bg-highlight-blue rounded-xl cursor-pointer hover:text-white items-center gap-2`}
                          onClick={() => {

                            selectCategory(categoryMaster)
                          }}>
                          <i className={`ph-bold ph-${categoryMaster?.otherMetadata?.icon || 'buildings'}`}></i>
                          <p className="text-xs text-center">{categoryMaster?.categoryName}</p>
                        </div>
                      )
                    })}
                    {/* <div className={`${category === "Investor" ? 'text-white bg-highlight-blue' : 'text-highlight-blue bg-light-blue'} flex flex-col py-12 px-6  hover:bg-highlight-blue rounded-xl cursor-pointer hover:text-white items-center gap-2`}
                      onClick={() => {
                        setmiis(defaultMIIS)
                        setCategory("Investor")
                      }}>
                      <i className={`ph-bold ph-user`}></i>
                      <p className="text-xs text-center">Investor</p>
                    </div> */}
                  </div>
                </>
              ) :
                (
                  <div className="flex flex-col gap-2 cursor-pointer" onClick={() => setToggleCategory(true)}>
                    <h1 className="sm:text-3xl text-lg font-semibold text-back-gray">
                      {t('SelectIntermediaryType')}
                    </h1>
                    <p className="text-xs text-back-gray">{category.length !== 0 && category}</p>
                    <hr />
                  </div>
                )
              }
              {subCategoryMasters.length > 0 && toggleSubCategory ? (
                <>
                  <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue">
                    {t('SelectIntermediary')}
                  </h1>

                  <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 gap-9 sm:pr-36 w-full">
                    {subCategoryMasters?.map((subCategory1: SubCategoryMaster) => {
                      return (
                        <div className={`${subCategory === subCategory1?.categoryName ? 'text-white bg-highlight-blue' : 'text-highlight-blue bg-light-blue'} flex flex-col py-12 px-6  hover:bg-highlight-blue rounded-xl cursor-pointer hover:text-white items-center gap-2`}
                          onClick={() => {
                            selectSubCategory(subCategory1)
                          }}>
                          <i className={`ph-bold ph-${subCategory1?.otherMetadata?.icon || 'buildings'}`}></i>
                          <p className="text-xs text-center">{subCategory1?.categoryName}</p>
                        </div>
                      )
                    })}
                  </div>
                </>
              ) : (
                <>
                  {category !== "Clients / Investors" &&
                    <div className="flex flex-col gap-2 cursor-pointer" onClick={() => setToggleSubCategory(true)}>
                      <h1 className="sm:text-3xl text-lg font-semibold text-back-gray">
                        {t('SelectIntermediary')}
                      </h1>
                      <p className="text-xs text-back-gray">{subCategory.length !== 0 && subCategory}</p>
                      <hr />
                    </div>

                  }

                </>

              )}

              {subsubCategoryMasters.length > 0 && <>
                {toggleSubSubCategory && category !== "Clients / Investors" ? (
                  <>
                    <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue">
                      {t('SelectCategory')}
                    </h1>

                    <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 gap-9 sm:pr-36 w-full">
                      {subsubCategoryMasters?.map((subCategory: ThirdLevelCategory) => {
                        return (
                          <div className={`${thirdCategory === subCategory?.categoryName ? 'text-white bg-highlight-blue' : 'text-highlight-blue bg-light-blue'} flex flex-col py-12 px-6  hover:bg-highlight-blue rounded-xl cursor-pointer hover:text-white items-center gap-2`}
                            onClick={() => selectThirdCategory(subCategory)}>
                            <i className={`ph-bold ph-${subCategory?.otherMetadata?.icon || 'buildings'}`}></i>
                            <p className="text-xs text-center">{subCategory?.categoryName?.split(/\//g)?.map((item: any, index: any) => (
                              <Fragment key={subCategory?.thirdLevelCategoryId}>
                                {item} {index === (subCategory?.categoryName.split(/\//g).length - 1) ? '' : '/'} {
                                  index % 3 === 0 &&
                                  <br />
                                }
                              </Fragment>
                            ))}</p>

                          </div>
                        )
                      })}
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col gap-2 cursor-pointer" onClick={() => setToggleSubSubCategory(true)}>
                    <h1 className="sm:text-3xl text-lg font-semibold text-back-gray">
                      {t('SelectCategory')}
                    </h1>
                    <p className="text-xs text-back-gray">{thirdCategory.length !== 0 && thirdCategory}</p>
                    <hr />
                  </div>
                )}
              </>
              }
              {(subCategoryId && categoryId && Object.keys(subCategory).length !== 0 && Object.keys(category).length !== 0 && !toggleCategory && !toggleSubCategory && !toggleSubSubCategory) || category === "Clients / Investors" ? (
                <>
                  <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue">
                    {t('FillDisputeDetails')}
                  </h1>

                  {miis && miis.length > 0 && category !== "Clients / Investors" && (
                    <div className="flex flex-col pt-1 gap-2">
                      <div className="flex flex-row gap-2 items-center">
                        {category === "Clients / Investors" ? (
                          <div>
                            <label htmlFor="CompanyName" className="text-xs">Disputed Trade executed on (If Applicable) </label>
                            <InfoTooltip title={t('MIIOnRoundRobin')} />
                          </div>
                        ) : (
                          <div>
                            <label htmlFor="CompanyName" className="text-xs">{t('DisputedTradeExecutedOn')} </label>
                            <InfoTooltip title={t('SelectStockExchange')} />
                          </div>
                        )}

                      </div>

                      <select
                        aria-label={t('SelectState')} required name='state' id='state'
                        className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl"
                        onChange={(e: any) => {

                          setSelectedMII(e.target.value)
                        }}
                        value={selectedMII}
                      >
                        <option>{t("SelectExchange")}</option>
                        {miis?.map((org: Organisation) => {
                          return <option key={org.organisationName} value={org.organisationName}>{org.organisationName}</option>
                        })}
                      </select>

                    </div>
                  )}
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-col pt-1 gap-2">
                      <div className="relative w-full">
                        {category !== "Clients / Investors" && (
                          <div className="inline-flex flex-col gap-2 w-full">
                            <div className="flex flex-row gap-2 items-center">
                              <label htmlFor="CompanyName" className="text-xs">{subCategory === 'Depository Participants' ? t('SelectDepositoryParticipant') : t('SelectIntermediary')}</label>
                              <InfoTooltip title={t('EnterSpecificIntermediary')} />
                            </div>
                              <input
                                className="sm:w-1/2 p-2 border border-back-gray text-xs bg-white rounded-xl"
                                type="text"
                                placeholder={t('EnterNameofIntermediary')}
                                value={intermediarysearchTerm}
                                onChange={handleIntermediarySearch}
                              />
                          </div>
                        )}

                        {filteredIntermediaries.length > 0 && (
                          <ul className="origin-top-right absolute left-0 mt-2 sm:w-1/2 w-fit rounded-lg bg-white text-xs px-1 py-2">
                            {filteredIntermediaries?.map((item: any, index: any) => (
                              <li
                                className="px-3 py-1 hover:bg-back-gray rounded-lg cursor-pointer"
                                key={item?.organisationName} onClick={() => {
                                  setintermediarysearchTerm(item.organisationName)
                                  setIntermediary(item)
                                  setfilteredIntermediaries([])
                                }}>

                                {item.organisationName}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>


                    </div>



                    <div className="flex flex-col gap-2">
                      <p className="text-xs">{t('NatureOfDisputeRelatedTo')} <span className="text-xs text-highlight-red">*</span></p>
                      <div className="flex flex-col gap-3">
                        {Array.from(natureOfComplaint).length > 0 && Array.from(natureOfComplaint)?.map((nature: any, index: number) => {
                          return (
                            <>

                              {nature &&
                                <div key={index} className="flex flex-row gap-2">
                                  <input type="checkbox" onChange={(e) => updateNatureOfComplaint(e, nature)} />
                                  <label htmlFor={nature.natureType} className="text-xs">{nature.natureType}</label>
                                </div>
                              }
                              {selectedNatureOfComplaints?.filter((complaintNature: any) => complaintNature.natureType === nature?.natureType)?.length > 0 && (
                                <div className="flex flex-col gap-2 pl-2">
                                  {selectedNatureOfComplaints[0].subNatureType !== "" && selectedNatureOfComplaints?.filter((complaintNature: any) => complaintNature.natureType === nature?.natureType)?.[0]?.subNatureType?.map((natureType: any, complaintIndex: number) => {

                                    return (
                                      <>
                                        <div key={complaintIndex} className="flex flex-row gap-2">
                                          <input type="checkbox" onChange={(e) => updateSubNatureOfComplaint(e, natureType, nature)} />
                                          <label htmlFor={natureType} className="text-xs">{natureType}</label>
                                        </div>
                                        {selectedNatureOfComplaints?.filter((complaintNature: any) => complaintNature.natureType === nature?.natureType)?.[0]?.selectedSubComplaintType?.filter((selectedSubType: any) => selectedSubType.subNatureType === natureType)?.[0] && (
                                          <div className="flex flex-col gap-2 pl-2">
                                            <div className="bg-white rounded-xl sm:w-1/2 p-2 flex flex-col gap-2">
                                              <textarea onChange={(e) => { handleSubDescriptionChange(e, natureType, nature) }} placeholder={t('AddDisputeDescription')} rows={3} className="text-xs p-2 border-none"></textarea>
                                              <div className="flex flex-row justify-between">
                                                <div className="grid grid-cols-2  sm:grid-cols-3 sm:gap-1 gap-2">
                                                  {files.filter((i: any) => i.natureType === natureType && i.nature === nature.natureType)?.map((file: any, i: any) => {
                                                    return (
                                                      <div className="flex flex-row gap-2 p-2 border border-border-grey rounded-xl">
                                                        <i className="ph-bold ph-file-arrow-up"></i>
                                                        <p className="sm:text-xs text-[10px]">{file.fileName.length > 10 ? (file.fileName).slice(0, 5) + '...' : file.fileName}</p>
                                                        <i className="ph-bold ph-x text-xs" onClick={() => removeFile(file.timestamp)}></i>
                                                      </div>
                                                    )
                                                  })}
                                                </div>
                                                <div className="flex flex-row gap-6 items-end pr-4">
                                                  <label htmlFor='upload' className="cursor-pointer">
                                                    <i onClick={(e) => uploadingFile(natureType, nature.natureType)} className="ph-bold ph-paperclip"></i>
                                                    <input multiple aria-label={t('Choosefile')} type="file" id='upload' style={{ display: 'none' }} accept=".jpeg, .pdf, .png, .jpg, .xlsx, .csv, .mp3, .zip, .mp4, .doc, .docx" onClick={(e) => e.currentTarget.value = ""}
                                                      onChange={(e) => handleFilesInSubDescription(e)} />
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="flex flex-col gap-1 text-[10px] text-back-gray">
                                              <p>{t('AcceptedFormat')}</p>
                                              <p>{t('Note')}: {t('TotalFileSize')}</p>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )
                                  })}

                                  {selectedNatureOfComplaints[0].subNatureType == "" && selectedNatureOfComplaints?.filter((complaintNature: any) => complaintNature.natureType === nature?.natureType)?.[0] && (
                                    <div className="flex flex-col gap-2 pl-2">
                                      <div className="bg-white rounded-xl sm:w-1/2 p-2 flex flex-col gap-2">
                                        <textarea onChange={(e) => { handleDescriptionChange(e, nature.natureType) }} placeholder={t('AddDisputeDescription')} rows={3} className="text-xs p-2 border-none"></textarea>
                                        <div className="flex flex-row justify-between">
                                          <div className="grid grid-cols-2  sm:grid-cols-3 sm:gap-1 gap-2">
                                            {files.filter((i: any) => i.nature === nature.natureType)?.map((file: any, i: any) => {
                                              return (
                                                <div className="flex flex-row gap-2 p-2 border border-border-grey rounded-xl">
                                                  <i className="ph-bold ph-file-arrow-up"></i>
                                                  <p className="sm:text-xs text-[10px]">{file.fileName.length > 10 ? (file.fileName).slice(0, 5) + '...' : file.fileName}</p>
                                                  <i className="ph-bold ph-x text-xs" onClick={() => removeFile(file.timestamp)}></i>
                                                </div>
                                              )
                                            })}
                                          </div>
                                          <div className="flex flex-row gap-6 items-end pr-4">
                                            <label htmlFor='upload' className="cursor-pointer">
                                              <i onClick={(e) => uploadingFile(nature.natureType)} className="ph-bold ph-paperclip"></i>
                                              <input multiple aria-label={t('Choosefile')} type="file" id='upload' style={{ display: 'none' }} name={nature.natureType} onClick={(e) => e.currentTarget.value = ""}
                                                accept=".jpeg, .pdf, .png, .jpg, .xlsx, .csv, .mp3, .zip, .mp4, .doc, .docx" onChange={(e) => handleFilesInDescription(e)} />
                                            </label>
                                          </div>
                                        </div>
                                        <div className="flex flex-col gap-1 text-[10px] text-back-gray">
                                          <p>{t('AcceptedFormat')}</p>
                                          <p>{t('Note')}: {t('TotalFileSize')}</p>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                </div>
                              )}
                            </>
                          )
                        })}

                      </div>

                    </div>
                    {category !== "Clients / Investors" && (
                      <div>

                        <div className="flex flex-col pt-1 gap-2">
                          <label htmlFor="CompanyName" className="text-xs">{t('TypeofSecurities')}</label>
                          <input type="text" onChange={(e) => { setTypeOfSecurity(e.target.value) }} className="sm:w-1/2 p-2 border text-xs border-back-gray bg-white rounded-xl" />
                        </div>
                        <div className="flex flex-col pt-1 gap-2">
                          <label htmlFor="CompanyName" className="text-xs">{t('ModeofHoldingSecurities')}</label>
                          <div className="flex flex-col gap-3">
                            <div className="flex flex-row gap-2">
                              <input type="radio" name="mode" id="Physical" value={complaint?.modeOfHoldingSecurities?.mode} onClick={(e) => setModeOfHolding(e)} />
                              <label htmlFor="Physical" className="text-xs">{t('Physical')}</label>
                            </div>
                            {
                              modeOfHoldingSecurities?.mode === "Physical" &&
                              <div className="flex flex-col gap-2 pl-2">
                                <div className="flex flex-col pt-1 gap-2">
                                  <label htmlFor="Folio No." className="text-xs">{t('FolioNo')}</label>
                                  <input type="text" name="folioNo" id="folioNo" value={complaint?.modeOfHoldingSecurities?.folioNo} onChange={(e) => setModeOfHolding(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                                </div>
                                <div className="flex flex-col pt-1 gap-2">
                                  <label htmlFor="Distinctive Nos." className="text-xs">{t('DistinctiveNo')}</label>
                                  <input type="text" name="distinctiveNo" id="distinctiveNo" value={complaint?.modeOfHoldingSecurities?.distinctiveNo} onChange={(e) => setModeOfHolding(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                                </div>
                                <div className="flex flex-col pt-1 gap-2">
                                  <label htmlFor="Certificate No." className="text-xs">{t('CertificateNo')}</label>
                                  <input type="text" name="certificateNo" id="certificateNo" value={complaint?.modeOfHoldingSecurities?.certificateNo} onChange={(e) => setModeOfHolding(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                                </div>
                              </div>
                            }
                            <div className="flex flex-row gap-2">
                              <input type="radio" name="mode" id="Demat" value={complaint?.modeOfHoldingSecurities?.mode} onClick={(e) => setModeOfHolding(e)} />
                              <label htmlFor="Demat" className="text-xs">{t('Demat')}</label>
                            </div>
                            {modeOfHoldingSecurities?.mode === "Demat" &&
                              <div className="flex flex-col gap-2 pl-2">
                                <div className="flex flex-col pt-1 gap-2">
                                  <label htmlFor="DP ID" className="text-xs">{t('DPID')}</label>
                                  <input type="text" name="dpId" id="dpId" value={complaint?.modeOfHoldingSecurities?.dpId} onChange={(e) => setModeOfHolding(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                                </div>
                                <div className="flex flex-col pt-1 gap-2">
                                  <label htmlFor="Client ID" className="text-xs">{t('ClientID')}</label>
                                  <input type="text" name="clientId" id="clientId" value={complaint?.modeOfHoldingSecurities?.clientId} onChange={(e) => setModeOfHolding(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                                </div>
                              </div>
                            }

                          </div>
                        </div>
                      </div>
                    )}


                  </div>
                  {category === "Clients / Investors" && (
                    <div className="flex flex-col gap-3">
                      <div className="flex flex-col gap-2 pt-1">
                        <p className="text-xs flex items-center gap-1">{t('AreYouFiling')}
                          <InfoTooltip title={t('DisputesFiledStockBrokersRoundRobin')} /></p>
                        <div className="flex flex-row gap-2">
                          <div className="flex flex-row gap-2" >
                            <input type="radio" id="Yes" value={"yes"} checked={filingasSB === "yes" ? true : false} name="score-options" onChange={(e: any) => {
                              setFilingAsSB("yes")
                            }} />
                            <label htmlFor="Yes" className="text-xs">{t('Yes')}</label>
                          </div>
                          <div className="flex flex-row gap-2">
                            <input type="radio" id="No" checked={filingasSB === "no" ? true : false} value="no" name="filing-as-sb" onChange={(e: any) => {
                              setFilingAsSB("no")
                            }} />
                            <label htmlFor="No" className="text-xs">{t('No')}</label>
                          </div>
                        </div>

                      </div>
                      {filingasSB === "yes" &&
                        <div className="flex flex-col pt-1 gap-2">
                          <div className="flex flex-row gap-2 items-center">
                            <div>
                              <label htmlFor="CompanyName" className="text-xs">{t('DisputedTradeExecutedOn')} </label>
                              <InfoTooltip title={t('SelectStockExchange')} />
                            </div>

                          </div>

                          <select
                            aria-label={t('SelectState')} required name='state' id='state'
                            className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl"
                            onChange={(e: any) => {

                              setSelectedMII(e.target.value)
                            }}
                            value={selectedMII}
                          >
                            <option>{t('SelectExchange')}</option>
                            {miis?.map((org: Organisation) => {
                              return <option key={org.organisationName} value={org.organisationName}>{org.organisationName}</option>
                            })}
                          </select>

                        </div>
                      }
                      <div className="flex flex-col pt-1 gap-2">
                        <label htmlFor="InvestorName" className="text-xs">{t('EnterInvestorName')}</label>
                        <input type="text" name="userName" id="InvestorName" value={investorDetails?.userName} onChange={(e) => handleInvestorDetails(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                        <span className="text-xs text-highlight-red">{error?.userName}</span>
                      </div>
                      <div className="flex flex-col pt-1 gap-2">
                        <label htmlFor="InvestorPAN" className="text-xs">{t('EnterInvestorPAN')}</label>
                        <input type="text" name="panNumber" id="InvestorPAN" value={investorDetails?.panNumber} onChange={(e) => handleInvestorDetails(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                        <span className="text-xs text-highlight-red">{error?.panNumber}</span>
                      </div>
                      <div className="flex flex-col pt-1 gap-2">
                        <label htmlFor="InvestorNumber" className="text-xs">{t('EnterInvestorMobileNumber')}</label>
                        <input type="number" name="phoneNumber" id="InvestorNumber" value={investorDetails?.phoneNumber} onChange={(e) => handleInvestorDetails(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                        <span className="text-xs text-highlight-red">{error?.phoneNumber}</span>
                      </div>
                      <div className="flex flex-col pt-1 gap-2">
                        <label htmlFor="InvestorEmail" className="text-xs">{t('EnterInvestorEmailID')}</label>
                        <input type="text" name="email" id="InvestorEmail" value={investorDetails?.email} onChange={(e) => handleInvestorDetails(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                        <span className="text-xs text-highlight-red">{error?.email}</span>
                      </div>
                      <div className="flex flex-col pt-1 gap-2">
                        <label htmlFor="InvestorAddress" className="text-xs">{t('EnterInvestorResidentialAddress')}</label>
                        <input type="text" name="address" id="InvestorAddress" value={investorDetails?.address} onChange={(e) => handleInvestorDetails(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                        <span className="text-xs text-highlight-red">{error?.address}</span>
                      </div>
                      <div className="flex flex-col pt-1 gap-2">
                        <label htmlFor="InvestorAddress" className="text-xs">{t('EnterInvestorState')}</label>

                        <select
                          aria-label={t('SelectState')} required name='state' id='state' onChange={(e) => handleInvestorDetails(e)}
                          className="sm:w-1/2 rounded-xl p-2 border border-border-grey border-solid text-xs" value={investorDetails?.state}
                        >
                          <option>{t('SelectState')}</option>
                          {StateType?.map((state: string) => {
                            return <option key={state} value={state}>{state}</option>
                          })}
                        </select>
                        <span className="text-xs text-highlight-red">{error?.state}</span>
                      </div>
                      <div className="flex flex-col pt-1 gap-2">
                        <label htmlFor="InvestorAddress" className="text-xs">{t('EnterInvestorCity')}</label>
                        <select
                          aria-label={t('SelectCity')} required name='city' id='city' onChange={(e) => handleInvestorDetails(e)}
                          className="sm:w-1/2 rounded-xl p-2 border border-border-grey border-solid text-xs" value={investorDetails?.city}
                        >
                          <option>{t('SelectCity')}</option>
                          {CityType.filter((city: any) => city.state === investorDetails?.state)?.map((city: any) => {
                            return <option key={city} value={city.city}>{city.city}</option>
                          })}
                        </select>
                        <span className="text-xs text-highlight-red">{error?.city}</span>
                      </div>


                      <div className="flex flex-col pt-1 gap-2">
                        <label htmlFor="InvestorAddress" className="text-xs">{t('EnterUCCNumber')}</label>
                        <input type="text" name="UCC" id="InvestorAddress" value={investorDetails?.UCC} onChange={(e) => handleInvestorDetails(e)} className="sm:w-1/2 text-xs p-2 border border-back-gray bg-white rounded-xl" />
                      </div>
                      <div className="flex flex-col pt-1 gap-2">
                        <p className="text-xs">Dispute Description</p>
                        <div className="bg-white rounded-xl sm:w-1/2 p-2 flex flex-col gap-2">
                          <textarea value={complainBrief} onChange={(e) => setComplainBrief(e.target.value)} placeholder={t('AddDisputeDescription')} rows={3} className="text-xs p-2 border-none"></textarea>
                        </div>
                      </div>

                    </div>
                  )}
                  <div className="flex flex-col pt-1 gap-2">
                    <label htmlFor="claimAmount" className="text-xs">{t('ClaimAmount')}<span className="text-highlight-red">*</span> ({t('Input0')})</label>
                    <input required type="number" value={complainFilingDetail?.claimAmount} step="0.001" onChange={(e) => {
                      checkClaimAmount(e.target.value);
                      setComplaintFilingDetail({
                        ...complainFilingDetail,
                        claimAmount: e.target.value
                      })
                    }} className="sm:w-1/2 p-2 border text-xs border-back-gray bg-white rounded-xl" />
                  </div>
                  <hr />
                  <div className="flex flex-row gap-2">
                    <input type="checkbox" checked={tAndC} onChange={() => setTAndC(!tAndC)} />
                    <label className="text-xs">{t('IConfirmDispute')} <span className="text-xs text-highlight-red">*</span></label>
                  </div>
                </>
              ) :
                (
                  <div className="flex flex-col gap-2">
                    <h1 className="text-3xl font-semibold text-back-gray">
                      {t('FillDisputeDetails')}
                    </h1>
                    <hr />
                  </div>
                )}

              <div className="flex flex-row gap-3 justify-end">
                <button aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2 rounded-lg">
                  {t('cancel')}
                </button>
                <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2 rounded-lg disabled:bg-highlight-blue/[0.4]" disabled={!tAndC} aria-label={t('Proceed')}
                  onClick={(e) => {
                    fileAComplaint(e)
                  }}
                >
                  <p>{t('Proceed')}</p>
                </button>
              </div>
            </div>
          </div>
          {claimValueModal &&
            <ClaimValueAlert setClaimValueModal={setClaimValueModal} claimValueModal={claimValueModal} setEditClaimValue={setEditClaimValue} ></ClaimValueAlert>
          }
        </div>
      </div>

    </div>
  )
}

export default IntermediaryComplaintFiling