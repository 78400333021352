import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { ApiController } from "../../../apiController";
import { User } from "../../../swagger/models";
import { useTranslation } from 'react-i18next';

  /**
   * This component is used to display the list of user management options to the user.
   * The component is only accessible by an organisation admin.
   * The component displays the list of options for managing users, including
   *     - MII profiles
   *     - Market Participants
   *     - ODR institutions
   *     - Users
   * The component also displays the organisation name and the number of users associated with the organisation.
   */
function Organizations() {

  const currentURL = window.location.href.split('/')[3]
  const [userDetail, setUserDetails] = useState<User>({})
  const { t } = useTranslation();

  /**
   * 
   */
  useEffect(() => {
    ApiController.getInstance().user$.subscribe((user: any) => {
      if (user) {
        setUserDetails(user)
      }
    });
  }, [])

  return (
    <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full h-full flex-initial px-8 py-7 flex-col gap-2  rounded-2xl">
        <p className='text-xs text-back-gray'><Link to={`/${currentURL}/settings`}>{t('Settings')}</Link> &gt; {t('UserManagement')}</p>
        <h1 className="text-3xl font-semibold text-highlight-blue pb-3">{t('Settings')}</h1>
        <div className="bg-white w-full flex flex-col rounded-xl gap-4">
          <Link to={`/${currentURL}/settings`} className="py-3 border-b border-F5F5F5 text-xs pl-7">{t('Back')}</Link>
          <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
            <i className="ph-bold ph-user-circle-plus p-3 border border-F5F5F5 rounded-full"></i>
            <div className="flex flex-col gap-2">
              <p className="text-sm">{t('UserManagement')}</p>
              <p className="text-xs text-back-gray">{t('AdditionalUsers')}</p>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <Link to="mii" className="flex flex-row items-center justify-between px-7 py-2.5 border-b border-border-grey">
              <div className="flex flex-row gap-3">
                <i className="ph-bold ph-chart-line-up"></i>
                <p className="text-xs">MII's</p>
              </div>
              <i className="ph-bold ph-caret-right"></i>
            </Link>
            <Link to="intermediary" className="flex flex-row items-center justify-between px-7 py-2.5 border-b border-border-grey">
              <div className="flex flex-row gap-3">
                <i className="ph-bold ph-buildings"></i>
                <p className="text-xs">{t('MarketParticipants')}</p>
              </div>

              <i className="ph-bold ph-caret-right"></i>
            </Link>
            <Link to="odr" className="flex flex-row items-center justify-between px-7 py-2.5 border-b border-border-grey">
              <div className="flex flex-row gap-3">
                <i className="ph-bold ph-globe"></i>
                <p className="text-xs">{t('ODRInstitutions')}</p>
              </div>
              <i className="ph-bold ph-caret-right"></i>
            </Link>
            <Link to={`../user-management?role=investor`} className="flex flex-row items-center justify-between px-7 py-2.5 border-b border-border-grey">
              <div className="flex flex-row gap-3">
                <i className="ph-bold ph-user-gear"></i>
                <p className="text-xs">Investors</p>
              </div>
              <i className="ph-bold ph-caret-right"></i>
            </Link>
            <Link to={`../user-management?orgId=${userDetail?.orgId}&role=${currentURL}`} className="flex flex-row items-center justify-between px-7 py-2.5 border-b border-border-grey">
              <div className="flex flex-row gap-3">
                <i className="ph-bold ph-users"></i>
                <p className="text-xs">{userDetail?.Organisation?.organisationName} Users</p>
              </div>
              <i className="ph-bold ph-caret-right"></i>
            </Link>
          </div>
          <p className="pb-3 text-xs pl-7">{" "}</p>
        </div>
      </div>
    </div>
  )
}

export default Organizations