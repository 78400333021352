import { useEffect, useRef, useState } from "react"
import { Link, NavLink } from "react-router-dom"
import { notifySuccess } from "../../helpers/helper"
import { useCombinedStore } from "../../store"
import { Organisation, User } from "../../swagger/models"
import _ from "lodash";
import { useTranslation } from 'react-i18next';

/**
 * Component for investor verification
 * 
 * This component handles the verification of investors.
 * The investor's email is entered and the component sends a verification email to the entered email.
 * If the investor is not registered, the component provides a link to registration.
 * 
 * @returns The InvestorVerify component.
 */
function InvestorVerify() {
  const { t } = useTranslation();
  const [investorSearchTerm, setInvestorSearchTerm] = useState<string>('');
  const [filteredInvestors, setfilteredInvestors] = useState<Organisation[]>([]);

  const [user, setUser] = useState<User>({})

  const [bugModal, setBugModal] = useState(false)
  const [feedback, setFeedback] = useState({ name: '', email: '', feedback: '' })
  const [dropdown, setDropdown] = useState(false)

  const delayedQuery = useRef(_.debounce(q => listUser(q), 500)).current;

  const listUser = useCombinedStore((state: any) => state.listUser);
  const verifyRegisteredInvestorApi = useCombinedStore((state: any) => state.verifyRegisteredInvestorApi)
  const users = useCombinedStore((state: any) => state.users)

  const newProfileCreated = useCombinedStore((state: any) => state.newProfileCreated)
  const isLoading = useCombinedStore((state: any) => state.isLoading)

  const sendFeedback = () => {
    fetch(
      "https://hooks.slack.com/services/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",
      {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          text: `*Name:* ${feedback.name},\n*Email:* ${feedback.email}\n*Bug Report:* ${feedback.feedback}`,
        }),
      }
    ).then((response) => {
      notifySuccess("Thanks, we'll look into it!");
      setBugModal(false)
    });
  };

  const handleIntermediarySearch = (e: any) => {
    const { value } = e.target;
    setInvestorSearchTerm(value);
    delayedQuery(value);
    if (value.length > 2) {
      setDropdown(true)
    }
  }

  /**
   * 
   * @param e 
   * @returns 
   */
  async function onSubmit(e: any) {
    if (!user.userId) {
      return false;
    }
    e.preventDefault();
    verifyRegisteredInvestorApi(user.userId)
  }

  useEffect(() => {
    if (users.length > 0) {
      if (investorSearchTerm) {
        setfilteredInvestors(users);
      } else {
        setfilteredInvestors([])
      }
    } else {
      setUser({})
      setfilteredInvestors([])
    }
  }, [users])
  return (
    <>
      <div className="flex flex-row justify-between pt-7 px-10 lg:pl-0 pb-7 w-full h-screen">
        <div className="flex flex-col h-full w-full lg:w-full items-center pt-10 sm:pt-0 justify-center text-center">
          <div className="sm:w-1/3">
            <NavLink to="/login" className="flex w-full justify-center">
              <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '175px', height: 'fit' }} />
            </NavLink>
            <h1 className="pt-10 text-3xl text-highlight-blue font-bold">{t('InvestorVerify')}</h1>
            <p className="text-xs pt-3 pb-7">{t('AlreadyHaveAccount')} <Link to="/login" style={{ color: '#306AFF' }}>{t('Loginhere')}</Link></p>
            {!newProfileCreated ? (
              <div className="pb-8 border-b-2 border-highlight-blue">
                <div className="rounded-xl bg-light-blue flex flex-col gap-3 py-4 px-4 text-xs">
                  <p className="font-bold text-highlight-blue text-left">{t('ImportantNote')}</p>
                  <p className="text-highlight-blue text-left">{t('VerificationEmailsTriggered')}</p>
                </div>
              </div>
            ) : (
              <div className="pb-8">
                <div className="rounded-xl bg-light-blue flex flex-col gap-3 py-4 px-4 text-xs">
                  <div className="flex flex-row w-full h-fit gap-2 items-center">
                    <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/Green-Tick-Vector-PNG-Images.png" width={"30px"} />
                    <p className="font-bold text-highlight-blue">{t('AccountRegistrationSuccessful')}! {t('CheckYourEmail')}</p>
                  </div>

                  <p className="text-highlight-blue">{t('TheLinkRedirect')}</p>
                  <p className="text-highlight-blue">{t('PleaseNoteLoginWithout')}</p>
                </div>
              </div>
            )}
            {!newProfileCreated && (
              <form autoComplete="off" className="flex flex-col pt-8 gap-2" onSubmit={onSubmit}>
                <div className="flex flex-col pt-1 gap-2">

                  <div className="relative w-full">
                    <div className="inline-flex flex-col gap-2 w-full">
                      <div className="flex flex-row gap-2 items-center">
                        <label htmlFor="CompanyName" className="text-xs">{t('InvestorEmail')}*</label>
                      </div>

                      <input
                        className="p-2 border border-border-grey text-xs bg-white rounded-lg"
                        type="text"
                        autoComplete="off" // Try "off", "new-password", or "nope"
                        placeholder={t('EnterEmailofInvestor')}
                        value={investorSearchTerm}
                        onChange={handleIntermediarySearch}
                      />
                    </div>
                    {filteredInvestors.length > 0 && dropdown && (
                      <ul className="origin-top-right absolute left-0 mt-2 w-fit rounded-lg bg-white text-xs px-1 py-2">
                        {filteredInvestors?.map((item: any, index: any) => (
                          <li
                            className="px-3 py-1 hover:bg-back-gray rounded-lg cursor-pointer"
                            key={item?.email} onClick={() => {

                              setInvestorSearchTerm(item.email)
                              setUser(item)
                              setfilteredInvestors([])
                              setDropdown(false)
                            }}>

                            {item.email}
                          </li>
                        ))}
                      </ul>
                    )}
                    <div className="flex flex-col gap-4">
                      <button disabled={!(user?.userId && user?.status !== 'ACTIVE' && (user?.email.length > 10))} aria-label='Proceed' type="submit" className='bg-highlight-blue disabled:bg-highlight-blue/40 hover:bg-highlight-blue/[0.8] mt-4 text-white text-sm py-4 px-28 rounded-lg'>
                        {t('TriggerVerificationMail')}
                      </button>
                    </div>
                  </div>
                  {
                    user?.userId && (
                      <div className="pt-4 pb-2">
                        <div className="rounded-xl bg-light-blue flex flex-col gap-3 py-4 px-4 text-xs">
                          <div className="flex flex-row w-full h-fit gap-2 items-center">
                            <p className="font-bold text-highlight-blue">{user?.userName}</p>
                          </div>
                          <div className="text-left">
                            <p className="text-highlight-blue">{t('Status')}: {user?.status}</p>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </form>

            )}
            <p className="text-xs pt-6 ">{t('IncorrectInformation')} <span className="hover:underline cursor-pointer" onClick={() => setBugModal(true)} style={{ color: '#306AFF' }}>{t('RaiseSupportRequest')}</span></p>
            <p className="text-xs pt-2">{t('ReadCommonErrors')}  <a href="https://help.smartodr.in/" target='_blank' className="hover:underline" style={{ color: '#306AFF' }}>{t('here')}</a>.</p>
          </div>
        </div>
      </div>

      {bugModal &&
        (
          <div
            className="fixed z-10 inset-0 flex items-center justify-center "
            id="modal"
          >
            <div className="px-8 sm:px-0 sm:w-1/3">
              <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setBugModal(false)}
                >
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                  className="inline-block align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <form >
                    <div className="flex flex-col gap-1 px-8 pb-5">
                      <h1 className='font-semibold sm:text-2xl text-lg pt-3'>{t('reportBug')}?</h1>
                      <div className='flex flex-col gap-2 pb-3 pt-4 text-label-grey'>
                        <p className="text-xs text-label-grey">{t('Name')}</p>
                        <input type="text" onChange={(e) => setFeedback({ ...feedback, name: e.target.value })} placeholder={t('EnterYourName')} className="p-2 border border-border-grey rounded-lg w-full text-xs" />
                        <p className="text-xs text-label-grey">{t('email')}</p>
                        <input type="email" onChange={(e) => setFeedback({ ...feedback, email: e.target.value })} placeholder={t('EnterYourEmail')} className="p-2 border border-border-grey rounded-lg w-full text-xs" />
                        <p className="text-xs text-label-grey">{t('bugDescription')}</p>
                        <textarea onChange={(e) => setFeedback({ ...feedback, feedback: e.target.value })} placeholder={t("AddressIncorrect")} name="bugReport" id="bugReport" rows={4} className="text-xs border rounded-lg w-full p-2" />
                      </div>
                      <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>
                        <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12" aria-label={t('submit')}
                          onClick={sendFeedback}>
                          <p>{t('submit')}</p>
                        </button>
                        <button aria-label={t('cancel')} onClick={() => setBugModal(false)} className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                          {t('cancel')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        )
      }
    </>
  )
}

export default InvestorVerify