import React, { Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import ComplaintDetails from '../Admin/Complain/ComplaintDetails'
import TrackStatus from '../Admin/Track-Status/TrackStatus'
import InvestorContainer from './InvestorContainer'
import ComplaintFiling from '../Admin/ComplaintFiling/ComplaintFiling'
import Settings from '../Admin/Settings/Settings'
import AccountSettings from '../Admin/Settings/AccountSettings'
import PrivacySettings from '../Admin/Settings/PrivacySettings'
import FilingSuccess from '../Admin/ComplaintFiling/FilingSuccess'
import { ApiController } from '../../apiController'
import Loader from '../common-module/loader'
import ArbitrationRequisites from '../Admin/Complain/ArbitrationRequisites'
import ArbitrationSuccess from '../Admin/Complain/ArbitrationSuccess'
import NotificationSettings from '../Admin/Settings/NotificationSettings'
import RightSideBar from '../common-module/RightSideBar'
import posthog from 'posthog-js'
import ScoresDisputeFiling from '../Admin/ComplaintFiling/ScoresDisputeFiling'
import ComplainFilingContainer from '../Admin/ComplaintFiling/ComplainFilingContainer'

const ComplainList = React.lazy(() => import("../Admin/Complain/ComplainList"));

function InvestorModule() {

  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    ApiController.getInstance().UserLoggedIn$.subscribe(
      async (userLoggedIn) => {
        if (userLoggedIn && userLoggedIn.userType === 'Investor') {
          setIsAuthenticated(true);
          const user = await ApiController.getInstance().authApi.getProfile();
          ApiController.getInstance().updateUserProfile(user.data);
          posthog.identify(user.data.email, {
            phone: user.data.phoneNumber,
            role: user.data.userType,
            city: user.data.city,
            state: user.data.state,
            pincode: user.data.pinCode,
          })
        } else {
          setIsAuthenticated(false);
          navigate("/investor/login");
        }
      }
    );
  }, []);

  return (
    <React.Fragment>
      <div role="heading" aria-level={1} className="flex sm:flex-row flex-col pb-0 mx-auto px-2 w-full h-full  justify-between">
        <div>{isAuthenticated && <InvestorContainer />}</div>
        <div className="w-full min-h-full" role="main">
          <Routes>
            <Route path="disputes">
              <Route index={true} element={
                <Suspense fallback={<Loader />}>
                  <ComplainList />
                </Suspense>
              } />
              <Route
                index={false}
                path="disputeDetails/:complainUUID"
                element={<ComplaintDetails />} />
              <Route
                index={false}
                path="disputeDetails/:complainUUID/initiate-arbitration"
                element={<ArbitrationRequisites />} />
              <Route
                index={false}
                path="disputeDetails/:complainUUID/initiate-arbitration/initiated-arbitration"
                element={<ArbitrationSuccess />} />
            </Route>
            <Route path="dispute-registration">
              <Route index={true} element={<ComplainFilingContainer />} />
              <Route
                index={false}
                path="success"
                element={<FilingSuccess />} />
            </Route>
            <Route path="scores-dispute-registration">
              <Route index={true} element={<ComplainFilingContainer />} />
              <Route
                index={false}
                path="success"
                element={<FilingSuccess />} />
            </Route>
            <Route path="settings">
              <Route index={true} element={<Settings />} />
              <Route
                index={false}
                path="account-settings"
                element={<AccountSettings />} />
              <Route
                index={false}
                path="security"
                element={<PrivacySettings />} />
              <Route
                index={false}
                path="notification-settings"
                element={<NotificationSettings />} />
            </Route>
            <Route
              path="track-status"
              element={<TrackStatus />}
              index={true}
            />
            <Route
              path="*"
              element={<Navigate replace={true} to="disputes" />}
            />
            <Route
              path="/"
              element={<Navigate replace={false} to={"disputes"} />}
            />
          </Routes>
        </div>
        <RightSideBar/>
      </div>
    </React.Fragment>
  )
}

export default InvestorModule