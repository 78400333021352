import { motion, AnimatePresence } from "framer-motion"
import { useEffect } from "react"
import { Link } from "react-router-dom";
import { useCombinedStore } from "../../../store";
import { Notification } from "../../../swagger/models";
import { dateHelper } from "../../common-module/helper";
import { useTranslation } from 'react-i18next';

function Notifications({ isOpen, setOpen }: any) {
  const { t } = useTranslation();
  const currentURL = window.location.href.split('/')[3]
  const getNotifications = useCombinedStore((state: any) => state.getNotifications);
  const cleanAllNotifications = useCombinedStore((state: any) => state.cleanAllNotifications);
  const notifications: Notification[] = useCombinedStore((state: any) => state.notifications);

  const getUniqueId = (complainId: string) => {
    const ids = complainId?.split('-')
    if (ids?.length === 5) {
      return ids[4];
    } else {
      return ''
    }
  }

  useEffect(() => {
    getNotifications()
  }, [])

  return (
    <>
      {isOpen && (
        <AnimatePresence>

          <div className="absolute top-0 left-0 z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => { setOpen(!isOpen) }}
              className="fixed inset-0 bg-gray-500 bg-opacity-0 transition-opacity">
            </motion.div>
            <div className="fixed inset-0 overflow-hidden z-50">
              <div className="absolute inset-0 overflow-hidden">
                <motion.div
                  initial={{ x: '100%' }}
                  animate={{ x: 0 }}
                  exit={{ x: '100%' }}
                  transition={{ ease: 'easeInOut', duration: 0.5 }}
                  className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <div className="pointer-events-auto relative w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 flex flex-row justify-between">
                        <h2 className="text-xl font-semibold text-highlight-blue" id="slide-over-title">{t('Notifications')}</h2>
                        <div className="flex gap-2 items-center">
                          {notifications?.length > 0 &&
                            <button onClick={() => cleanAllNotifications()} className="text-xs text-label-grey h-full px-3 rounded-lg bg-back-gray hover:bg-back-gray/25">{t('ClearNotifications')}</button>
                          }
                          <button onClick={() => { setOpen(!isOpen) }} type="button" className="rounded-md text-label-grey px-2 hover:text-border-grey text-lg focus:outline-none focus:ring-2 focus:ring-back-gray">
                            <i className="ph-bold ph-x"></i>
                          </button>
                        </div>

                      </div>
                      {notifications?.map((notification: Notification) => {
                        return <div className=" mt-6 flex flex-col px-4 sm:px-6">
                          <Link key={notification?.notificationId} to={`/${currentURL}/disputes/disputeDetails/${notification?.Complain?.complaintUUID}`}>
                            <div className={`py-4 flex flex-col gap-2 border-b border-border-grey px-2 ${notification?.isSeen ? '' : 'bg-light-blue'}`}>
                              <div className="flex flex-row w-full gap-4 items-center">
                                <p className="text-xs font-semibold text-label-grey ">{getUniqueId(notification?.Complain?.complaintUUID)}:</p>
                                <p className="text-xs font-semibold text-label-grey">{notification?.comment}</p>
                              </div>
                              <p className="text-xs text-back-gray">{t('commentBy', {
                                commentBy: notification?.commentMadeBy,
                                date: dateHelper(notification?.createdAt)
                              })}</p>
                            </div>
                          </Link>
                        </div>

                      })}
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </AnimatePresence>

      )}
    </>

  )
}

export default Notifications