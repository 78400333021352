import { useTranslation } from 'react-i18next';

interface ReviewRejectionProps {
  setReviewRejection: any;
  reason: string;
}
/**
 * A modal for reviewing the reason for a dispute rejection.
 *
 * @param {Object} props component props
 * @param {Function} props.setReviewRejection a function to close the modal
 * @param {string} props.reason the reason for the dispute rejection
 * @returns {ReactElement} the component
 */
const ReviewRejection = ({ setReviewRejection, reason }: ReviewRejectionProps) => {
  const { t } = useTranslation();
  return (
    <div
      className="fixed z-10 inset-0 flex items-center justify-center "
      id="modal"
    >
      <div className="px-8 sm:px-0 sm:w-1/3">
        <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => setReviewRejection(false)}
          >
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <div
            className="inline-block align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <form >
              <div className="flex flex-col gap-4 px-12 pb-5 text-xs text-label-grey">
                <h1 className='font-semibold sm:text-2xl text-lg text-black pt-3'>{t('DisputeReturnedReason')}</h1>
                <div className='p-3 border w-full rounded-lg h-36 overflow-hidden overflow-y-scroll bg-EBEBEB text-label-grey text-xs'>
                  {reason}
                </div>
                <p>{t('MIIReturnDispute')}</p>
                <p>{t('FileDisputeAgain')}</p>
                <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>
                  <button aria-label={t('cancel')} onClick={() => setReviewRejection(false)} className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                  {t('cancel')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  );
};


export default ReviewRejection;