// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en.json";
import translationHI from "./locales/hi.json";
import translationGU from "./locales/gu.json";
import translationBE from "./locales/bengali.json";
import translationTE from "./locales/telugu.json";
import translationMA from "./locales/marathi.json";
import translationTA from "./locales/tamil.json";
import translationKN from "./locales/kannada.json";
import translationML from "./locales/malyalam.json";

const resources = {
  en: {
    translation: translationEN,
  },
  hi: {
    translation: translationHI,
  },
  gu: {
    translation: translationGU,
  },
  be: {
    translation: translationBE,
  },
  te: {
    translation: translationTE,
  },
  ma: {
    translation: translationMA,
  },
  ta: {
    translation: translationTA,
  },
  kn: {
    translation: translationKN,
  },
  ml: {
    translation: translationML,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;